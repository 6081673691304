import React from 'react';
import './WarningPopup.scss';
import '../styles/popup.scss';

interface WarningPopupProps {
  message: string;
  close(): void;
}

const WarningPopup = React.memo((props: WarningPopupProps) => {
  return (
    <div className={'popup warningPopup'}>
      <h2>{props.message}</h2>
      <button className='close' onClick={props.close}>
        {'Fermer'}
      </button>
    </div>
  );
});

WarningPopup.displayName = 'WarningPopup';
export default WarningPopup;

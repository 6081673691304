import axios from 'axios';
import { InvoiceSaved, ValidateProformaParameters } from '../models/Invoice';
import Config from '../Config';

interface InvoiceParams {
  baseUrl: string;
  token: string;
  payload: InvoiceSaved;
}

export const saveInvoice = async ({ baseUrl, token, payload }: InvoiceParams) => {
  const url = Config.getApiExtranetUrl(baseUrl);
  await axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

interface ConvertToProformaParams {
  baseUrl: string;
  token: string;
  payload: ValidateProformaParameters;
}

export const convertProformasToInvoices = async ({ baseUrl, token, payload }: ConvertToProformaParams) => {
  const url = Config.getApiExtranetUrl(baseUrl);
  const response = await axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data.content.invoices;
};

import { useDispatch } from 'react-redux';
import Toast from '../models/Toast';
import { ToastTypes } from '../models/ToastTypes';
import { toastsActions } from '../store/toasts-slice';
import { v4 } from 'uuid';

// Added to avoid the serializable error
function createToast(
  title: string,
  description: string,
  type: ToastTypes,
  id?: string | undefined,
  onClick?: (() => void) | undefined,
): Toast {
  return {
    title: title,
    description: description ?? '',
    type: type,
    backgroundColor: type == ToastTypes.success ? '#65C366' : '#ff4f40',
    id: id,
    icon: undefined,
    onClick: onClick,
  };
}

function useToast() {
  const dispatch = useDispatch();

  const addToast = (message: string, type: ToastTypes, id?: string, description?: string, onClick?: () => void) => {
    const toastId = id ?? v4();
    const toastClick = () => deleteToast(toastId);

    dispatch(toastsActions.addTempToast(createToast(message, description ?? '', type, toastId, onClick ?? toastClick)));
  };

  const deleteToast = (id: string) => {
    dispatch(toastsActions.deleteTempToast(id));
  };

  const addFixedToast = (
    message: string,
    type: ToastTypes,
    id?: string,
    description?: string,
    onClick?: () => void,
  ) => {
    const toastId = id ?? v4();
    const toastClick = () => deleteFixedToast(toastId);
    dispatch(
      toastsActions.addFixedToast(createToast(message, description ?? '', type, toastId, onClick ?? toastClick)),
    );
  };

  const deleteFixedToast = (id: string) => {
    dispatch(toastsActions.deleteFixedToast(id));
  };

  return { addToast, addFixedToast, deleteToast, deleteFixedToast };
}

export default useToast;

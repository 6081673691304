import React from 'react';
import QuotationHistories from '../../lists/Quotations/QuotationHistories';
import { GetQuotation } from '../../../models/Quotation';

interface HistQuotationProps {
  quotationData: GetQuotation | undefined;
}

const HistQuotation = React.memo((props: HistQuotationProps) => {
  return <>{props.quotationData && <QuotationHistories quotationData={props.quotationData} />}</>;
});

HistQuotation.displayName = 'HistQuotation';
export default HistQuotation;

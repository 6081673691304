import { SelectData, SelectDataOption, SelectProps } from '../../stories/dune/molecules/SelectData';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransportMode } from '../../models/Product';

export type SelectTransportModeOption = SelectDataOption

export interface SelectTransportModeProps extends SelectProps {
  hideIncludedTransportMode?: boolean;
}

export const SelectTransportMode = ({
  setValue,
  register,
  registerName = 'transportMode',
  error,
  selectedOptionChanged,
  isPopup,
  onClosePopup,
  searchParent,
  dependsOnSearchParent = false,
  initialOption = undefined,
  forceSelectedOption = undefined,
  ...props
}: SelectTransportModeProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const [options, setOptions] = useState<SelectTransportModeOption[]>([]);

  const [defaultOption, setDefaultOption] = useState<SelectTransportModeOption | undefined>(undefined);

  const transportModeList = props.hideIncludedTransportMode
    ? {
        exworks: t('transportMode.' + TransportMode.EXWORKS),
        delivered: t('transportMode.' + TransportMode.DELIVERED),
      }
    : {
        exworks: t('transportMode.' + TransportMode.EXWORKS),
        included: t('transportMode.' + TransportMode.INCLUDED),
        delivered: t('transportMode.' + TransportMode.DELIVERED),
      };

  const loadOptions = () => {
    const optionsTransportMode: SelectTransportModeOption[] = [];

    if (transportModeList != null) {
      for (const [key, value] of Object.entries(transportModeList)) {
        const option = {
          value: key,
          label: value,
          data: { value: key, label: value },
        };

        optionsTransportMode.push(option);
      }
      setOptions(optionsTransportMode);
      setDefaultOption(optionsTransportMode.find((x) => x.value === TransportMode.EXWORKS));
    }
  };

  useEffect(() => {
    loadOptions();
  }, []);

  return (
    <SelectData
      title={props.titleOverride ?? t('common.transportMode')}
      placeholder={t('common.transportModeChoose')}
      titleSize={props.titleSize ?? 'large'}
      hoverDescription={props.hoverDescription ?? t('common.transportModeDesc')}
      setValue={setValue}
      register={register}
      registerName={registerName}
      error={error}
      isSelectable={props.isSelectable}
      selectedOptionChanged={selectedOptionChanged}
      clearOnSelect={props.clearOnSelect}
      isLoading={isLoading}
      options={options}
      searchParent={searchParent}
      dependsOnSearchParent={dependsOnSearchParent}
      initialOption={initialOption}
      forceSelectedOption={forceSelectedOption ?? defaultOption}
      filterText={''}
      fetchAtLoad={false}
      onClickNoResult={undefined}
      isPopup={isPopup}
      onClosePopup={onClosePopup}
      inRow={props.inRow}
      isClearable={props.isClearable ?? true}
      isSearchable={false}
      noResultText={props.noResultTextOverride}
    ></SelectData>
  );
};

import React from 'react';
import './errorLine.scss';

interface ErrorLineProps {
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'title1' | 'title2' | 'label';

  align?: 'left' | 'center';

  label: string;
}

export const ErrorLine = ({ type = 'label', align = 'center', label }: ErrorLineProps) => {
  const addLineBreak = (str: string) =>
    str.split('\n').map((subStr) => {
      return (
        <>
          {subStr}
          <br />
        </>
      );
    });

  return <span className={'dune-error dune-error-' + type + ' dune-error-' + align}>{addLineBreak(label)}</span>;
};

import React from 'react';
import './dataBox.scss';
import PopupContainer from '../organisms/PopupContainer';
import { Icon, IconType } from '../atoms/Icon';
import { Button } from '../atoms/Button';

interface DataBoxProps {
  type?: 'text' | 'email' | 'number' | 'password' | 'date' | 'time' | 'datetime-local' | 'month' | 'week';

  size?: 'wide' | 'fit';

  placeholder?: string;

  label?: string;

  hoverDescription?: string;

  toolTipMode?: 'none' | 'info' | 'question' | 'warning';

  value: string | number | undefined;

  disabled?: boolean;

  inRow?: boolean;

  isPopup?: boolean;

  onClosePopup?: (selectedOption: any, linkedData?: any) => void;

  popupConfirmLabel?: string;

  popupConfirmIcon?: IconType;

  prefixText?: string;

  suffixText?: string;

  prefixIcon?: IconType;

  suffixIcon?: IconType;

  prefixStyle?:
    | 'primary'
    | 'primary-02'
    | 'primary-03'
    | 'primary-04'
    | 'secondary'
    | 'tertiary'
    | 'quaternary'
    | 'white';

  suffixStyle?:
    | 'primary'
    | 'primary-02'
    | 'primary-03'
    | 'primary-04'
    | 'secondary'
    | 'tertiary'
    | 'quaternary'
    | 'white';

  onPrefixClick?: () => void;
  onSuffixClick?: () => void;
  onClick?: () => void;
}

export const DataBox = ({
  size = 'wide',
  toolTipMode = 'none',
  type = 'text',
  label,

  prefixText,
  suffixText,
  prefixIcon,
  suffixIcon,
  prefixStyle,
  suffixStyle,
  onPrefixClick,
  onSuffixClick,
  onClick,
  placeholder,
  value,
  disabled = false,
  inRow = false,
  ...props
}: DataBoxProps) => {
  const prefix = prefixText || prefixIcon;
  const suffix = suffixText || suffixIcon;

  const content = (
    <div className={['dune-content-databox', `dune-content-databox-${size}`].join(' ')} title={props.hoverDescription}>
      {label ? (
        <>
          <div className={['dune-databox-box', inRow ? 'dune-databox-box-in-row' : ''].join(' ')}>
            <span className='dune-label'>{label}</span>
            {toolTipMode !== 'none' && (
              <span className='dune-label-icon'>
                <Icon icon={toolTipMode} style='secondary' />
              </span>
            )}
          </div>
        </>
      ) : (
        ''
      )}
      {prefix || suffix ? (
        <div className={['dune-databox-box', inRow ? 'dune-databox-box-in-row' : ''].join(' ')}>
          {prefix && (
            <Button
              label={prefixText}
              style={prefixStyle ?? 'secondary'}
              iconLeft={prefixIcon}
              onClick={disabled ? undefined : onPrefixClick}
              radius='prefix'
            />
          )}
          <div
            placeholder={placeholder ?? undefined}
            className={[
              'dune-databox',
              prefix && suffix
                ? 'dune-databox-with-both'
                : prefix || suffix
                ? prefix
                  ? 'dune-databox-with-prefix'
                  : 'dune-databox-with-suffix'
                : '',
              inRow ? 'dune-databox-in-row' : '',
              onClick ? 'dune-databox-clickable' : '',
              'dune-databox-disabled',
            ].join(' ')}
            onClick={disabled ? undefined : onClick}
          >
            {type === 'number' && value ? value.toLocaleString() : value ?? undefined}
          </div>
          {suffix && (
            <Button
              label={suffixText}
              style={suffixStyle ?? 'secondary'}
              iconLeft={suffixIcon}
              onClick={disabled ? undefined : onSuffixClick}
              radius='suffix'
            />
          )}
        </div>
      ) : (
        <div
          placeholder={placeholder ?? undefined}
          className='dune-databox dune-databox-disabled'
          onClick={disabled ? undefined : onClick}
        >
          {type === 'number' && value ? value.toLocaleString() : value ?? undefined}
        </div>
      )}
    </div>
  );

  return (
    <>
      {props.isPopup ? (
        <PopupContainer
          onConfirm={function (): void {
            if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
            else props.onClosePopup(value);
          }}
          onCancel={function (): void {
            if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
            else props.onClosePopup(null);
          }}
          confirmLabel={props.popupConfirmLabel}
          confirmIcon={props.popupConfirmIcon}
        >
          {content}
        </PopupContainer>
      ) : (
        content
      )}
    </>
  );
};

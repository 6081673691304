import { SelectData, SelectDataOption, SelectProps } from '../../stories/dune/molecules/SelectData';
import axios from 'axios';
import { useState } from 'react';
import Config from '../../Config';
import { useTranslation } from 'react-i18next';
import { Invoice } from '../../models/Invoice';

export interface SelectInvoiceOption extends SelectDataOption {
  data: Invoice;
}

export const SelectInvoice = ({
  setValue,
  register,
  registerName = 'client',
  error,
  selectedOptionChanged,
  searchParent,
  dependsOnSearchParent = false,
  initialOption = undefined,
  forceSelectedOption = undefined,
  ...props
}: SelectProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const [options, setOptions] = useState<SelectInvoiceOption[]>([]);
  const [filterText, setFilterText] = useState('');

  const { t } = useTranslation();

  const fetchData = (filterText: string) => {
    setFilterText(filterText);
    if (filterText.length > 0) {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');

      const baseUrl = '/invoice/get';
      const url = orgid + `${baseUrl}?onlyActive=true&page=${1}&limit=${200}&search=${filterText}`;

      axios
        .get(Config.getApiExtranetUrl(url), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsLoading(false);
          setFetchedOptions(res.data.content.items);
        })
        .catch(() => {
          setIsLoading(false);
          setFetchedOptions([]);
        });
    } else {
      setFetchedOptions([]);
    }
  };

  const setFetchedOptions = (fetchedOptions: Invoice[]) => {
    const options: any[] = [];

    if (fetchedOptions != null) {
      fetchedOptions.forEach((s) => {
        const option = {
          value: s.id,
          label: s.invoiceNumber + (s.externalReference ? ' - ' + s.externalReference : ''),
          data: s,
        };

        options.push(option);
      });

      setOptions(options);
    }
  };

  return (
    <>
      <SelectData
        title={props.titleOverride ?? t('common.invoice')}
        placeholder={t('common.invoiceChoose')}
        titleSize={props.titleSize ?? 'large'}
        setValue={setValue}
        register={register}
        registerName={registerName}
        error={error}
        isSelectable={props.isSelectable}
        selectedOptionChanged={selectedOptionChanged}
        clearOnSelect={props.clearOnSelect}
        isLoading={isLoading}
        options={options}
        searchParent={searchParent}
        dependsOnSearchParent={dependsOnSearchParent}
        initialOption={initialOption}
        forceSelectedOption={forceSelectedOption}
        filterText={filterText}
        fetch={fetchData}
        onClickNoResult={undefined}
        isPopup={props.isPopup}
        onClosePopup={props.onClosePopup}
        noResultText={props.noResultTextOverride}
      ></SelectData>
    </>
  );
};

import React, { useEffect } from 'react';
import { ToastContainer } from '../stories/dune/organisms/ToastContainer';
import { useDispatch, useSelector } from 'react-redux';
import { ToastsState, toastsActions } from '../store/toasts-slice';

const ToastDisplay = React.memo(() => {
  const dispatch = useDispatch();

  const toastDismissTime = 4000;

  const toasts = useSelector((state: { toasts: ToastsState }) => state.toasts.tempToasts);
  const fixedToasts = useSelector((state: { toasts: ToastsState }) => state.toasts.fixedToasts);

  useEffect(() => {
    const interval = setInterval(() => {
      if (toasts.length && toasts.length > 0 && toasts[0]?.id) {
        dispatch(toastsActions.deleteTempToast(toasts[0]?.id));
      }
    }, toastDismissTime);
    return () => {
      clearInterval(interval);
    };
  }, [toastDismissTime, toasts]);
  return (
    <>
      <ToastContainer list={toasts} position={'bottom-left'} key={1} maxToasts={1} />
      <ToastContainer list={fixedToasts} position={'bottom-left'} key={2} maxToasts={1} />
    </>
  );
});

ToastDisplay.displayName = 'ToastDisplay';
export default ToastDisplay;

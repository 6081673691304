import React, { useEffect, useState } from 'react';
import ListAdditionalDocs from './ListAdditionalDocs';
import { useSelector } from 'react-redux';
import { StaticDataState } from '../../../store/staticData-slice';
import { AdditionalDocType } from '../../../models/AdditionalDocType';

interface ListZonePlanDocsProps {
  entityId: string;
  canAddNew: boolean;
  canDelete: boolean;
}

const ListZonePlanDocs = React.memo((props: ListZonePlanDocsProps) => {
  const docTypes = useSelector((state: { staticData: StaticDataState }) => state.staticData.additionalDocTypes);
  const [docTypeZonePlan, setDocTypeZonePlan] = useState<AdditionalDocType | undefined>();
  useEffect(() => {
    if (docTypes) {
      setDocTypeZonePlan(docTypes.find((docType) => docType.label.toUpperCase() === 'ZONEPLAN'));
    }
  }, [docTypes]);

  return (
    <>
      {docTypeZonePlan && (
        <ListAdditionalDocs
          entityType={'zone'}
          entityId={props.entityId}
          canAddNew={props.canAddNew}
          canDeleteAny={props.canDelete}
          forcedFilters={[
            {
              filterType: 'additionalDocType',
              filterData: {
                value: docTypeZonePlan?.id,
                label: docTypeZonePlan?.description + ' - ' + docTypeZonePlan?.label,
                data: docTypeZonePlan,
              },
            },
          ]}
        />
      )}
    </>
  );
});

ListZonePlanDocs.displayName = 'ListZonePlanDocs';
export default ListZonePlanDocs;

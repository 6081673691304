import { SelectData, SelectDataOption, SelectProps } from '../../stories/dune/molecules/SelectData';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type SelectAxleNumberOption = SelectDataOption

export const SelectAxleNumber = ({
  setValue,
  register,
  registerName = 'axleNumber',
  error,
  selectedOptionChanged,
  isPopup,
  onClosePopup,
  searchParent,
  forceOptions,
  dependsOnSearchParent = false,
  initialOption = undefined,
  forceSelectedOption = undefined,
  ...props
}: SelectProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const [options, setOptions] = useState<SelectAxleNumberOption[]>([]);

  const axleNumberList = ['2', '3', '4', '5', '6'];

  const fetchData = (filterText: string) => {
    setFetchedOptions((searchParent ?? null) === null ? axleNumberList : searchParent);
  };

  const setFetchedOptions = (fetchedOptions: string[]) => {
    const options: any[] = [];

    if (fetchedOptions != null) {
      fetchedOptions.forEach((s) => {
        const option = {
          value: s,
          label: s,
        };

        options.push(option);
      });

      setOptions(options);
    }
  };

  const loadOptions = () => {
    const optionsAxleNumber: SelectAxleNumberOption[] = [];

    let optionsList = axleNumberList;
    if (forceOptions != null && forceOptions.length > 0) {
      optionsList = forceOptions;
    }

    if (optionsList != null) {
      for (const [key, value] of Object.entries(optionsList)) {
        const option = {
          value: value,
          label: value,
        };

        optionsAxleNumber.push(option);
      }
      setOptions(optionsAxleNumber);
    }
  };

  useEffect(() => {
    loadOptions();
  }, [forceOptions]);

  return (
    <SelectData
      title={props.titleOverride ?? t('common.axleNumber')}
      placeholder={t('common.axleNumberChoose')}
      titleSize={props.titleSize ?? 'large'}
      hoverDescription={props.hoverDescription}
      setValue={setValue}
      register={register}
      registerName={registerName}
      error={error}
      isSelectable={props.isSelectable}
      selectedOptionChanged={selectedOptionChanged}
      clearOnSelect={props.clearOnSelect}
      isLoading={isLoading}
      options={options}
      searchParent={searchParent}
      dependsOnSearchParent={dependsOnSearchParent}
      initialOption={initialOption}
      forceSelectedOption={forceSelectedOption}
      filterText={''}
      fetch={fetchData}
      fetchAtLoad={true}
      isSearchable={false}
      onClickNoResult={undefined}
      isPopup={isPopup}
      onClosePopup={onClosePopup}
      inRow={props.inRow}
      isClearable={props.isClearable ?? true}
      noResultText={props.noResultTextOverride}
    ></SelectData>
  );
};

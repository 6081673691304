import React from 'react';
import { useTranslation } from 'react-i18next';
import Menu from '../../Menu';
import { Head } from '../../../stories/dune/molecules/Head';
import ListZones from '../../lists/Zones/ListZones';

const Zones = React.memo(() => {
  const { t } = useTranslation();

  return (
    <div className='main-container'>
      <Menu selectedPage='zones' />
      <div className='right detail-container'>
        <Head
          title={t('common.zones')}
          back={false}
          OnNotificationClick={function (): void {
            throw new Error('Function not implemented.');
          }}
          OnAutorisationClick={function (): void {
            throw new Error('Function not implemented.');
          }}
        />
        <ListZones canAddNew={true} />
      </div>
    </div>
  );
});

Zones.displayName = 'Zones';
export default Zones;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './global.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import PiwikPro from '@piwikpro/react-piwik-pro';
import { Provider } from 'react-redux';
import store from './store/index';

PiwikPro.initialize('cb75c074-5014-49c0-b5c9-a3e8aafa57b3', 'https://dunemanager.containers.piwik.pro');

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

reportWebVitals();

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Config from '../../../Config';
import axios from 'axios';
import useToast from '../../../hooks/use-toast';
import { ToastTypes } from '../../../models/ToastTypes';
import { Head } from '../../../stories/dune/molecules/Head';
import Menu from '../../Menu';
import { GetZone, ZoneSave } from '../../../models/Zone';
import PopupContainer from '../../../stories/dune/organisms/PopupContainer';
import SubMenuZone from './SubMenuZone';
import GeneralZone from './GeneralZone';
import ChildZoneList from './ChildZoneList';
import ZoneProductList from './ZoneProductList';
import Product from '../../../models/Product';
import stringUtils from '../../../utils/stringUtils';
import ListZonePlanDocs from '../../lists/AdditionalDocs/ListZonePlanDocs';

interface DetailZoneProps {
  id?: string;
  isPopup?: boolean;
  onClosePopup?: (createdId: string | undefined) => void;
  mode?: string;
}

const DetailZone = React.memo((props: DetailZoneProps) => {
  // #region generic

  const { t } = useTranslation();
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState('general');

  const history = useNavigate();

  type Params = {
    id: string;
    mode: string;
    autosign?: string;
  };

  const { id, mode, autosign } = useParams<Params>();

  function updateCurrentTab(mode: string) {
    setCurrentTab(mode);
  }

  function getMode() {
    return props.mode ?? mode ?? '';
  }

  // #endregion generic

  // #region specific
  const [getZone, setGetZone] = useState<GetZone>();

  function getCurrentZone(id: string | undefined) {
    if (id && getMode() === 'update') {
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');
      const baseUrl = orgid + '/zone/detail/';
      const url = Config.getApiExtranetUrl(baseUrl + id);
      setIsLoading(true);
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if ((res.data.content ?? null) !== null) {
            // on enregistre GetZone
            setGetZone(res.data.content);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          if ((id ?? null) !== null) {
            addToast(
              (error?.response?.data?.code
                ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
                : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
              ToastTypes.error,
            );
          }
        });
    } else setIsLoading(false);
  }

  useEffect(() => {
    getCurrentZone(id);
  }, [id, getMode()]);

  // #endregion specific

  const content = (
    <>
      {!isLoading && (
        <>
          {getMode() === 'add' && (
            <GeneralZone mode={getMode()} zoneData={getZone} onCancel={onCanceled} onConfirm={onConfirmed} />
          )}

          {getMode() !== 'add' && (
            <>
              <SubMenuZone tab={currentTab} updateTab={updateCurrentTab} />
              {currentTab === 'general' && (
                <GeneralZone
                  id={id}
                  mode={getMode()}
                  zoneData={getZone}
                  onCancel={onCanceled}
                  onConfirm={onConfirmed}
                />
              )}

              {currentTab == 'childzones' && (
                <ChildZoneList zn={getZone} onChildSelected={() => updateCurrentTab('general')} />
              )}

              {currentTab === 'products' && (
                <ZoneProductList id={id} zoneData={getZone} onRemove={onRemoveProduct} onAdd={onAddProduct} />
              )}

              {currentTab === 'documents' && <ListZonePlanDocs entityId={id ?? ''} canAddNew={true} canDelete={true} />}
            </>
          )}
        </>
      )}
    </>
  );

  function onConfirmed(data: any) {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(data);
    } else {
      history('/zones');
    }
  }
  function onCanceled() {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(undefined);
    } else {
      history('/zones');
    }
  }

  function onAddProduct(data: Product) {
    if (getZone) {
      getZone.products.push(data);
      saveChanges();
    }
  }

  function onRemoveProduct(data: Product) {
    if (getZone) {
      const index = getZone.products.findIndex((x) => x.id === data.id);
      if (index > -1) {
        getZone.products.splice(index, 1);
        saveChanges();
      }
    }
  }

  function saveChanges() {
    setIsAddingLoading(true);
    if (getZone) {
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');
      const baseUrl = orgid + '/zone/edit/' + getZone.id;

      const dataToSend: ZoneSave = {
        ...getZone,
        products: getZone?.products?.map((p) => p.id ?? ''),
        childZonesId: getZone?.childZones?.map((z) => z.id ?? ''),
      };

      axios
        .post(Config.getApiExtranetUrl(baseUrl), stringUtils.formatFieldsForPost(dataToSend), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          setIsAddingLoading(false);
          addToast(t('common.zoneUpdated'), ToastTypes.success);
        })
        .catch((error) => {
          setIsAddingLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        });
    } else {
      setIsAddingLoading(false);
    }
  }

  return (
    <>
      {props.isPopup ? (
        <PopupContainer
          hideBottomBar={true}
          isLarge={true}
          title={getMode() === 'add' ? t('common.newZone') : t('common.zone') + ' ' + (!getZone ? '' : getZone.label)}
        >
          {content}
        </PopupContainer>
      ) : (
        <div className='main-container'>
          <Menu selectedPage='zones'></Menu> {/* TODO : sortir et mettre dans classe 'parent'*/}
          <div className='right detail-container'>
            <Head
              title={
                getMode() === 'add' ? t('common.newZone') : t('common.zone') + ' ' + (!getZone ? '' : getZone.label)
              }
              back={true}
              OnNotificationClick={function (): void {
                throw new Error('Function not implemented.');
              }}
              OnAutorisationClick={function (): void {
                throw new Error('Function not implemented.');
              }}
              Back={function (): void {
                history('/zones');
              }}
            />
            {content}
          </div>
        </div>
      )}
    </>
  );
});

DetailZone.displayName = 'DetailZone';
export default DetailZone;

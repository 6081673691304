import React, { useEffect, useState } from 'react';
import './Header.scss';
import { ReactComponent as SvgBell } from '../assets/svg/bell.svg';
import { ReactComponent as SvgKey } from '../assets/svg/key.svg';
import Barrier from './Barrier';
import Notification from './Notification';
import eventBus from '../utils/EventBus';
import StringUtils from '../utils/stringUtils';

export interface HeaderProps {
  selectedPage: string;
}

const Header = React.memo((props: HeaderProps) => {
  const [isOpenBarrierPopup, setIsOpenBarrierPopup] = useState(true);
  const [isOpenNotificationPopup, setIsOpenNotificationPopup] = useState(true);
  const [newAlert, setNewAlert] = useState(false);

  const openPopupBarrier = () => {
    setIsOpenBarrierPopup(false);
  };

  const openPopupNotif = () => {
    setNewAlert(false);
    setIsOpenNotificationPopup(false);
  };

  const closePopupBarrier = (e: any) => {
    e ? setIsOpenBarrierPopup(e) : setIsOpenBarrierPopup(false);
  };

  const closeNotificationPopup = (e: any) => {
    e ? setIsOpenNotificationPopup(e) : setIsOpenNotificationPopup(false);
  };

  useEffect(() => {
    eventBus.on('new_alert', () => {
      setNewAlert(true);
    });
  }, []);

  return (
    <div className='Header'>
      <div className='Header__pagetitle'>
        <h1>{StringUtils.Capitalize(props.selectedPage)}</h1>
      </div>
      <div className='Header__btn'>
        <div className='Btn' onClick={openPopupBarrier}>
          <SvgKey className='svgKey' />
        </div>
        <div className={newAlert ? 'Btn active' : 'Btn'} onClick={openPopupNotif}>
          <SvgBell className={newAlert ? 'svgBell active' : 'svgBell'} />
        </div>
        <div className='Sidebar' hidden={isOpenBarrierPopup}>
          <Barrier from='Header' parentCallback={closePopupBarrier} />
        </div>
        <div className='Sidebar' hidden={isOpenNotificationPopup}>
          <Notification from='Header' parentCallback={closeNotificationPopup} refresh={isOpenNotificationPopup} />
        </div>
      </div>
    </div>
  );
});

Header.displayName = 'Header';
export default Header;

import { ToastTypes } from './ToastTypes';

export default class Toast {
  id: string | undefined;
  title: string | undefined;
  description: string | undefined;
  type: ToastTypes | undefined;
  backgroundColor: string | undefined;
  icon: string | undefined;
  onClick: (() => void) | undefined;

  constructor(title: string, description: string, type: ToastTypes, id?: string, onClick?: () => void) {
    this.title = title;
    this.description = description;
    this.type = type;
    this.backgroundColor = type == ToastTypes.success ? '#65C366' : '#ff4f40';
    this.onClick = onClick;
    this.id = id;
  }
}

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CardDetail } from '../../../stories/dune/atoms/CardDetail';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { Input } from '../../../stories/dune/atoms/Input';
import {
  InvoiceSaved,
  GetInvoice,
  InvoiceStatus,
  InvoiceType,
  InvoiceRow,
  InvoiceRowProduct,
  ValidateProformaParameters,
  CancelProformaParameters,
} from '../../../models/Invoice';
import { Title } from '../../../stories/dune/atoms/Title';
import { DataCustomItem } from '../../forms/BuildDataCustom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { FixBar } from '../../../stories/dune/molecules/FixBar';
import { Button } from '../../../stories/dune/atoms/Button';
import useToast from '../../../hooks/use-toast';
import axios from 'axios';
import { ToastTypes } from '../../../models/ToastTypes';
import Config from '../../../Config';
import stringUtils from '../../../utils/stringUtils';
import moment from 'moment';
import { SelectCustomer, SelectCustomerOption } from '../../forms/SelectCustomer';
import { SelectOrderOption } from '../../forms/SelectOrder';
import { GetCustomer } from '../../../models/Customer';
import { GetOrder } from '../../../models/Order';
import { ParagraphInput } from '../../../stories/dune/atoms/ParagraphInput';
import { ProductItemRow, productItemsActions } from '../../../store/productItems-slice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { ProductItems } from '../../forms/ProductItems';
import PopupContainer from '../../../stories/dune/organisms/PopupContainer';
import { GetAdditionalDoc } from '../../../models/AdditionalDoc';
import fileDownload from 'js-file-download';
import { GlobalParameter } from '../../../models/GlobalParameter';
import { convertProformasToInvoices, saveInvoice } from '../../../services/invoices';
import { set } from 'lodash';
import { DocTypeIds } from '../../../models/AdditionalDocType';

interface GeneralInvoiceProps {
  id?: string;
  invoiceData: GetInvoice | undefined;
  onCancel: () => void;
  onConfirm: (data?: any) => void;
  onReload: () => void;
  mode: string;
}

interface IFormInputs {
  externalReference?: string;
  invoiceComment?: string;
}

const GeneralInvoice = React.memo((props: GeneralInvoiceProps) => {
  const { t } = useTranslation();
  const history = useNavigate();
  const { addToast } = useToast();
  const dispatch = useDispatch<AppDispatch>();

  const [siteTimeZone, setSiteTimeZone] = useState<string>('Europe/Paris');
  useEffect(() => {
    const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setSiteTimeZone(globalParametersSite.find((x) => x.label === 'siteTimeZone')?.value ?? 'Europe/Paris');
  }, []);

  const [savedInvoice, setSavedInvoice] = useState<InvoiceSaved>();

  const [isActive, setIsActive] = useState<boolean>(true);

  const [invoiceNumber, setInvoiceNumber] = useState<string>();
  const [externalReference, setExternalReference] = useState<string>();
  const [invoiceComment, setInvoiceComment] = useState<string>();
  const [type, setType] = useState<string>('');
  const [status, setStatus] = useState<string>('');
  const [customerOption, setCustomerOption] = useState<SelectCustomerOption | null>(null);
  const [orderOption, setOrderOption] = useState<SelectOrderOption | null>(null);

  const initialInvoicingDate = props.invoiceData?.invoicingDate
    ? moment(props.invoiceData?.invoicingDate).tz(siteTimeZone).toISOString().substring(0, 10)
    : moment().tz(siteTimeZone).toISOString()?.substring(0, 10);

  const [invoicingDate, setInvoicingDate] = useState<string>(initialInvoicingDate);

  const initialInvoiceDueDate = props.invoiceData?.invoiceDueDate
    ? moment(props.invoiceData?.invoiceDueDate).tz(siteTimeZone).toISOString().substring(0, 10)
    : moment(props.invoiceData?.invoicingDate).tz(siteTimeZone).add(60, 'days').toISOString().substring(0, 10);

  const [invoiceDueDate, setInvoiceDueDate] = useState<string>(initialInvoiceDueDate);

  const [parentInvoiceNumber, setParentInvoiceNumber] = useState<string>();

  const [additionalDocInvoice, setAdditionalDocInvoice] = useState<GetAdditionalDoc | undefined>();
  const [docIsLoading, setDocIsLoading] = useState<boolean>(false);

  const [invoiceDataCustom, setInvoiceDataCutom] = useState<DataCustomItem>({});

  const [showConfirmCancelProforma, setShowConfirmCancelProforma] = useState(false);

  const schema = yup.object().shape({}); // on utilise un validateur de 'validator.js' pour correspondre au back

  const {
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    dispatch(productItemsActions.reset());
    if (props.invoiceData) {
      // on initialise savedInvoice pour ne pas perdre les données non-gérées lors de la sauvegarde
      setSavedInvoice({
        ...props.invoiceData,
        invoiceDueDate: moment(props.invoiceData.invoiceDueDate).tz(siteTimeZone).format('YYYY-MM-DD'),
        invoicingDate: moment(props.invoiceData.invoicingDate).tz(siteTimeZone).format('YYYY-MM-DD'),
      });
      setIsActive(props.invoiceData?.isActive ?? true);
      setCustomerOption(
        props.invoiceData
          ? ({
              value: props.invoiceData.customer.id,
              label: props.invoiceData.customer.label,
              data: props.invoiceData.customer as GetCustomer,
            } as SelectCustomerOption)
          : null,
      );
      setOrderOption(
        props.invoiceData?.order
          ? ({
              value: props.invoiceData.order.id,
              label: `${props.invoiceData.order?.externalReference ?? ''}${
                props.invoiceData.order?.externalReference && props.invoiceData.order?.customerOrderNumber ? ' - ' : ''
              }${props.invoiceData.order?.customerOrderNumber ?? ''}`,
              data: props.invoiceData.order as GetOrder,
            } as SelectOrderOption)
          : null,
      );
      setInvoiceNumber(props.invoiceData?.invoiceNumber);
      setExternalReference(props.invoiceData?.externalReference);
      setInvoiceComment(props.invoiceData?.invoiceComment);
      setType(props.invoiceData?.type);
      setStatus(props.invoiceData?.status);
      setInvoicingDate(initialInvoicingDate);
      setInvoiceDueDate(initialInvoiceDueDate);
      setParentInvoiceNumber(props.invoiceData?.parentInvoiceNumber);

      setInvoiceDataCutom(props.invoiceData?.dataCustom as any);

      const products: ProductItemRow[] = [];

      props.invoiceData?.invoiceRows?.map((x: InvoiceRow) => {
        x.invoiceRowProducts?.map((y: InvoiceRowProduct) => {
          products.push({
            id: y.id,
            productId: y.productId ?? '',
            label: y.label ?? '',
            desc: '',
            externalReference: x.tripId, // invoices : tripId dans externalRef pour liaison
            unit: y.unit ?? '',
            weightByUnit: undefined,
            orderedQuantity: undefined,
            deliveredQuantity: y.quantity,
            baseUnitPrice: y.genericPrice,
            itemUnitPrice: y.quotedPrice,
            discountPercent: y.discount,
            vatRate: y.vatRate,
            vatRateId: undefined,
            repValue: y.repValue,
            tgapValue: y.tgapValue,
            transportValue: y.transportPrice,
            mainProduct: false,
            enableTransportPrice: false,
            showTransportDetails: false,
            invoiceId: x.invoiceId,
            invoice: props.invoiceData,
            tripId: x.tripId,
            trip: x.trip,
          });
        });
      });
      dispatch(productItemsActions.setProducts(products));
    }
  }, [props.invoiceData]);

  useEffect(() => {
    if (additionalDocInvoice) {
      axios({
        url: additionalDocInvoice.url, // your url
        method: 'GET',
        responseType: 'blob', // important
      }).then((data) => {
        fileDownload(data.data, additionalDocInvoice.label);
      });
    }
  }, [additionalDocInvoice]);

  async function onSubmit() {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    if (!token || !orgid || !savedInvoice) {
      return;
    }

    const baseUrl = props.mode === 'update' ? `${orgid}/invoice/edit-proforma/${props.id}` : `${orgid}/invoice/add`;

    const invoicePayload: InvoiceSaved = {
      ...savedInvoice,
      externalReference,
      invoiceComment,
      invoicingDate,
      invoiceDueDate,
    };

    try {
      await saveInvoice({
        baseUrl,
        token,
        payload: stringUtils.formatFieldsForPost(invoicePayload),
      });

      addToast(
        t('common.' + props.invoiceData?.type + (props.mode === 'update' ? 'Updated' : 'Created')),
        ToastTypes.success,
      );
    } catch (error: any) {
      if (error.response) {
        if (
          error.response.data.code == 'ERR4010001' ||
          error.response.data.code == 'ERR4031001' ||
          error.response.data.code == 'ERR4010000'
        ) {
          history('/');
        }
      }
      addToast(
        (error?.response?.data?.code
          ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
          : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
        ToastTypes.error,
      );
    }
  }

  async function handleConvertToInvoice() {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    if (!token || !orgid || !props.invoiceData?.id || !savedInvoice) return;

    const invoicePayload: InvoiceSaved = {
      ...savedInvoice,
      externalReference,
      invoiceComment,
      invoicingDate,
      invoiceDueDate,
    };

    const proformaPayload: ValidateProformaParameters = {
      proformas: [props.invoiceData.id],
    };

    try {
      await saveInvoice({
        baseUrl: `${orgid}/invoice/edit-proforma/${props.id}`,
        token,
        payload: stringUtils.formatFieldsForPost(invoicePayload),
      });

      await convertProformasToInvoices({
        baseUrl: `${orgid}/invoice/validate-proforma`,
        payload: proformaPayload,
        token,
      });

      addToast(t('common.invoiceConverted'), ToastTypes.success);
      if (props.onReload) props.onReload();
    } catch (error: any) {
      if (error.response) {
        if (
          error.response.data.code == 'ERR4010001' ||
          error.response.data.code == 'ERR4031001' ||
          error.response.data.code == 'ERR4010000'
        ) {
          history('/');
        }
      }
      addToast(
        (error?.response?.data?.code
          ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
          : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
        ToastTypes.error,
      );
    }
  }

  function handlePayInvoice() {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    const baseUrl = orgid + '/invoice/paid/' + props.id;

    axios
      .get(Config.getApiExtranetUrl(baseUrl), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        addToast(t('common.invoicePaid'), ToastTypes.success);
        history(0);
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  }

  function handleCancelProforma() {
    if (props.invoiceData?.id) {
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');
      const url = orgid + '/invoice/cancel-proforma';

      const dataToSend: CancelProformaParameters = {
        proformas: [props.invoiceData.id],
      };

      axios
        .post(
          Config.getApiExtranetUrl(url),
          { ...dataToSend },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then(() => {
          addToast(t('common.proformaCanceled'), ToastTypes.success);
          history('/invoicing/invoices/proforma');
        })
        .catch((error) => {
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        });
    }
  }

  async function handleDownloadInvoice() {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    const baseUrl = orgid + '/invoice/detail/';
    const url = Config.getApiExtranetUrl(baseUrl + props.id);

    setDocIsLoading(true);
    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if ((res.data.content ?? null) !== null) {
          // on récupère le dernier additionalDoc dans GetInvoice
          const oInvoice: GetInvoice = res.data.content;

          const docs = oInvoice.additionalDocs?.sort((a: GetAdditionalDoc, b: GetAdditionalDoc) => {
            // Convert the dates to timestamps for comparison
            const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
            const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;

            // Sort in descending order
            return dateB - dateA;
          });
          let doc: GetAdditionalDoc | undefined;
          if (props.invoiceData?.type === InvoiceType.PROFORMA) {
            doc = docs.find(
              (doc: GetAdditionalDoc) =>
                doc.additionalDocTypeId === DocTypeIds.PROFORMA &&
                doc?.createdAt != null &&
                doc?.createdAt >= oInvoice.lastModificationAt,
            ); // get proforma pdf only if it was created after the last modification
          } else if (props.invoiceData?.type === InvoiceType.INVOICE) {
            doc = oInvoice.additionalDocs?.find(
              (doc: GetAdditionalDoc) => doc.additionalDocTypeId === DocTypeIds.INVOICE,
            ); // get invoice
          }
          if (doc) setAdditionalDocInvoice(doc);
          else {
            setAdditionalDocInvoice(undefined);
            addToast(t('common.documentNotAvailableYet'), ToastTypes.error);
          }
        }
        setDocIsLoading(false);
      })
      .catch((error) => {
        setDocIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        }
      });
  }

  let selectPopup = <></>;
  if (showConfirmCancelProforma) {
    selectPopup = (
      <PopupContainer
        message={t('common.confirmCancelProforma')}
        onCancel={function (): void {
          setShowConfirmCancelProforma(false);
        }}
        onConfirm={function (): void {
          setShowConfirmCancelProforma(false);
          handleCancelProforma();
        }}
        confirmLabel={t('common.yes')}
        confirmIcon='delete'
        cancelLabel={t('common.no')}
      />
    );
  }

  return !props.invoiceData && props.mode === 'update' ? (
    <div>Loading...</div>
  ) : (
    <form id='form' onSubmit={handleSubmit(onSubmit)}>
      <CardDetail>
        <div className='form-section'>
          {props.mode === 'add' && (
            <FormRow alignVert='center'>
              <SelectCustomer
                registerName={''}
                error={''}
                isSelectable={false}
                forceSelectedOption={customerOption}
                titleSize='normal'
              />
            </FormRow>
          )}
          <FormRow>
            <Input
              error={''}
              label={t('common.invoicingDate')}
              type='date'
              value={invoicingDate}
              placeholder='--/--/--'
              disabled={false}
              onChange={(newValue: string | number) => {
                const newDate = moment(new Date(newValue)).format('YYYY-MM-DD');
                setInvoicingDate(newDate);
                return newDate;
              }}
            />
            <Input
              error={''}
              label={t('common.invoiceDueDate')}
              type={'date'}
              value={invoiceDueDate}
              placeholder='--/--/--'
              disabled={false}
              onChange={(newValue: string | number) => {
                const newDate = moment(new Date(newValue)).format('YYYY-MM-DD');
                setInvoiceDueDate(newDate);
                return newDate;
              }}
            />
          </FormRow>
          <FormRow>
            <Title label={t('common.products')} type='title1' />
          </FormRow>
          <ProductItems
            label={t('common.products')}
            canAdd={false}
            canRemove={false}
            isLoading={false}
            showTransportMode={false}
            canEditTransportMode={false}
            showOrderedQuantity={false}
            showDeliveredQuantity={true}
            canEditOrderedQuantity={false}
            showUnitPrice={true}
            showDiscountValue={true}
            canEditDiscountValue={false}
            showRowExVatPrice={true}
            showRowInclVatPrice={true}
            showTotal={true}
            showTotalDetails={true}
          />
          <FormRow>
            <ParagraphInput
              onChange={(newValue: string) => setInvoiceComment(newValue)}
              error={errors.invoiceComment?.message ?? ''}
              value={invoiceComment ?? ''}
              label={t('common.comment')}
            />
          </FormRow>
        </div>
        {/* // TODO : datacustom */}
        <FixBar>
          <Button label={t('common.return')} style='white' onClick={props.onCancel} />
          {props.invoiceData?.status !== InvoiceStatus.ARCHIVED && props.invoiceData?.type === InvoiceType.PROFORMA && (
            <>
              <Button
                iconLeft='delete'
                label={t('common.cancel')}
                style='primary-04'
                onClick={() => setShowConfirmCancelProforma(true)}
              />
              <Button iconLeft='save' label={t('common.save')} style='primary' onClick={handleSubmit(onSubmit)} />
            </>
          )}
          {props.mode == 'update' && (
            <>
              <Button
                iconLeft={docIsLoading ? 'refresh' : 'download'}
                label={t('common.export')}
                style='primary-02'
                onClick={handleDownloadInvoice}
                disabled={docIsLoading}
              />
              {props.invoiceData?.status !== InvoiceStatus.ARCHIVED &&
                props.invoiceData?.type === InvoiceType.PROFORMA && (
                  <Button
                    iconLeft='valid'
                    label={t('common.convertToInvoice')}
                    style='primary-03'
                    onClick={handleConvertToInvoice}
                  />
                )}
              {props.invoiceData?.status === InvoiceStatus.UPCOMING && // TODO : afficher quand on gère correctement la création d'avoir
                props.invoiceData?.type === InvoiceType.INVOICE && (
                  <Button
                    iconLeft='valid'
                    label={t('common.payInvoice')}
                    hoverDescription={t('common.payInvoiceDesc')}
                    style='primary-03'
                    onClick={handlePayInvoice}
                  />
                )}
            </>
          )}
        </FixBar>
      </CardDetail>
      {selectPopup}
    </form>
  );
});

GeneralInvoice.displayName = 'GeneralInvoice';
export default GeneralInvoice;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastTypes } from '../../../models/ToastTypes';
import useToast from '../../../hooks/use-toast';
import Config from '../../../Config';
import FilterHeader, { AdvancedFilter } from '../FilterHeader';
import TabFilter from '../TabFilter';
import {
  InvoiceStatus,
  GetInvoice,
  GetInvoiceParameters,
  InvoiceType,
  ValidateProformaParameters,
  CancelProformaParameters,
} from '../../../models/Invoice';
import DataTable from 'react-data-table-component';
import TableStyles from '../../../styles/tableStyles';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { Title } from '../../../stories/dune/atoms/Title';
import { Button } from '../../../stories/dune/atoms/Button';
import { GlobalParameter } from '../../../models/GlobalParameter';
import moment from 'moment';

interface ListInvoicesProps {
  defaultStatus?: string;
  forcedFilters?: AdvancedFilter[];
}

const ListInvoices = React.memo((props: ListInvoicesProps) => {
  // #region intializing constants

  // #region generic
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(true);

  const history = useNavigate();

  const [filtersAdvanced, setFiltersAdvanced] = useState<AdvancedFilter[]>(props.forcedFilters ?? []);
  const [filtersTab, setFiltersTab] = useState<string[]>([props.defaultStatus ?? 'overdue']);
  const [filterTabOverride, setFilterTabOverride] = useState<string>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState(20);
  const [sortColumn, setSortColumn] = useState('invoiceDueDate');
  const [sortDirection, setSortDirection] = useState('desc');
  const [totalRows, setTotalRows] = useState(0);
  const [totalResource, setTotalResource] = useState(0);

  const [siteTimeZone, setSiteTimeZone] = useState<string>('Europe/Paris');
  useEffect(() => {
    const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setSiteTimeZone(globalParametersSite.find((x) => x.label === 'siteTimeZone')?.value ?? 'Europe/Paris');
  }, []);

  // #endregion generic

  // #region specific

  const [filteredItems, setFilteredItems] = useState<GetInvoice[]>([]);
  const [selectedRows, setSelectedRows] = useState<GetInvoice[]>([]);

  const fetchItems = (
    page: number,
    newPerPage: number,
    newSortColumn: string = sortColumn,
    newSortDirection: string = sortDirection,
    TabFilter: string[] = filtersTab,
    AdvancedFilters: AdvancedFilter[] = filtersAdvanced,
  ) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url =
      orgid +
      '/invoice/get?page=' +
      page +
      '&limit=' +
      newPerPage +
      '&sortBy=' +
      newSortColumn +
      '&orderBy=' +
      newSortDirection;

    setCurrentPage(page);
    setIsLoading(true);
    setFilteredItems([]);

    if (newSortColumn != sortColumn) {
      setSortColumn(newSortColumn);
    }

    if (newSortDirection != sortDirection) {
      setSortDirection(newSortDirection);
    }

    axios
      .post(
        Config.getApiExtranetUrl(url),
        { ...getFiltersToSend(TabFilter, AdvancedFilters) },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async (res) => {
        setTotalRows(res.data.meta.total);
        setTotalResource(res.data.meta.total);
        setFilteredItems(res.data.content.items);

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  function getFiltersToSend(tabFilters: string[], advancedFilters: AdvancedFilter[]) {
    const toSend: GetInvoiceParameters = {};

    if (tabFilters?.length > 0) {
      if (tabFilters.includes(InvoiceType.PROFORMA)) {
        toSend.invoiceType = InvoiceType.PROFORMA;
        toSend.invoiceStatus = InvoiceStatus.UPCOMING;
      } else if (tabFilters.includes(InvoiceStatus.UPCOMING)) {
        toSend.invoiceStatus = InvoiceStatus.UPCOMING;
        toSend.invoiceType = InvoiceType.INVOICE;
      } else if (tabFilters.includes('overdue')) {
        toSend.invoiceStatus = InvoiceStatus.UPCOMING;
        toSend.invoiceType = InvoiceType.INVOICE;
        toSend.dueDateBefore = new Date();
      } else if (tabFilters.includes(InvoiceStatus.PAID)) {
        toSend.invoiceStatus = InvoiceStatus.PAID;
      } else if (tabFilters.includes(InvoiceStatus.ARCHIVED)) {
        toSend.invoiceStatus = InvoiceStatus.ARCHIVED;
      }
    }

    if (advancedFilters.length > 0) {
      advancedFilters.map((x) => {
        if (x.filterType === 'invoiceType') toSend.invoiceType = x.filterData?.value;
        if (x.filterType === 'customer') toSend.customerId = x.filterData?.value;
        if (x.filterType === 'order') toSend.orderId = x.filterData?.value;
        if (x.filterType === 'number') toSend.invoiceNumber = x.filterData;
        if (x.filterType === 'parentReference') toSend.parentInvoiceNumber = x.filterData;
      });
    }

    return toSend;
  }

  const tabFilterOptions = [
    {
      tabLabel: InvoiceType.PROFORMA,
      tabLabelTranslate: t('common.proforma'),
    },
    { tabLabel: InvoiceStatus.UPCOMING, tabLabelTranslate: t('status.' + InvoiceStatus.UPCOMING) },
    { tabLabel: 'overdue', tabLabelTranslate: t('status.' + 'overdue') },
    { tabLabel: InvoiceStatus.PAID, tabLabelTranslate: t('status.' + InvoiceStatus.PAID) },
    { tabLabel: InvoiceStatus.ARCHIVED, tabLabelTranslate: t('status.' + InvoiceStatus.ARCHIVED) },
  ];

  const seeDetails = (row: GetInvoice) => {
    // TODO INTEGRATION : gestion popup si dans sous-fenetre ?
    const url = 'invoices';
    return `/${url}/${row.id}/update`;
  };

  const columns: any[] = [
    {
      name: t('common.invoicingDate'),
      selector: (row: GetInvoice) => row.invoicingDate,
      cell: (row: GetInvoice) => (
        <div className='column' data-tag='allowRowEvents'>
          <div data-tag='allowRowEvents'>
            <div className='caption2medium' data-tag='allowRowEvents'>
              {row.invoicingDate ? moment(row.invoicingDate).tz(siteTimeZone).format('DD/MM/YYYY, HH:mm') : undefined}
            </div>
          </div>
        </div>
      ),
      sortable: true,
      right: false,
      id: 'invoicingDate',
    },
    {
      name: t('common.invoiceDueDate'),
      selector: (row: GetInvoice) => row.invoiceDueDate,
      cell: (row: GetInvoice) => (
        <div className='column' data-tag='allowRowEvents'>
          <div data-tag='allowRowEvents'>
            <div className='caption2medium' data-tag='allowRowEvents'>
              {row.invoiceDueDate ? moment(row.invoiceDueDate).tz(siteTimeZone).format('DD/MM/YYYY, HH:mm') : undefined}
            </div>
          </div>
        </div>
      ),
      sortable: true,
      right: false,
      id: 'invoiceDueDate',
    },
    {
      name: t('common.customer'),
      selector: (row: GetInvoice) => row.customer?.label,
      cell: (row: GetInvoice) => (
        <div className='column' data-tag='allowRowEvents'>
          <div data-tag='allowRowEvents'>
            <strong className='caption2medium' data-tag='allowRowEvents'>
              {row.customer?.label}
            </strong>
          </div>
          <div data-tag='allowRowEvents'>
            <i className='caption2medium' data-tag='allowRowEvents'>
              {row.customer?.externalReference}
            </i>
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      omit: props.forcedFilters && props.forcedFilters?.findIndex((x) => x.filterType == 'customer') !== -1,
      id: 'customer',
    },
    {
      name: t('common.number'),
      selector: (row: GetInvoice) => row.invoiceNumber,
      cell: (row: GetInvoice) => (
        <Link to={seeDetails(row)}>
          <div className='column' data-tag='allowRowEvents'>
            <div>
              <strong className='caption2medium' data-tag='allowRowEvents'>
                {row.invoiceNumber}
              </strong>
            </div>
            <div data-tag='allowRowEvents'>
              <i className='caption2medium' data-tag='allowRowEvents'>
                {row.externalReference}
              </i>
            </div>
          </div>
        </Link>
      ),
      sortable: true,
      right: false,
      id: 'invoiceNumber',
    },
  ];

  // #endregion specific

  // #endregion intializing constants

  // #region event handling

  const handlePageChange = (pageNumber: number) => {
    fetchItems(pageNumber, perPage);
  };

  const handlePerRowsChange = (newPerPage: any) => {
    setPerPage(newPerPage);
    fetchItems(1, newPerPage);
  };

  const onSort = (data: any, newSortOrder: any) => {
    if (data.id == sortColumn) {
      if (sortDirection == 'asc') {
        newSortOrder = 'desc';
      } else {
        newSortOrder = 'asc';
      }
    } else {
      newSortOrder = 'asc';
    }
    fetchItems(1, perPage, data.id, newSortOrder);
  };

  function handleSetFiltersAdvanced(newFilters: AdvancedFilter[]) {
    setFiltersAdvanced(newFilters);
    fetchItems(currentPage, perPage, sortColumn, sortDirection, filtersTab, newFilters);
  }

  function handleSetFiltersStatus(newFilters: string[]) {
    setFiltersTab(newFilters);
    fetchItems(currentPage, perPage, sortColumn, sortDirection, newFilters, filtersAdvanced);
    setSelectedRows([]);
  }

  function handleConvertToInvoice() {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url = orgid + '/invoice/validate-proforma';

    const dataToSend: ValidateProformaParameters = {
      proformas: selectedRows.map((x) => x.id),
    };

    axios
      .post(
        Config.getApiExtranetUrl(url),
        { ...dataToSend },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async (res) => {
        if (res.data.content.invoices.length > 1) {
          addToast(
            t('common.multipleInvoicesValidated').replace('¤', res.data.content.invoices.length),
            ToastTypes.success,
          );
          history('/invoicing/invoices/' + InvoiceStatus.UPCOMING);
          setFilterTabOverride(InvoiceStatus.UPCOMING);
        } else if (res.data.content.invoices.length === 1) {
          addToast(t('common.invoiceValidated'), ToastTypes.success);
          history('/invoices/' + res.data.content.invoices[0] + '/update');
        }

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  }

  function handleCancelProformas() {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url = orgid + '/invoice/cancel-proforma';

    const dataToSend: CancelProformaParameters = {
      proformas: selectedRows.map((x) => x.id),
    };

    axios
      .post(
        Config.getApiExtranetUrl(url),
        { ...dataToSend },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async () => {
        if (dataToSend.proformas.length > 1) {
          addToast(
            t('common.multipleProformasCanceled').replace('¤', dataToSend.proformas.length.toString()),
            ToastTypes.success,
          );
        } else if (dataToSend.proformas.length === 1) {
          addToast(t('common.proformaCanceled'), ToastTypes.success);
        }
        setSelectedRows([]);
        fetchItems(currentPage, perPage, sortColumn, sortDirection, filtersTab, filtersAdvanced);

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  }

  function handleRowSelected(selectedRows: any) {
    setSelectedRows(selectedRows.selectedRows);
  }

  useEffect(() => {
    setFilterTabOverride(undefined); // reset override on callback
  }, [filterTabOverride]);

  // #endregion event handling

  return (
    <>
      <FilterHeader
        title={''}
        allowQuickSearch={true}
        allowAdvancedSearch={true}
        advancedSearchOptions={['invoiceType', 'customer', 'order', 'number', 'parentReference']}
        showAddNew={false}
        forcedFilters={props.forcedFilters}
        quickSearchFilterTypes={['number']}
        advancedSearchFilterChanged={handleSetFiltersAdvanced}
      />
      <TabFilter
        tabArray={tabFilterOptions}
        parent={'Invoice'}
        filterSet={handleSetFiltersStatus}
        defaultTab={props.defaultStatus ?? 'overdue'}
        overrideTab={filterTabOverride}
      />
      {isLoading ?? false ? (
        <div>{t('common.loading')}</div>
      ) : (
        <>
          {filtersTab[0] === InvoiceType.PROFORMA && (
            <FormRow align='left'>
              <Title
                type='label'
                label={
                  selectedRows.length === 0
                    ? filteredItems.length + ' ' + t('common.proformas').toLowerCase()
                    : selectedRows.length === 1
                    ? t('common.oneProformaSelected')
                    : t('common.multipleProformasSelected').replace('¤', selectedRows.length.toString())
                }
                width='auto'
              />
              {selectedRows.length > 0 && (
                <>
                  <Button onClick={handleConvertToInvoice} label={t('common.convertToInvoice')} />
                  <Button label={t('common.cancelProforma')} style='primary-04' onClick={handleCancelProformas} />
                </>
              )}
            </FormRow>
          )}
          <DataTable
            className='table'
            noHeader={true}
            customStyles={TableStyles}
            columns={columns}
            onRowClicked={(row) => history(seeDetails(row))}
            highlightOnHover
            pointerOnHover
            data={filteredItems}
            noDataComponent={<div className='noResults'>{t('common.noResult')}</div>}
            defaultSortAsc={sortDirection == 'asc'}
            defaultSortFieldId={sortColumn}
            onSort={(data, newSortDirection) => onSort(data, newSortDirection)}
            sortServer
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            paginationDefaultPage={currentPage}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationPerPage={perPage}
            paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
            paginationComponentOptions={{
              rowsPerPageText: t('common.perPage'),
              rangeSeparatorText: t('common.onPage'),
              noRowsPerPage: false,
              selectAllRowsItem: false,
              selectAllRowsItemText: 'All',
            }}
            dense
            selectableRows={filtersTab[0] === InvoiceType.PROFORMA}
            onSelectedRowsChange={handleRowSelected}
          />
        </>
      )}
    </>
  );
});

ListInvoices.displayName = 'ListInvoices';
export default ListInvoices;

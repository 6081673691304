import { SelectData, SelectDataOption, SelectProps } from '../../stories/dune/molecules/SelectData';
import axios from 'axios';
import { useState } from 'react';
import Config from '../../Config';
import { useTranslation } from 'react-i18next';
import TripType, { GenericTripType } from '../../models/TripType';
import stringUtils from '../../utils/stringUtils';

class SearchTT {
  genericTripType: GenericTripType[] | undefined;
}

export interface SelectTripTypeOption extends SelectDataOption {
  data: TripType;
}

export const SelectTripType = ({
  setValue,
  register,
  registerName = 'tripType',
  error,
  selectedOptionChanged,
  isPopup,
  onClosePopup,
  searchParent,
  dependsOnSearchParent = false,
  initialOption = undefined,
  forceSelectedOption = undefined,
  ...props
}: SelectProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const [options, setOptions] = useState<SelectTripTypeOption[]>([]);

  const fetchData = () => {
    setIsLoading(true);
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const baseUrl = '/trip-type/get';
    const url = orgid + `${baseUrl}`;

    const dataToSend: SearchTT = {
      genericTripType: (searchParent ?? null) === null ? undefined : (searchParent as GenericTripType[]),
    };

    axios
      .post(Config.getApiExtranetUrl(url), stringUtils.formatFieldsForPost(dataToSend), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setIsLoading(false);
        setFetchedOptions(res.data.content.items);
      })
      .catch(() => {
        setIsLoading(false);
        setFetchedOptions([]);
      });
  };

  const setFetchedOptions = (fetchedOptions: TripType[]) => {
    const options: any[] = [];

    if (fetchedOptions != null) {
      fetchedOptions.forEach((s) => {
        const option = {
          value: s.id,
          label: s.label + (s.externalReference ? ' - ' + s.externalReference : ''),
          data: s,
        };

        options.push(option);
      });
      setOptions(options);
    }
  };

  return (
    <SelectData
      title={t('common.tripType')}
      placeholder={t('common.tripTypeChoose')}
      titleSize={props.titleSize ?? 'large'}
      hoverDescription={props.hoverDescription}
      setValue={setValue}
      register={register}
      registerName={registerName}
      error={error}
      isSelectable={props.isSelectable}
      selectedOptionChanged={selectedOptionChanged}
      clearOnSelect={props.clearOnSelect}
      isLoading={isLoading}
      options={options}
      searchParent={searchParent}
      dependsOnSearchParent={dependsOnSearchParent}
      initialOption={initialOption}
      forceSelectedOption={forceSelectedOption}
      filterText={''}
      fetch={fetchData}
      fetchAtLoad={true}
      onClickNoResult={undefined}
      isPopup={isPopup}
      onClosePopup={onClosePopup}
      inRow={props.inRow}
      isClearable={props.isClearable ?? true}
      noResultText={props.noResultTextOverride}
    ></SelectData>
  );
};

import { SelectData, SelectProps, SelectDataOption } from '../../stories/dune/molecules/SelectData';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Config from '../../Config';
import { useTranslation } from 'react-i18next';
import stringUtils from '../../utils/stringUtils';
import FormContact from './FormContact';
import { ContactEntity } from '@dune-manager/backend-core/dist/models/contact';
import PopupContainer from '../../stories/dune/organisms/PopupContainer';
import Contact from '../../models/Contact';

export interface SelectContactOption extends SelectDataOption {
  data: Contact;
}

interface SelectContactProps extends SelectProps {
  entityType: ContactEntity;
}

export const SelectContact = ({
  setValue,
  register,
  registerName = 'contact',
  error,
  selectedOptionChanged,
  isPopup,
  onClosePopup,
  searchParent,
  entityType,
  dependsOnSearchParent = true,
  initialOption = undefined,
  forceSelectedOption = undefined,
  ...props
}: SelectContactProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const [options, setOptions] = useState<SelectContactOption[]>([]);
  const [createdOption, setCreatedOption] = useState<SelectContactOption | undefined>();
  const [filterText, setFilterText] = useState('');

  const { t } = useTranslation();

  const fetchData = (filterText: string) => {
    setFilterText(filterText);
    if (filterText.length > 0 || (searchParent ?? null) !== null) {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');

      const baseUrl = '/contact/get';
      const url = orgid + `${baseUrl}?onlyActive=true&page=${1}&limit=${200}&search=${filterText}`;

      const dataToSend: any = {
        entityId: (searchParent ?? null) === null ? undefined : dependsOnSearchParent ? searchParent : undefined,
        contactEntity: dependsOnSearchParent ? entityType : undefined,
      };

      axios
        .post(Config.getApiExtranetUrl(url), stringUtils.formatFieldsForPost(dataToSend), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsLoading(false);
          setFetchedOptions(res.data.content.items);
        })
        .catch(() => {
          setIsLoading(false);
          setFetchedOptions([]);
        });
    } else {
      setFetchedOptions([]);
    }
  };

  const setFetchedOptions = (fetchedOptions: Contact[]) => {
    const options: any[] = [];

    if (fetchedOptions != null) {
      fetchedOptions.forEach((s) => {
        const option = {
          value: s.id,
          label: (
            s.firstname +
            ' ' +
            s.lastname +
            ' (' +
            (s.telephone ? s.telephone + (s.email ? ' - ' : '') : '') +
            (s.email ? s.email : '') +
            ')' +
            (s.companyRole ? ' - ' + s.companyRole : '')
          ).replace('()', ''),
          searchParentRef: dependsOnSearchParent ? searchParent : undefined,
          data: s,
        };

        options.push(option);
      });

      setOptions(options);
    }
  };

  useEffect(() => {
    if (dependsOnSearchParent && searchParent == null) {
      setOptions([]);
    }
  }, [searchParent]);

  const [showAddPopup, setShowAddPopup] = useState(false);

  return (
    <>
      {showAddPopup ? (
        <PopupContainer hideBottomBar={true} isLarge={true}>
          <FormContact
            contactEntity={entityType}
            entityId={searchParent ?? undefined}
            lastname={''}
            firstname={''}
            telephone={''}
            email={''}
            companyRole={''}
            isPopup={false}
            onClosePopup={(validate: boolean, contact: any | undefined) => {
              setShowAddPopup(false);
              if (validate && contact != undefined) {
                setCreatedOption({
                  value: contact?.id ?? '',
                  label:
                    contact.firstname +
                    ' ' +
                    contact?.lastname +
                    ' (' +
                    contact?.telephone +
                    ' - ' +
                    contact?.email +
                    ')' +
                    (contact?.companyRole ? ' - ' + contact?.companyRole : ''),
                  searchParentRef: searchParent,
                  data: contact,
                });
              }
            }}
          />
        </PopupContainer>
      ) : (
        <SelectData
          title={props.titleOverride ?? t('common.contact')}
          placeholder={t('common.contactChoose')}
          titleSize={props.titleSize ?? 'large'}
          setValue={setValue}
          register={register}
          registerName={registerName}
          error={error}
          isSelectable={props.isSelectable}
          selectedOptionChanged={selectedOptionChanged}
          clearOnSelect={props.clearOnSelect}
          isLoading={isLoading}
          options={options}
          searchParent={searchParent}
          dependsOnSearchParent={dependsOnSearchParent}
          initialOption={initialOption}
          forceSelectedOption={createdOption ?? forceSelectedOption}
          filterText={filterText}
          fetch={fetchData}
          fetchAtLoad={searchParent && dependsOnSearchParent ? true : false}
          onClickNoResult={() => {
            setShowAddPopup(true);
          }}
          isPopup={isPopup}
          onClosePopup={onClosePopup}
          noResultText={props.noResultTextOverride}
        ></SelectData>
      )}
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Config from '../../../Config';
import axios from 'axios';
import useToast from '../../../hooks/use-toast';
import { ToastTypes } from '../../../models/ToastTypes';
import { Head } from '../../../stories/dune/molecules/Head';
import Menu from '../../Menu';
import GeneralCustomer from './GeneralCustomer';
import SubMenuCustomer from './SubMenuCustomer';
import { GetCustomer } from '../../../models/Customer';
import PopupContainer from '../../../stories/dune/organisms/PopupContainer';
import ListTrips from '../../lists/Trips/ListTrips';
import ListOrders from '../../lists/Orders/ListOrders';
import ListContacts from '../../lists/Contacts/ListContacts';
import { ContactEntity } from '@dune-manager/backend-core/dist/models/contact';
import ListAdditionalDocs from '../../lists/AdditionalDocs/ListAdditionalDocs';
import ListBuildingSites from '../../lists/BuildingSites/ListBuildingSites';
import ListInvoices from '../../lists/Invoices/ListInvoices';
import ListContracts from '../../lists/Contracts/ListContracts';

interface DetailCustomerProps {
  id?: string;

  isPopup?: boolean;
  onClosePopup?: (createdId: string | undefined) => void;
  mode?: string;
}

const DetailCustomer = React.memo((props: DetailCustomerProps) => {
  // #region generic

  const { t } = useTranslation();
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState('general');

  const history = useNavigate();

  type Params = {
    id: string;
    mode: string;
    autosign?: string;
    tab?: string;
  };

  const { id, mode, tab } = useParams<Params>();

  function updateCurrentTab(mode: string) {
    setCurrentTab(mode);
  }

  function getMode() {
    return props.mode ?? mode ?? '';
  }

  // #endregion generic

  // #region specific
  const [getCustomer, setGetCustomer] = useState<GetCustomer>();

  function getCurrentCustomer(id: string | undefined) {
    if (id && getMode() === 'update') {
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');
      const baseUrl = orgid + '/customer/detail/';
      const url = Config.getApiExtranetUrl(baseUrl + id);
      setIsLoading(true);
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if ((res.data.content ?? null) !== null) {
            // on enregistre GetCustomer
            setGetCustomer(res.data.content);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          if ((id ?? null) !== null) {
            addToast(
              (error?.response?.data?.code
                ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
                : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
              ToastTypes.error,
            );
          }
        });
    } else setIsLoading(false);
  }

  useEffect(() => {
    getCurrentCustomer(id);
  }, [id, getMode()]);

  useEffect(() => {
    if (tab) setCurrentTab(tab);
  }, [tab]);

  // #endregion specific

  const content = (
    <>
      {!isLoading && (
        <>
          {getMode() === 'add' && (
            <GeneralCustomer
              mode={getMode()}
              customerData={getCustomer}
              onCancel={onCanceled}
              onConfirm={onConfirmed}
            />
          )}

          {getMode() !== 'add' && (
            <>
              <SubMenuCustomer tab={currentTab} updateTab={updateCurrentTab} />
              {currentTab === 'general' && (
                <GeneralCustomer
                  id={id}
                  mode={getMode()}
                  customerData={getCustomer}
                  onCancel={onCanceled}
                  onConfirm={onConfirmed}
                />
              )}
              {currentTab === 'contact' && id && (
                <ListContacts
                  contactEntity={ContactEntity.CUSTOMER}
                  entityId={id}
                  canAdd={true}
                  canEdit={true}
                  canRemove={true}
                  canSelect={false}
                />
              )}
              {currentTab === 'contract' && id && (
                <ListContracts
                  forcedFilters={[
                    {
                      filterType: 'customer',
                      filterData: {
                        value: getCustomer?.id,
                        label: getCustomer?.label + ' - ' + getCustomer?.externalReference,
                        data: getCustomer,
                      },
                    },
                  ]}
                />
              )}
              {currentTab === 'buildingSite' && id && (
                <ListBuildingSites
                  canAddNew={false}
                  forcedFilters={[
                    {
                      filterType: 'customer',
                      filterData: {
                        value: getCustomer?.id,
                        label: getCustomer?.label + ' - ' + getCustomer?.externalReference,
                        data: getCustomer,
                      },
                    },
                  ]}
                />
              )}
              {currentTab === 'order' && getCustomer && (
                <ListOrders
                  canAddNew={true}
                  forcedFilters={[
                    {
                      filterType: 'customer',
                      filterData: {
                        value: getCustomer?.id,
                        label: getCustomer?.label + ' - ' + getCustomer?.externalReference,
                        data: getCustomer,
                      },
                    },
                  ]}
                />
              )}
              {currentTab === 'trip' && getCustomer && (
                <ListTrips
                  canAddNew={false}
                  forcedFilters={[
                    {
                      filterType: 'customer',
                      filterData: {
                        value: getCustomer?.id,
                        label: getCustomer?.label + ' - ' + getCustomer?.externalReference,
                        data: getCustomer,
                      },
                    },
                  ]}
                />
              )}
              {currentTab === 'invoice' && getCustomer && (
                <ListInvoices
                  forcedFilters={[
                    {
                      filterType: 'customer',
                      filterData: {
                        value: getCustomer?.id,
                        label: getCustomer?.label + ' - ' + getCustomer?.externalReference,
                        data: getCustomer,
                      },
                    },
                  ]}
                />
              )}
              {currentTab === 'documents' && getCustomer && (
                <ListAdditionalDocs entityType='customer' entityId={getCustomer.id ?? ''} canAddNew={true} />
              )}
            </>
          )}
        </>
      )}
    </>
  );

  function onConfirmed(data: any) {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(data);
    } else {
      history('/customers');
    }
  }
  function onCanceled() {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(undefined);
    } else {
      history('/customers');
    }
  }

  return (
    <>
      {props.isPopup ? (
        <PopupContainer
          hideBottomBar={true}
          isLarge={true}
          title={
            getMode() === 'add'
              ? t('common.newCustomer')
              : t('common.customer') +
                ' ' +
                (!getCustomer
                  ? ''
                  : getCustomer.label + (getCustomer.externalReference ? '-' + getCustomer.externalReference : ''))
          }
        >
          {content}
        </PopupContainer>
      ) : (
        <div className='main-container'>
          <Menu selectedPage='customers'></Menu> {/* TODO : sortir et mettre dans classe 'parent'*/}
          <div className='right detail-container'>
            <Head
              title={
                getMode() === 'add'
                  ? t('common.newCustomer')
                  : t('common.customer') +
                    ' ' +
                    (!getCustomer
                      ? ''
                      : getCustomer.label + (getCustomer.externalReference ? '-' + getCustomer.externalReference : ''))
              }
              back={true}
              OnNotificationClick={function (): void {
                throw new Error('Function not implemented.');
              }}
              OnAutorisationClick={function (): void {
                throw new Error('Function not implemented.');
              }}
              Back={function (): void {
                history('/customers');
              }}
            />
            {content}
          </div>
        </div>
      )}
    </>
  );
});

DetailCustomer.displayName = 'DetailCustomer';
export default DetailCustomer;

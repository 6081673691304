import { SelectData, SelectProps, SelectDataOption } from '../../stories/dune/molecules/SelectData';
import axios from 'axios';
import { useState } from 'react';
import Config from '../../Config';
import { useTranslation } from 'react-i18next';
import BuildingSite from '../../models/BuildingSite';
import stringUtils from '../../utils/stringUtils';
import DetailBuildingSite from '../pages/BuildingSites/DetailBuildingSite';
import { SelectCustomerOption } from './SelectCustomer';

class SearchBS {
  customerId: string | undefined;
}

export interface SelectBuildingSiteOption extends SelectDataOption {
  data: BuildingSite;
}

export const SelectBuildingSite = ({
  setValue,
  register,
  registerName = 'chantier',
  error,
  selectedOptionChanged,
  isPopup,
  onClosePopup,
  searchParent,
  dependsOnSearchParent = true,
  initialOption = undefined,
  forceSelectedOption = undefined,
  ...props
}: SelectProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const [options, setOptions] = useState<SelectBuildingSiteOption[]>([]);
  const [createdOption, setCreatedOption] = useState<SelectBuildingSiteOption | undefined>();
  const [filterText, setFilterText] = useState('');

  const { t } = useTranslation();

  const fetchData = (filterText: string) => {
    setFilterText(filterText);
    if (filterText.length > 0 || (searchParent ?? null) !== null) {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');

      const baseUrl = '/building-site/get';
      const url = orgid + `${baseUrl}?onlyActive=true&page=${1}&limit=${200}&search=${filterText}`;

      const dataToSend: SearchBS = {
        customerId: (searchParent ?? null) === null ? undefined : searchParent,
      };

      axios
        .post(Config.getApiExtranetUrl(url), stringUtils.formatFieldsForPost(dataToSend), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsLoading(false);
          setFetchedOptions(res.data.content.items);
        })
        .catch(() => {
          setIsLoading(false);
          setFetchedOptions([]);
        });
    } else {
      setFetchedOptions([]);
    }
  };

  const setFetchedOptions = (fetchedOptions: BuildingSite[]) => {
    const options: any[] = [];

    if (fetchedOptions != null) {
      fetchedOptions.forEach((s) => {
        const option = {
          value: s.id,
          label:
            s.label +
            (s.externalReference ? ' - ' + s.externalReference : '') +
            (props.showParent ? ' (' + s.customer?.label + ')' : ''),
          searchParentRef: s.customerId,
          data: s,
        };

        options.push(option);
      });

      setOptions(options);
    }
  };

  const [showAddPopup, setShowAddPopup] = useState(false);

  function addPopupClosedHandler(createdId: string | undefined) {
    setShowAddPopup(false);

    if (createdId) {
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');

      const baseUrl = '/building-site/detail/';
      const url = orgid + baseUrl + createdId;

      axios
        .get(Config.getApiExtranetUrl(url), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsLoading(false);
          setCreatedOption({
            value: res.data.content.id,
            label:
              res.data.content.label +
              (res.data.content.externalReference ? ' - ' + res.data.content.externalReference : ''),
            data: res.data.content,
          });
        })
        .catch(() => {
          setIsLoading(false);
          setFetchedOptions([]);
        });
    }
  }

  return (
    <>
      {showAddPopup && (
        <DetailBuildingSite mode='add' isPopup={true} onClosePopup={addPopupClosedHandler} dataParent={searchParent} />
      )}
      <SelectData
        title={props.titleOverride ?? t('common.buildingSite')}
        placeholder={props.placeholder ?? t('common.buildingSiteChoose')}
        hoverDescription={props.hoverDescription ?? t('common.buildingSiteDesc')}
        titleSize={props.titleSize ?? 'large'}
        setValue={setValue}
        register={register}
        registerName={registerName}
        error={error}
        isSelectable={props.isSelectable}
        selectedOptionChanged={selectedOptionChanged}
        clearOnSelect={props.clearOnSelect}
        isLoading={isLoading}
        options={options}
        searchParent={searchParent}
        dependsOnSearchParent={dependsOnSearchParent}
        initialOption={initialOption}
        forceSelectedOption={createdOption ?? forceSelectedOption}
        filterText={filterText}
        fetch={fetchData}
        fetchAtLoad={searchParent ? true : false}
        onClickNoResult={() => {
          setShowAddPopup(true);
        }}
        noResultText={props.noResultTextOverride}
        isPopup={isPopup}
        onClosePopup={onClosePopup}
      ></SelectData>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import './Login.scss';
import '../index.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Config from '../Config';
import { Buffer } from 'buffer';
import { useTranslation } from 'react-i18next';
import Lang from './Lang';
import { ReactComponent as Logo } from '../assets/svg/dune-logo.svg';
import SelectWorkspace from './SelectWorkspace';
import Roles from '../utils/Roles';
import { Button } from '../stories/dune/atoms/Button';
import { FormRow } from '../stories/dune/atoms/FormRow';
import PopupContainer from '../stories/dune/organisms/PopupContainer';
import { Input } from '../stories/dune/atoms/Input';
import { ErrorLine } from '../stories/dune/atoms/ErrorLine';
import { AppDispatch } from '../store';
import { useDispatch } from 'react-redux';
import { loadStaticData } from '../store/staticData-slice';

const schema = yup.object().shape({
  email: yup.string().email('Adresse email non valide').required('Le mail est obligatoire'),
  password: yup.string().required('Le mot de passe est obligatoire'),
});

function Login() {
  const { t } = useTranslation();

  const [resetPasswordEmail, setResetPasswordEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [resultResetPassword, setResultResetPassword] = useState('');
  const [hasError, setHasError] = useState(false);

  const [isSelectingOrga, setIsSelectingOrga] = useState(false);
  const [isOpenForgotPassword, setIsOpenForgotPassword] = useState(false);

  const history = useNavigate();
  const dispatch = useDispatch<AppDispatch>(); // The `useDispatch` hook with AppDispatch type to call async thunk actions

  useEffect(() => {
    localStorage.clear();
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const chooseWorkspace = () => {
    history('/trips');
  };

  const onSubmit = (data: any) => {
    const dataToSend = {
      username: data.email,
      password: Buffer.from(data.password).toString('base64'),
    };

    axios
      .post(Config.getApiUrl() + 'auth/login', dataToSend)
      .then((res) => {
        const token = res.data.content.accessToken;
        localStorage.setItem('token', token);
        localStorage.setItem('email', dataToSend.username);
        getScopes();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            setErrorMessage(t('login.IncorrectCredentials'));
          }
        } else if (error.request) {
          setErrorMessage(t('login.ErrorMessage'));
        } else {
          setErrorMessage(t('login.ErrorMessage'));
        }
      });
  };

  const getScopes = () => {
    const token = localStorage.getItem('token');
    axios
      .get(Config.getApiUrl() + 'me?d=' + new Date().toISOString(), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const workspaces = Roles.saveWorkspaces(res.data.content.workspaces);
        Roles.saveUser(res.data.content.uuid);

        if (workspaces.length > 1) {
          setIsSelectingOrga(true);
        } else {
          Roles.setLocalStorage(workspaces[0]);
          dispatch(loadStaticData()); // Load static data once authenticated (called at refresh by APP as well)
          history('/trips');
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            setErrorMessage(t('login.IncorrectCredentials'));
          } else {
            setErrorMessage(t('login.ErrorMessage'));
          }
        } else if (error.request) {
          setErrorMessage(t('login.ErrorMessage'));
        } else {
          setErrorMessage(t('login.ErrorMessage'));
        }
      });
  };

  const onKeyPressEvent = async (event: any) => {
    if (event.key === 'Enter') {
      await handleSubmit(onSubmit)();
    }
  };

  const onSendEmail = () => {
    const dataToSend = {
      username: resetPasswordEmail,
    };
    axios
      .post(Config.getUrl('auth/forgot-password'), dataToSend)
      .then(() => {
        setResultResetPassword(t('login.sendEmail'));
        setHasError(false);
      })
      .catch((error) => {
        setHasError(true);
        if (error.response) {
          if (error.response.status == 400) {
            if (error.response.data.code == 'ERR4000003') {
              setResultResetPassword(t('login.resetPassword.results.error'));
            } else {
              setResultResetPassword(t('login.ErrorMessage'));
            }
          }
          if (error.response.status == 401) {
            setResultResetPassword(t('login.IncorrectCredentials'));
          }
        } else if (error.request) {
          setResultResetPassword(t('login.ErrorMessage'));
        } else {
          setResultResetPassword(t('login.ErrorMessage'));
        }
      });
  };

  return (
    <div className='login'>
      <div className='leftImage'></div>
      <div className='right'>
        <div style={{ textAlign: 'center' }}>
          <Logo></Logo>
        </div>

        <h1 className='title-center'>{t('login.welcomeMessage')}</h1>
        <div className='content'>
          {!isSelectingOrga ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <h5>{t('login.email')}</h5>

              <input type='text' {...register('email', {})} name='email' placeholder='Dune.manager@gmail.com' />
              <p className='validator-error'>{errors.email?.message}</p>

              <h5>{t('login.password')}</h5>

              <input
                type='password'
                {...register('password', {})}
                name='password'
                placeholder='***********'
                onKeyPress={onKeyPressEvent}
              />

              <p className='validator-error'>{errors.password?.message}</p>

              <p className='validator-error'>{errorMessage}</p>

              <div onClick={() => setIsOpenForgotPassword(true)} className='link'>
                {t('login.forgetPassword')}
              </div>
              <div style={{ marginTop: '20px' }} />
              <FormRow>
                <Button
                  size='widelarge'
                  label={t('login.connexion')}
                  style='primary'
                  onClick={handleSubmit(onSubmit)}
                />
              </FormRow>
            </form>
          ) : (
            <div>
              <p className='chooseWorkspaceExplanation'>
                Vous êtes associés à plusieurs workspaces Dune. Choisissez un workspace pour vous connecter :
              </p>
              <SelectWorkspace />

              <FormRow>
                <Button size='widelarge' label={t('login.access')} style='primary' onClick={() => chooseWorkspace()} />
              </FormRow>
            </div>
          )}
        </div>
      </div>
      {isOpenForgotPassword && (
        <PopupContainer
          isLarge={false}
          title={t('login.forgetPasswordTitle')}
          confirmIcon=''
          confirmLabel={t('common.validate')}
          onCancel={() => setIsOpenForgotPassword(false)}
          onConfirm={() => {
            onSendEmail();
            setIsOpenForgotPassword(false);
          }}
        >
          <div className='form-section'>
            <Input
              label={t('login.email')}
              type='text'
              value={resetPasswordEmail}
              placeholder='Dune.manager@gmail.com'
              disabled={false}
              onChange={(e) => setResetPasswordEmail(e.toString())}
              error={''}
            />
            {hasError && <ErrorLine label={resultResetPassword} />}
          </div>
        </PopupContainer>
      )}

      <div className='login langSelector'>
        <Lang></Lang>
      </div>
    </div>
  );
}

export default Login;

import React from 'react';
import './Changelog.scss';
import '../styles/table.scss';
import '../styles/popup.scss';
import Menu from './Menu';
import { useTranslation } from 'react-i18next';

const Changelog = React.memo(() => {
  const { t } = useTranslation();

  return (
    <div className='updatePassword'>
      <Menu selectedPage='Changelog'></Menu>
      <div className='right detail-container'>
        <div className='detail-header'>
          <div className='buttonContainer'>
            <h1 className='title'>Changelog</h1>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.15.1 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.15.1-1')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.15.0 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.15.0-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.15.0-2')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.15.0-3')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.15.0-4')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.15.0-5')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.14.1 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.14.1-1')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.14.0 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.14.0-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.14.0-2')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.14.0-3')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.14.0-4')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.13.0 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.13.0-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.13.0-2')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.12.0 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.12.0-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.12.0-2')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.11.4 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.11.4-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.11.4-2')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.11.4-3')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.11.4-4')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.11.3 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.11.3-1')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.11.2 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.11.2-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.11.2-2')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.11.2-3')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.11.2-4')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.11.2-5')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.11.2-6')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.11.1 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.11.1-1')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.11.0 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.11.0-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.11.0-2')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.11.0-3')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.11.0-4')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.11.0-5')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.11.0-6')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.11.0-7')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.11.0-8')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.11.0-9')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.11.0-10')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.11.0-11')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.11.0-12')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.11.0-13')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.11.0-14')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.11.0-15')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.11.0-16')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.11.0-17')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.10.0 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.10.0-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.10.0-1b')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.10.0-2')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.10.0-3')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.10.0-4')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.10.0-5')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.10.0-6')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.10.0-7')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.10.0-8')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.10.0-9')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.10.0-10')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.10.0-11')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.9.0 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.9.0-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.9.0-2')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.9.0-3')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.9.0-4')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.9.0-5')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.9.0-6')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.9.0-7')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.9.0-8')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.8.10 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.8.10-1')}</p>
          </div>
          <div className='versionTitle'>
            <h3>v1.8.9 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.8.9-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.9-2')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.8.8 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.8.8-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.8-2')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.8-3')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.8-4')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.8-5')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.8-6')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.8-7')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.8.7 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.8.7-1')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.8.6 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.8.6-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.6-2')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.6-3')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.6-4')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.6-5')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.6-6')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.6-7')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.8.5 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.8.5-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.5-2')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.5-3')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.5-4')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.5-5')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.8.4 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.8.4-1')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.8.3 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.8.3-1')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.8.2 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.8.2-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.2-2')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.2-3')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.2-4')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.2-5')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.2-6')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.2-7')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.2-8')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.8.1 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.8.1-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.1-2')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.1-3')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.1-4')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.1-5')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.1-6')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.1-7')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.1-8')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.8.0 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.8.0-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.0-2')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.0-3')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.0-4')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.0-5')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.0-6')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.0-7')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.8.0-8')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.7.4 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.7.4-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.7.4-2')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.7.4-3')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.7.4-4')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.7.4-5')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.7.4-6')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.7.4-7')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.7.3 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.7.3-1')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.7.2 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.7.2-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.7.2-2')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.7.2-3')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.7.2-4')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.7.2-5')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.7.1 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.7.1-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.7.1-2')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.7.1-3')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.7.1-4')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.7.1-5')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.7.1-6')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.7.1-7')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.6.0 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.6.0-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.6.0-2')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.5.1 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.5.1-1')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.5.0 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.5.0-1')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.4.0 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.4.0-1')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.3.0 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.3.0-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.3.0-2')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.3.0-3')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.3.0-4')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.3.0-5')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.3.0-6')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.3.0-7')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.3.0-8')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.2.10 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.2.10-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.2.10-2')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.2.10-3')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.2.9 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.2.9-1')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.2.8 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.2.8-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.2.8-2')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.2.8-3')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.2.8-4')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.2.7 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.2.7-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.2.7-2')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.2.7-3')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.2.6 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.2.6-1')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.2.5 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.2.5-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.2.5-2')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.2.4 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.2.4-1')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.2.3 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.2.3-1')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.2.2 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.2.2-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.2.2-2')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.2.2-3')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.2.1 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.2.1-1')}</p>
          </div>
        </div>
        <div className='versionBlock'>
          <div className='versionTitle'>
            <h3>v1.2.0 : </h3>
          </div>
          <div className='versionDescription'>
            <p className='versionParagraph base2'>{t('changelog.1.2.0-1')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.2.0-2')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.2.0-3')}</p>
            <p className='versionParagraph base2'>{t('changelog.1.2.0-4')}</p>
          </div>
        </div>
      </div>
    </div>
  );
});

Changelog.displayName = 'Changelog';
export default Changelog;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { Button } from '../../../stories/dune/atoms/Button';
import '../SubMenu.scss';

interface SubMenuZoneProps {
  tab: string;
  updateTab: any;
}

const SubMenuZone = React.memo((props: SubMenuZoneProps) => {
  const { t } = useTranslation();

  return (
    <div className='submenu'>
      <FormRow>
        <Button
          label={t('common.generalInformations')}
          size='wide'
          style={props.tab === 'general' ? 'primary' : 'white'}
          onClick={() => props.updateTab('general')}
        />
        <Button
          label={t('common.products')}
          size='wide'
          style={props.tab === 'products' ? 'primary' : 'white'}
          onClick={() => props.updateTab('products')}
        />
        <Button
          label={t('common.childZones')}
          size='wide'
          style={props.tab === 'childzones' ? 'primary' : 'white'}
          onClick={() => props.updateTab('childzones')}
        />

        <Button
          label={t('common.documents')}
          size='wide'
          style={props.tab === 'documents' ? 'primary' : 'white'}
          onClick={() => props.updateTab('documents')}
        />
      </FormRow>
    </div>
  );
});

SubMenuZone.displayName = 'SubMenuZone';
export default SubMenuZone;

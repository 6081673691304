export enum TypeVehicleTranslate {
  truck = 'Camion',
  trailer = 'Remorque',
  semiTrailerTruck = 'Semi-remorque',
  smallTruck = 'Petit camion',
  car = 'Voiture',
  dumper = 'Dumper',
  other = 'Autre',
}

export type isActiveList = {
  [key: string]: boolean;
};

export enum TypeZoneTranslate {
  process = 'Zone d\'activité',
  waiting = 'Zone d\'attente',
  transit = 'Zone de Transit / Roulage',
}

export enum TypeTripTranslate {
  authorization = 'Autorisation',
  load = 'Charge',
  unload = 'Decharge',
}

export enum ProductUnitList {
  TON = 'ton',
  M3 = 'm3',
  KG = 'kg',
  UNIT = 'unité',
  L = 'l',
}

export enum TripStatusTranslate {
  // Unloading = 'Déchargement',
  // Completed = 'Complété',
  Planned = 'Planifié',
  Started = 'Démarré',
  WaitingForSpaceEntry = 'Attente place entrée',
  WaitingForEquipmentEntry = 'Attente automatisme entrée',
  WaitingForManagerEntry = 'Attente DuneManager entrée',
  WaitingForErpEntry = 'Attente ERP entrée',
  WaitingForDriverEntry = 'Attente chauffeur entrée',
  OnSite = 'Sur site',
  WaitingForSpaceProcessing = 'Attente place traitement',
  WaitingForEquipmentProcessing = 'Attente automatisme traitement',
  WaitingForErpProcessing = 'Attente ERP traitement',
  WaitingForManagerProcessing = 'Attente DuneManager traitement',
  WaitingForAppProcessing = 'A traiter',
  Processing = 'Traitement en cours',
  Processed = 'Traité',
  WaitingForSpaceExit = 'Attente place sortie',
  WaitingForEquipmentExit = 'Attente automatisme sortie',
  WaitingForManagerExit = 'Attente DuneManager sortie',
  WaitingForErpExit = 'Attente ERP sortie',
  WaitingForDriverExit = 'Attente chauffeur sortie',
  CanExit = 'Peut sortir',
  AskingForExit = 'Demande sortie',
  Exited = 'Parti',
  Refused = 'Refusé',
  Canceled = 'Annulé',
  Deleted = 'Supprimé',
  Created = 'Créé',
  Anomaly = 'Anomalie',
  CanEnter = 'Peut entrer',
  GoToReception = 'Retourne à l\'accueil',
}

export enum TripStatusTranslateView {
  Planned = 'Planifié',
  Started = 'Démarré',
  CanEnter = 'Peut entrer',
  WaitingForAppProcessing = 'A traiter',
  Processing = 'Traitement en cours',
  Processed = 'Traité',
  Anomaly = 'Anomalie',
  GoToReception = 'Retourne à l\'accueil',
  CanExit = 'Peut sortir',
  Refused = 'Refusé',
  Canceled = 'Annulé',
  Deleted = 'Supprimé',
  Exited = 'Parti',
}

export enum TripPriorityTranslate {
  High = 'Haute',
  Medium = 'Normale',
  Low = 'Bas',
}

export type listCountry = {
  [key: string]: string;
};

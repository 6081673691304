export interface AdditionalDocType {
  id: string;
  label: string;
  description?: string;
  extensions?: string;
}

export enum DocTypes {
  DELIVERY_NOTE = 'DeliveryNote',
  INVOICE = 'Invoice',
  PROFORMA = 'Proforma',
  PLATE_PICTURE = 'PlatePicture',
  BED_PICTURE = 'BedPicture',
  DOCUMENT_PICTURE = 'DocumentPicture',
  ZONE_PLAN = 'ZonePlan',
  APP_LOG = 'AppLog',
  WAYBILL = 'Waybill',
  LOADING_TICKET = 'LoadingTicket',
  REFUSAL_PICTURE = 'RefusalPicture',
  SITE_LOGO = 'SiteLogo',
  OTHER_DOCUMENT = 'OtherDocument',
  SIGNATURE = 'Signature',
  COMMENT_PICTURE = 'CommentPicture',
  QUOTATION = 'quotation',
  REP_BUILDINGSITE_EXEMPTION = 'RepBuildingSiteExemption',
  REP_CUSTOMER_EXEMPTION = 'RepCustomerExemption',
  WASTE_REQUEST = 'WasteRequest',
}

export enum DocTypeIds {
  DELIVERY_NOTE = '8486878a-a432-11ec-b466-0fbf1f46271d',
  INVOICE = '824b40bc-cab3-11ec-8d20-078b2e961056',
  PROFORMA = '0a8458cd-2c36-463f-85b3-0cd39e46cc08',
  PLATE_PICTURE = '060e2736-cbb6-11ec-ae09-570dd1379401',
  BED_PICTURE = '0acba26c-cbb6-11ec-ae84-a71e1b3e7206',
  DOCUMENT_PICTURE = '0df8bfb0-cbb6-11ec-807f-1b9748b871b5',
  ZONE_PLAN = '117c1c40-cbb6-11ec-afb9-ab1176b1cd08',
  APP_LOG = '180956fe-cbb6-11ec-97ee-bb6db05e2fb2',
  WAYBILL = 'b768a426-a432-11ec-8b25-a30667ef85b0',
  LOADING_TICKET = 'c2d64caa-a432-11ec-8eff-ab7de7cdf37a',
  REFUSAL_PICTURE = '19ae92f2-cd4c-11ec-beb4-cfe86bc9dd64',
  SITE_LOGO = 'db15cbe8-dc35-11ec-b5c3-978ba48446b5',
  OTHER_DOCUMENT = '1baf5bec-064f-4d79-987a-ae4f3e7f40f4',
  SIGNATURE = 'dec5bac5-b01a-4284-bcb3-272d44914d11',
  COMMENT_PICTURE = 'f3947820-652f-4380-8eb3-86615fa1ea9a',
  QUOTATION = 'b011cd9c-f084-4f8c-8014-e8f2ccaa4be4',
  REP_BUILDINGSITE_EXEMPTION = 'defba192-3e8e-4f24-bb5f-208619ef9d3e',
  REP_CUSTOMER_EXEMPTION = '2a10186f-7e1d-49ac-8be8-89933c7839fa',
  WASTE_REQUEST = '65735459-3e31-404c-af1e-f8124ec1e3f2',
}
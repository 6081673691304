import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { Button } from '../../../stories/dune/atoms/Button';
import '../SubMenu.scss';

interface SubMenuCustomerProps {
  tab: string;
  updateTab: any;
}

const SubMenuCustomer = React.memo((props: SubMenuCustomerProps) => {
  const { t } = useTranslation();

  return (
    <div className='submenu'>
      <FormRow>
        <Button
          label={t('common.generalInformations')}
          size='wide'
          style={props.tab === 'general' ? 'primary' : 'white'}
          onClick={() => props.updateTab('general')}
        />
        <Button
          label={t('common.contracts')}
          size='wide'
          style={props.tab === 'contract' ? 'primary' : 'white'}
          onClick={() => props.updateTab('contract')}
        />
        <Button
          label={t('common.buildingSites')}
          size='wide'
          style={props.tab === 'buildingSite' ? 'primary' : 'white'}
          onClick={() => props.updateTab('buildingSite')}
        />
        <Button
          label={t('common.orders')}
          size='wide'
          style={props.tab === 'order' ? 'primary' : 'white'}
          onClick={() => props.updateTab('order')}
        />
        <Button
          label={t('common.trips')}
          size='wide'
          style={props.tab === 'trip' ? 'primary' : 'white'}
          onClick={() => props.updateTab('trip')}
        />
        <Button
          label={t('common.invoices')}
          size='wide'
          style={props.tab === 'invoice' ? 'primary' : 'white'}
          onClick={() => props.updateTab('invoice')}
        />
        <Button
          label={t('common.documents')}
          size='wide'
          style={props.tab === 'documents' ? 'primary' : 'white'}
          onClick={() => props.updateTab('documents')}
        />
        <Button
          label={t('common.contacts')}
          size='wide'
          style={props.tab === 'contact' ? 'primary' : 'white'}
          onClick={() => props.updateTab('contact')}
        />
      </FormRow>
    </div>
  );
});

SubMenuCustomer.displayName = 'SubMenuCustomer';
export default SubMenuCustomer;

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastTypes } from '../../../models/ToastTypes';
import useToast from '../../../hooks/use-toast';
import Config from '../../../Config';
import FilterHeader, { AdvancedFilter } from '../FilterHeader';
import {
  GetQuotation as GetContract,
  GetQuotationParameters as GetContractParameters,
} from '../../../models/Quotation';
import DataTable from 'react-data-table-component';
import TableStyles from '../../../styles/tableStyles';
import moment from 'moment';

interface ListContractsProps {
  defaultStatus?: string;
  forcedFilters?: AdvancedFilter[];
}

const ListContracts = React.memo((props: ListContractsProps) => {
  // #region intializing constants

  // #region generic
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(true);

  const history = useNavigate();

  const [filtersAdvanced, setFiltersAdvanced] = useState<AdvancedFilter[]>(props.forcedFilters ?? []);
  const [filtersTab, setFiltersTab] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState(20);
  const [sortColumn, setSortColumn] = useState('dateStart');
  const [sortDirection, setSortDirection] = useState('desc');
  const [totalRows, setTotalRows] = useState(0);
  const [totalResource, setTotalResource] = useState(0);

  // #endregion generic

  // #region specific

  const [filteredItems, setFilteredItems] = useState<GetContract[]>([]);

  const fetchItems = (
    page: number,
    newPerPage: number,
    newSortColumn: string = sortColumn,
    newSortDirection: string = sortDirection,
    TabFilter: string[] = filtersTab,
    AdvancedFilters: AdvancedFilter[] = filtersAdvanced,
  ) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url =
      orgid +
      '/quotation/get?page=' +
      page +
      '&limit=' +
      newPerPage +
      '&sortBy=' +
      newSortColumn +
      '&orderBy=' +
      newSortDirection;

    setCurrentPage(page);
    setIsLoading(true);
    setFilteredItems([]);

    if (newSortColumn != sortColumn) {
      setSortColumn(newSortColumn);
    }

    if (newSortDirection != sortDirection) {
      setSortDirection(newSortDirection);
    }

    axios
      .post(
        Config.getApiExtranetUrl(url),
        { ...getFiltersToSend(TabFilter, AdvancedFilters), quotationType: 'Contract' },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async (res) => {
        setTotalRows(res.data.meta.total);
        setTotalResource(res.data.meta.total);
        setFilteredItems(res.data.content.items);

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  function getFiltersToSend(tabFilters: string[], advancedFilters: AdvancedFilter[]) {
    const toSend: GetContractParameters = {};

    if (tabFilters?.length > 0) toSend.status = tabFilters;

    if (advancedFilters.length > 0) {
      advancedFilters.map((x) => {
        if (x.filterType === 'search') toSend.search = x.filterData;
        if (x.filterType === 'buildingSite') toSend.buildingSiteId = x.filterData?.value;
        if (x.filterType === 'customer') toSend.customerId = x.filterData?.value;
        if (x.filterType === 'externalReference') toSend.externalReference = x.filterData;
        if (x.filterType === 'number') toSend.quotationNumber = x.filterData;
      });
    }

    return toSend;
  }

  const seeDetails = (row: GetContract) => {
    // TODO INTEGRATION : gestion popup si dans sous-fenetre ?
    const url = 'contracts';
    return `/${url}/${row.id}/update`;
  };

  const columns: any[] = [
    {
      name: t('common.number'),
      selector: (row: GetContract) => row.quotationNumber,
      cell: (row: GetContract) => (
        <Link to={seeDetails(row)}>
          <div className='column' data-tag='allowRowEvents'>
            <div data-tag='allowRowEvents'>
              <strong className='caption2medium' data-tag='allowRowEvents'>
                {row.quotationNumber}
              </strong>
            </div>
            <div data-tag='allowRowEvents'>
              <i className='caption2medium' data-tag='allowRowEvents'>
                {row.externalReference}
              </i>
            </div>
          </div>
        </Link>
      ),
      sortable: true,
      right: false,
      id: 'quotationNumber',
    },
    {
      name: t('common.contractPeriod'),
      selector: (row: GetContract) => row.dateEnd,
      cell: (row: GetContract) => (
        <div className='column' data-tag='allowRowEvents'>
          <div data-tag='allowRowEvents'>
            <div className='caption2medium' data-tag='allowRowEvents'>
              {moment(row.dateStart).utc().format('DD/MM/YYYY') +
                ' - ' +
                (row.dateEnd ? moment(row.dateEnd).utc().format('DD/MM/YYYY') : t('common.inProgress'))}
            </div>
          </div>
        </div>
      ),
      sortable: true,
      right: false,
      id: 'dateStart',
    },
    {
      name: t('common.customer'),
      selector: (row: GetContract) => row.customer?.label,
      cell: (row: GetContract) => (
        <Link to={seeDetails(row)}>
          <div className='column' data-tag='allowRowEvents'>
            <div data-tag='allowRowEvents'>
              <strong className='caption2medium' data-tag='allowRowEvents'>
                {row.customer?.label}
              </strong>
            </div>
            <div data-tag='allowRowEvents'>
              <i className='caption2medium' data-tag='allowRowEvents'>
                {row.customer?.externalReference}
              </i>
            </div>
          </div>
        </Link>
      ),
      sortable: false,
      right: false,
      id: 'customer',
    },
    {
      name: t('common.buildingSite'),
      selector: (row: GetContract) => row.buildingSite?.id,
      cell: (row: GetContract) => (
        <div className='column' data-tag='allowRowEvents'>
          <div data-tag='allowRowEvents'>
            <strong className='caption2medium' data-tag='allowRowEvents'>
              {row.buildingSite?.label}
            </strong>
          </div>
          <div data-tag='allowRowEvents'>
            <i className='caption2medium' data-tag='allowRowEvents'>
              {row.buildingSite?.externalReference}
            </i>
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'buildingSite',
    },
  ];

  // #endregion specific

  // #endregion intializing constants

  // #region event handling

  const handlePageChange = (pageNumber: number) => {
    fetchItems(pageNumber, perPage);
  };

  const handlePerRowsChange = (newPerPage: any) => {
    setPerPage(newPerPage);
    fetchItems(1, newPerPage);
  };

  const onSort = (data: any, newSortOrder: any) => {
    if (data.id == sortColumn) {
      if (sortDirection == 'asc') {
        newSortOrder = 'desc';
      } else {
        newSortOrder = 'asc';
      }
    } else {
      newSortOrder = 'asc';
    }
    fetchItems(1, perPage, data.id, newSortOrder);
  };

  function handleSetFiltersAdvanced(newFilters: AdvancedFilter[]) {
    setFiltersAdvanced(newFilters);
    fetchItems(currentPage, perPage, sortColumn, sortDirection, filtersTab, newFilters);
  }

  // #endregion event handling

  return (
    <>
      <FilterHeader
        title={''}
        allowQuickSearch={true}
        allowAdvancedSearch={true}
        advancedSearchOptions={['search', 'number', 'externalReference', 'customer', 'buildingSite']}
        showAddNew={props.forcedFilters ? true : false}
        addNewText={t('common.newContract')}
        addNew={() => {
          if (filtersAdvanced.find((x) => x.filterType === 'buildingSite')?.filterData !== undefined)
            history(
              '/contracts/add/buildingSite/' +
                filtersAdvanced.find((x) => x.filterType === 'buildingSite')?.filterData?.value,
            );
          else if (filtersAdvanced.find((x) => x.filterType === 'customer')?.filterData !== undefined)
            history(
              '/contracts/add/customer/' + filtersAdvanced.find((x) => x.filterType === 'customer')?.filterData?.value,
            );
          else history('/contracts/add');
        }}
        dropdown={false}
        forcedFilters={props.forcedFilters}
        quickSearchFilterTypes={['search']}
        advancedSearchFilterChanged={handleSetFiltersAdvanced}
      />

      {isLoading ?? false ? (
        <div>{t('common.loading')}</div>
      ) : (
        <DataTable
          className='table'
          noHeader={true}
          customStyles={TableStyles}
          columns={columns}
          onRowClicked={(row) => history(seeDetails(row))}
          highlightOnHover
          pointerOnHover
          data={filteredItems}
          noDataComponent={<div className='noResults'>{t('common.noResult')}</div>}
          defaultSortAsc={sortDirection == 'asc'}
          defaultSortFieldId={sortColumn}
          onSort={(data, newSortDirection) => onSort(data, newSortDirection)}
          sortServer
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
          conditionalRowStyles={[
            {
              when: (row: GetContract) =>
                row.dateStart !== undefined &&
                moment(row.dateStart).toDate() < new Date() &&
                (!row.dateEnd || moment(row.dateEnd).toDate() > new Date()),
              style: { backgroundColor: '#DDEBFF' },
            },
          ]}
          dense={true}
          paginationComponentOptions={{
            rowsPerPageText: t('common.perPage'),
            rangeSeparatorText: t('common.onPage'),
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: 'All',
          }}
        />
      )}
    </>
  );
});

ListContracts.displayName = 'ListContracts';
export default ListContracts;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CardDetail } from '../../../stories/dune/atoms/CardDetail';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { Input } from '../../../stories/dune/atoms/Input';
import { CustomerSave, GetCustomer, GetCustomerUnpaidAmount } from '../../../models/Customer';
import { Title } from '../../../stories/dune/atoms/Title';
import BuildDataCustom, { DataCustomItem } from '../../forms/BuildDataCustom';
import { SelectCountry, SelectCountryOption } from '../../forms/SelectCountry';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { FixBar } from '../../../stories/dune/molecules/FixBar';
import { Button } from '../../../stories/dune/atoms/Button';
import useToast from '../../../hooks/use-toast';
import axios from 'axios';
import { ToastTypes } from '../../../models/ToastTypes';
import Config from '../../../Config';
import countryList from '../../Countries';
import Switch from 'react-switch';
import { ContactSave } from '../../../models/Contact';
import { ContactEntity } from '@dune-manager/backend-core/dist/models/contact';
import isEmail from 'validator/lib/isEmail';
import stringUtils from '../../../utils/stringUtils';
import { ManageDocument } from '../../forms/ManageDocument';
import moment from 'moment';
import { SelectCustomerType, SelectCustomerTypeOption } from '../../forms/SelectCustomerType';

interface GeneralCustomerProps {
  id?: string;
  customerData: GetCustomer | undefined;

  onCancel: () => void;
  onConfirm: (data: any) => void;

  mode: string;
}

interface IFormInputs {
  label: string;
  externalReference?: string;
  country?: {
    value: string;
    label: string;
  };
  postalCode?: string;
  locality?: string;
  streetAddress: string;
  tvaNumber?: string;
  sendTripToSxd?: boolean;
  customerType: {
    label: string;
    value: string;
  };
  allBuildingSites?: boolean;
  isCashCustomer?: boolean;
  maxUnpaidAmount?: number;
  supplier?: boolean;
  firstname?: string;
  lastname?: string;
  telephone?: string;
  email?: string;
  repExemptionExpiration?: Date;
}

const GeneralCustomer = React.memo((props: GeneralCustomerProps) => {
  const { t } = useTranslation();
  const history = useNavigate();
  const { addToast } = useToast();

  const [isAddingLoading, setIsAddingLoading] = useState(false);

  const [saveCustomer, setSaveCustomer] = useState<CustomerSave>();

  const [isActive, setIsActive] = useState<boolean>(true);

  const [label, setLabel] = useState<string>();
  const [externalReference, setExternalReference] = useState<string>();
  const [tvaNumber, setTvaNumber] = useState<string>();

  const [streetAddress, setStreetAddress] = useState<string>();
  const [locality, setLocality] = useState<string>();
  const [postalCode, setPostalCode] = useState<string>();
  const [countryOption, setCountryOption] = useState<SelectCountryOption | null>(
    props.customerData?.address?.country
      ? {
          value: props.customerData.address.country,
          label: countryList[props.customerData.address.country],
          data: { value: props.customerData.address.country, label: countryList[props.customerData.address.country] },
        }
      : {
          value: 'FR',
          label: countryList['FR'],
          data: { value: 'FR', label: countryList['FR'] },
        },
  );

  const [selectedOptionCustomerType, setSelectedOptionCustomerType] = useState<SelectCustomerTypeOption | null>(
    props.customerData?.customerType
      ? {
          value: props.customerData.customerType.id,
          label: props.customerData.customerType.label,
          data: {
            value: props.customerData.customerType.id,
            label: props.customerData.customerType.label,
            data: props.customerData.customerType,
          },
        }
      : null,
  );
  const [allBuildingSites, setAllBuildingSites] = useState<boolean>(false);
  const [isCashCustomer, setIsCashCustomer] = useState<boolean>(false);

  const [maxUnpaidAmount, setMaxUnpaidAmount] = useState<number>();
  const [unpaidAmount, setUnpaidAmount] = useState<number>();

  const [isExemptFromRepTax, setIsExemptFromRepTax] = useState<boolean>(false);
  const [repExemptionExpiration, setRepExemptionExpiration] = useState<Date>();

  const [firstname, setFirstname] = useState<string>();
  const [lastname, setLastname] = useState<string>();
  const [telephone, setTelephone] = useState<string>();
  const [email, setEmail] = useState<string>();

  const [customerDataCustom, setCustomerDataCutom] = useState<DataCustomItem>({});

  const schema = yup.object().shape({
    label: yup.string().required('Le nom est obligatoire'),
    streetAddress: yup.string().required("L'adresse est obligatoire"),
    firstname: yup.string().when(['lastname', 'telephone', 'email'], {
      is: (lastname: string, telephone: string, email: string) =>
        lastname?.length > 0 || telephone?.length > 0 || email?.length > 0,
      then: yup.string().required('Le prénom doit également être rempli'),
      otherwise: yup.string().notRequired(),
    }),
    email: yup.string().test('email-validator', 'Doit être une adresse email valide', (value) => {
      return (value ?? '').length === 0 || isEmail(value ?? '');
    }),
  }); // on utilise un validateur de 'validator.js' pour correspondre au back

  const {
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (props.customerData) {
      // on initialise SaveCustomer pour ne pas perdre les données non-gérées lors de la sauvegarde
      setSaveCustomer({
        ...props.customerData,
      });

      setIsActive(props.customerData?.isActive ?? true);

      setLabel(props.customerData?.label);
      setValue('label', props.customerData?.label ?? '');
      setExternalReference(props.customerData?.externalReference);
      setTvaNumber(props.customerData?.tvaNumber);

      setStreetAddress(props.customerData?.address?.streetAddress);
      setValue('streetAddress', props.customerData?.address?.streetAddress ?? '');
      setLocality(props.customerData?.address?.locality);
      setPostalCode(props.customerData?.address?.postalCode);
      if (props.customerData?.address?.country)
        setCountryOption({
          value: props.customerData?.address?.country,
          label: countryList[props.customerData?.address?.country],
        });

      if (props.customerData?.customerType)
        setSelectedOptionCustomerType({
          value: props.customerData?.customerType.id,
          label: props.customerData?.customerType.label,
        });
      setAllBuildingSites(props.customerData?.allBuildingSites ?? false);
      setIsCashCustomer(props.customerData?.isCashCustomer ?? false);

      setMaxUnpaidAmount(props.customerData?.maxUnpaidAmount);

      setIsExemptFromRepTax(props.customerData?.isExemptFromRepTax ?? false);
      setRepExemptionExpiration(props.customerData?.repExemptionExpiration);

      setCustomerDataCutom(props.customerData?.dataCustom as any);

      loadCustomerCurrentUnpaidAmount();
    }
  }, [props.customerData]);

  function onSubmit() {
    setIsAddingLoading(true);

    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    let baseUrl = orgid + '/customer/add';
    if (props.mode == 'update') baseUrl = orgid + '/customer/edit/' + props.id;

    if (saveCustomer) {
      const dataToSend: CustomerSave = saveCustomer;

      dataToSend.label = label;
      dataToSend.allBuildingSites = allBuildingSites;
      dataToSend.isCashCustomer = isCashCustomer;
      dataToSend.maxUnpaidAmount = maxUnpaidAmount;
      dataToSend.externalReference = externalReference;
      dataToSend.tvaNumber = tvaNumber;
      dataToSend.locality = locality;
      dataToSend.postalCode = postalCode;
      dataToSend.streetAddress = streetAddress;
      dataToSend.isExemptFromRepTax = isExemptFromRepTax;
      dataToSend.repExemptionExpiration = repExemptionExpiration;
      dataToSend.isActive = isActive;

      axios
        .post(Config.getApiExtranetUrl(baseUrl), stringUtils.formatFieldsForPost(dataToSend), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (props.mode === 'add') {
            // si add, on ajoute le contact
            addContact(res.data.content.uuid);
          }
          setIsAddingLoading(false);
          addToast(t('common.customer' + (props.mode === 'update' ? 'Updated' : 'Created')), ToastTypes.success);
          props.onConfirm(res.data.content.uuid);
        })
        .catch((error) => {
          setIsAddingLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        });
    } else {
      setIsAddingLoading(false);
    }
  }

  function loadCustomerCurrentUnpaidAmount() {
    if (props.mode !== 'update') return;

    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    const baseUrl = orgid + '/customer/unpaid-amount/' + props.id;

    axios
      .get(Config.getApiExtranetUrl(baseUrl), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const unpaid: GetCustomerUnpaidAmount = res.data.content;
        setUnpaidAmount(unpaid.unpaidAmountVat ?? 0);
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  }

  function addContact(entityId: string) {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    const baseUrl = orgid + '/contact/add';

    if (firstname || lastname || telephone || email) {
      const dataToSend: ContactSave = {
        firstname: firstname ?? email ?? lastname ?? telephone ?? '',
        lastname: lastname,
        entityId: entityId,
        contactEntity: ContactEntity.CUSTOMER,
        telephone: telephone,
        email: email,
      };

      axios
        .post(Config.getApiExtranetUrl(baseUrl), stringUtils.formatFieldsForPost(dataToSend), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          addToast(t('common.contactAdded'), ToastTypes.success);
          return res.data.content.uuid;
        })
        .catch((error) => {
          setIsAddingLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        });
    }
  }

  function handleRepDocumentUpdated() {
    if (!repExemptionExpiration) {
      const newDate = new Date();
      newDate.setFullYear(newDate.getFullYear() + 1);
      setRepExemptionExpiration(newDate);
    }
  }

  function roundNumber(num: number, decimalPlaces: number): number {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(num * factor) / factor;
  }

  return !props.customerData && props.mode === 'update' ? (
    <div>Loading...</div>
  ) : (
    <form id='form' onSubmit={handleSubmit(onSubmit)}>
      <CardDetail>
        <div className='form-section'>
          <FormRow>
            <Title label={t('common.generalInformations')} type='title1' />

            {props.mode === 'update' && (
              <FormRow align='right' alignVert='center'>
                <Switch
                  onChange={function () {
                    setIsActive((prevValue) => !prevValue);
                  }}
                  checked={isActive ?? false}
                  onColor={'#2a85ff'}
                />
                <h3 className='base2'>{t('common.active')}</h3>
              </FormRow>
            )}
          </FormRow>
          <FormRow>
            <Input
              {...register('label')}
              error={errors.label?.message ?? ''}
              label={t('common.companyName')}
              type='text'
              value={label ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setValue('label', e.toString());
                setLabel(e.toString());
              }}
            />
            <Input
              {...register('externalReference')}
              error={errors.externalReference?.message ?? ''}
              label={t('common.yourReference')}
              type='text'
              value={externalReference ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setExternalReference(e.toString());
              }}
            />
            <Input
              {...register('tvaNumber')}
              error={errors.tvaNumber?.message ?? ''}
              label={t('common.tvaNumber')}
              type='text'
              value={tvaNumber ?? ''}
              placeholder='FRXX999999999'
              disabled={false}
              onChange={function (e): void {
                setTvaNumber(e.toString());
              }}
            />
          </FormRow>
          <FormRow>
            <Input
              {...register('streetAddress')}
              error={errors.streetAddress?.message ?? ''}
              label={t('common.address')}
              type='text'
              value={streetAddress ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setValue('streetAddress', e.toString());
                setStreetAddress(e.toString());
              }}
            />
          </FormRow>
          <FormRow>
            <Input
              {...register('locality')}
              error={errors.locality?.message ?? ''}
              label={t('common.locality')}
              type='text'
              value={locality ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setLocality(e.toString());
              }}
            />
            <Input
              {...register('postalCode')}
              error={errors.postalCode?.message ?? ''}
              label={t('common.postalCode')}
              type='text'
              value={postalCode ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setPostalCode(e.toString());
              }}
            />
            <SelectCountry
              register={register}
              registerName='country'
              setValue={setValue}
              error={errors.country?.value?.message ?? errors.country?.label?.message ?? ''}
              isSelectable={true}
              selectedOptionChanged={(e: SelectCountryOption) => {
                setSaveCustomer((prevValue) => {
                  return { ...prevValue, country: e?.value };
                });
              }}
              forceSelectedOption={countryOption}
              titleSize='normal'
            />
          </FormRow>

          <FormRow alignVert='center'>
            <SelectCustomerType
              titleSize='normal'
              titleOverride={t('common.customerType')}
              register={register}
              registerName='customerType'
              setValue={setValue}
              error={errors.customerType?.value?.message ?? errors.customerType?.label?.message ?? ''}
              isSelectable={true}
              selectedOptionChanged={(e: SelectCustomerTypeOption) => {
                if (e && e.value) {
                  setValue('customerType', {
                    value: e?.value,
                    label: e?.label,
                  });

                  setSaveCustomer((prevValue) => {
                    return { ...prevValue, customerTypeId: e?.value };
                  });
                } else {
                  setValue('customerType', {
                    value: '',
                    label: '',
                  });

                  setSaveCustomer((prevValue) => {
                    return { ...prevValue, customerTypeId: '' };
                  });
                }
              }}
              forceSelectedOption={selectedOptionCustomerType}
            />
            {/* // TODO DESIGN : champ 'checkbox' générique pour formulaire ? */}
            <div className='panelInput' title={t('common.isCashCustomerDesc')}>
              <div>
                <Title type={'label'} align='left' label={t('common.isCashCustomer')} />
                <Switch
                  className='base2'
                  type='text'
                  checked={isCashCustomer}
                  onChange={(checked: boolean) => setIsCashCustomer(checked)}
                  onColor={'#2a85ff'}
                />
              </div>
            </div>
            {/* // TODO DESIGN : champ 'checkbox' générique pour formulaire ? */}
            <div className='panelInput' title={t('common.allBuildingSitesDesc')}>
              <div>
                <Title type={'label'} align='left' label={t('common.allBuildingSites')} />
                <Switch
                  className='base2'
                  type='text'
                  checked={allBuildingSites}
                  onChange={(checked: boolean) => setAllBuildingSites(checked)}
                  onColor={'#2a85ff'}
                />
              </div>
            </div>
          </FormRow>
        </div>

        <div className='form-section'>
          <FormRow>
            <Title label={t('common.embankment')} type='title1' />
          </FormRow>

          <FormRow>
            <Input
              {...register('maxUnpaidAmount')}
              error={errors.maxUnpaidAmount?.message ?? ''}
              label={t('common.maxUnpaidAmount')}
              hoverDescription={t('common.maxUnpaidAmountDesc')}
              type='number'
              value={roundNumber(maxUnpaidAmount ?? 0, 2)}
              placeholder=''
              disabled={false}
              onChange={function (newValue: string | number): void {
                setValue('maxUnpaidAmount', roundNumber((newValue as number) ?? 0, 2));
                setMaxUnpaidAmount(roundNumber((newValue as number) ?? 0, 2));
              }}
              suffix={'€'}
              number={{ min: 0, max: 10000000, step: 10 }}
            />
            <Input
              error={''}
              label={t('common.unpaidAmount')}
              hoverDescription={t('common.unpaidAmountDesc')}
              type={'number'}
              value={unpaidAmount ? roundNumber(unpaidAmount ?? 0, 2) : ''}
              placeholder=''
              disabled={true}
              onChange={() => {
                return undefined;
              }}
              suffix={'€'}
              number={{ min: 0, max: 10000000, step: 10 }}
            />
          </FormRow>
        </div>
        <div className='form-section'>
          <FormRow>
            <Title label={t('common.repTax')} type='title1' />
          </FormRow>
          <FormRow>
            {/* // TODO DESIGN : champ 'checkbox' générique pour formulaire ? */}
            <div className='panelInput' title={t('common.customerIsExemptFromRepTaxDesc')}>
              <Switch
                className='base2'
                type='text'
                checked={isExemptFromRepTax}
                onChange={(checked: boolean) => setIsExemptFromRepTax(checked)}
                onColor={'#2a85ff'}
              />
              <h3 className='base2' style={{ margin: '0px 10px' }}>
                {t('common.isExemptFromRepTax')}
              </h3>
            </div>
            {isExemptFromRepTax && (
              <div
                title={t('common.clickHereForRepExemptionTemplateDesc')}
                style={{
                  margin: '10px 20px',
                }}
              >
                <a
                  href={'http://download.synaxe.com/tmp/modele%2Dattestation%2Dexoneration%2Dclient%2Epdf'}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  {t('common.clickHereForRepExemptionTemplate')}
                </a>
              </div>
            )}
          </FormRow>
          {isExemptFromRepTax && (
            <FormRow alignVert='center'>
              {props.mode !== 'add' && (
                // hide document management when adding because we don't have the entity id yet
                <ManageDocument
                  label={t('common.exemptionDocument')}
                  hoverDescription={t('common.exemptionDocumentDesc')}
                  docType={'RepCustomerExemption'}
                  entityType='customer'
                  entityId={props.id ?? ''}
                  onAddDocument={handleRepDocumentUpdated}
                />
              )}
              <Input
                {...register('repExemptionExpiration')}
                error={errors.repExemptionExpiration?.message ?? ''}
                label={t('common.repExemptionExpiration')}
                hoverDescription={t('common.repExemptionExpirationDesc')}
                type='date'
                size='fit'
                value={moment(repExemptionExpiration)?.toISOString()?.substring(0, 10) ?? ''}
                placeholder={new Date().toISOString().split('T')[0]}
                disabled={false}
                onChange={function (e): void {
                  setRepExemptionExpiration(new Date(e.toString()));
                }}
              />
            </FormRow>
          )}
        </div>
        {props.mode === 'add' && (
          <div className='form-section'>
            <FormRow>
              <Title label={t('common.contact')} type='title1' />
            </FormRow>
            <FormRow>
              <Input
                {...register('lastname')}
                error={errors?.lastname?.message ?? ''}
                label={t('common.lastname')}
                type='text'
                value={lastname ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setValue('lastname', e.toString());
                  setLastname(e.toString());
                }}
              />
              <Input
                {...register('firstname')}
                error={errors?.firstname?.message ?? ''}
                label={t('common.firstname')}
                type='text'
                value={firstname ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setValue('firstname', e.toString());
                  setFirstname(e.toString());
                }}
              />
            </FormRow>
            <FormRow>
              <Input
                {...register('telephone')}
                error={errors?.telephone?.message ?? ''}
                label={t('common.telephone')}
                type='text'
                value={telephone ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setValue('telephone', e.toString());
                  setTelephone(e.toString());
                }}
              />
              <Input
                {...register('email')}
                error={errors?.email?.message ?? ''}
                label={t('common.email')}
                type='email'
                value={email ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (e): void {
                  setValue('email', e.toString());
                  setEmail(e.toString());
                }}
              />
            </FormRow>
          </div>
        )}
        <div className='form-section'>
          <BuildDataCustom
            dataCustomType={'Customer'}
            dataCustom={customerDataCustom}
            dataCustomUpdated={(data: DataCustomItem) => {
              setSaveCustomer((prevValue) => {
                return { ...prevValue, dataCustom: data };
              });
            }}
          />
        </div>
        <FixBar>
          <Button label={t('common.return')} style='white' onClick={props.onCancel} />
          <Button iconLeft='save' label={t('common.save')} style='primary' onClick={handleSubmit(onSubmit)} />
        </FixBar>
      </CardDetail>
    </form>
  );
});

GeneralCustomer.displayName = 'GeneralCustomer';
export default GeneralCustomer;

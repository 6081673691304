import React, { ReactElement, useEffect, useState } from 'react';
import { Language } from '../../i18n';
import Switch from 'react-switch';
import { FormRow } from '../../stories/dune/atoms/FormRow';
import { Input } from '../../stories/dune/atoms/Input';

interface BuildDataCustomProps {
  dataCustomType: 'Trip' | 'Customer' | 'Vehicle' | 'Product' | 'Carrier' | 'BuildingSite' | 'Order';
  dataCustom: DataCustomItem;
  dataCustomUpdated: (data: DataCustomItem) => void;
}

export interface DataCustomItem {
  [key: string]: any;
}

const BuildDataCustom = (props: BuildDataCustomProps) => {
  const currentLanguage = localStorage.getItem('language') ?? Language.FR;

  const [currentDataCustom, setCurrentDataCustom] = useState<DataCustomItem>(props.dataCustom ?? {});
  const [paramDataCustom, setParamDataCustom] = useState<any>('');

  const containers: ReactElement[] = [];
  let containerRows: ReactElement[] = [];

  const switchDataCustom = (checked: boolean, event: any, id: string) => {
    updateCustomData(id, checked);
  };

  const updateRadioDataCustom = (event: any) => {
    updateCustomData(event?.target.name.toString(), event?.target.value);
  };

  const updateCustomData = (key: string, value: any) => {
    if (key) {
      const newCustomData = { ...currentDataCustom };
      newCustomData[key] = value;
      setCurrentDataCustom(newCustomData);
      props.dataCustomUpdated(newCustomData);
    }
  };

  useEffect(() => {
    setCurrentDataCustom(props.dataCustom);
  }, [props.dataCustom]);

  useEffect(() => {
    setParamDataCustom(localStorage.getItem('param' + props.dataCustomType + 'DataCustom') as string);
  }, [props.dataCustomType]);

  if ((paramDataCustom ?? null) !== null && paramDataCustom !== 'null' && paramDataCustom.length > 0) {
    const oParamDataCustom = JSON.parse(paramDataCustom as string);
    for (let index = 0; index < oParamDataCustom.length; index++) {
      const typeDataCustom = oParamDataCustom[index];
      containers.push(
        (
          <div className='section-title' key={typeDataCustom.label[currentLanguage]}>
            <h3 className='title1SemiBold'>{typeDataCustom.label[currentLanguage]}</h3>
          </div>
        ) as ReactElement,
      );

      for (let indexFields = 0; indexFields < typeDataCustom.fields.length; indexFields++) {
        const fieldsDataCustom = typeDataCustom.fields[indexFields];

        switch (fieldsDataCustom.type) {
          case 'text':
            containerRows.push(
              (
                <>
                  <Input
                    key={fieldsDataCustom.code}
                    error={''}
                    label={fieldsDataCustom.label[currentLanguage]}
                    hoverDescription={fieldsDataCustom.description[currentLanguage]}
                    type='text'
                    value={
                      fieldsDataCustom.code && currentDataCustom
                        ? currentDataCustom[fieldsDataCustom.code] ?? undefined
                        : undefined
                    }
                    placeholder=''
                    disabled={false}
                    onChange={(e) => updateCustomData(fieldsDataCustom.code ?? 'id', e?.toString())}
                  />
                </>
              ) as ReactElement,
            );

            break;
          case 'number':
            containerRows.push(
              (
                <div
                  className='detail-items-row'
                  title={fieldsDataCustom.description[currentLanguage]}
                  key={fieldsDataCustom.code}
                >
                  <div className='panelTitleText'>
                    <h3 className='base2'>{fieldsDataCustom.label[currentLanguage]}</h3>
                  </div>
                  <div className='panelInput'>
                    <div className='input-prefix'>
                      <h3 className='base2'>T</h3>
                    </div>
                    <input
                      className='base2'
                      id={fieldsDataCustom.code ?? null}
                      type='number'
                      min='0'
                      max='1000000'
                      value={
                        fieldsDataCustom.code && currentDataCustom
                          ? currentDataCustom[fieldsDataCustom.code] ?? undefined
                          : undefined
                      }
                      onChange={(e) => updateCustomData(e?.target.id.toString(), parseFloat(e?.target.value))}
                    />
                  </div>
                </div>
              ) as ReactElement,
            );
            break;
          case 'checkbox':
            containerRows.push(
              (
                <div
                  className='detail-items-row'
                  title={fieldsDataCustom.description[currentLanguage]}
                  key={fieldsDataCustom.code}
                >
                  <div className='panelInput'>
                    <h3 className='base2' style={{ margin: '0px 10px' }}>
                      {fieldsDataCustom.label[currentLanguage]}
                    </h3>
                    <Switch
                      id={fieldsDataCustom.code ?? 'id'}
                      className='base2'
                      type='text'
                      checked={
                        fieldsDataCustom.code && currentDataCustom
                          ? currentDataCustom[fieldsDataCustom.code] ?? false
                          : false
                      }
                      onChange={switchDataCustom}
                      onColor={'#2a85ff'}
                    />
                  </div>
                </div>
              ) as ReactElement,
            );
            break;
          case 'radio':
            containerRows.push(
              (
                <div
                  className='detail-items-row'
                  title={fieldsDataCustom.description[currentLanguage]}
                  key={fieldsDataCustom.code}
                >
                  <div className='panelTitleText'>
                    <h3 className='base2'>{fieldsDataCustom.label[currentLanguage]}</h3>
                  </div>
                  <div className='radioPanel' id={fieldsDataCustom.code ?? 'id'} onChange={updateRadioDataCustom}>
                    {fieldsDataCustom.options
                      ? fieldsDataCustom.options.map((option: any, index: number) => (
                          <div key={index} className='base2'>
                            <input
                              type='radio'
                              id={option.value}
                              name={fieldsDataCustom.code ?? 'radio'}
                              value={option.value}
                              checked={
                                ((currentDataCustom ?? null) !== null &&
                                  currentDataCustom[fieldsDataCustom.code] === option.value) ??
                                false
                              }
                            />
                            {option.label[currentLanguage]}
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              ) as ReactElement,
            );
            break;
          default:
            break;
        }

        if ((indexFields + 1) % 3 === 0) {
          containers.push((<FormRow key={'row' + index + '_' + indexFields}>{containerRows}</FormRow>) as ReactElement);
          containerRows = [];
        }
      }
      containers.push((<FormRow key={'row' + index.toString()}>{containerRows}</FormRow>) as ReactElement);
      containerRows = [];
      containers.push((<hr key={'div' + index.toString()} className='divider' />) as ReactElement);
    }
  }

  return <>{containers}</>;
};

export default BuildDataCustom;

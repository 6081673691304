import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './DetailVehicle.scss';
import Menu from '../../Menu';
import Config from '../../../Config';
import { ToastTypes } from '../../../models/ToastTypes';
import { useTranslation } from 'react-i18next';
import { SubMenuVehicle } from './SubMenuVehicle';
import ListContacts from '../../lists/Contacts/ListContacts';
import { ContactEntity } from '@dune-manager/backend-core/dist/models/contact';
import ListTrips from '../../lists/Trips/ListTrips';
import ListOrders from '../../lists/Orders/ListOrders';
import ListAdditionalDocs from '../../lists/AdditionalDocs/ListAdditionalDocs';
import GeneralVehicle from './GeneralVehicle';
import { Head } from '../../../stories/dune/molecules/Head';
import { GetVehicle, GetVehicleInfo } from '../../../models/Vehicle';
import useToast from '../../../hooks/use-toast';
import PopupContainer from '../../../stories/dune/organisms/PopupContainer';

interface DetailVehicleProps {
  id?: string;
  isPopup?: boolean;
  onClosePopup?: (createdId: string | undefined) => void;
  mode?: string;
}

const DetailVehicle = React.memo((props: DetailVehicleProps) => {
  // #region generic

  const { t } = useTranslation();
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState('general');

  const history = useNavigate();

  type Params = {
    id: string;
    mode: string;
    autosign?: string;
  };

  const { id, mode } = useParams<Params>();

  function updateCurrentTab(mode: string) {
    setCurrentTab(mode);
  }

  function getMode() {
    return props.mode ?? mode ?? '';
  }

  // #endregion generic

  // #region specific
  const [getVehicle, setGetVehicle] = useState<GetVehicle>();
  const [getVehicleInfo, setGetVehicleInfo] = useState<GetVehicleInfo>();

  function getCurrentVehicle(id: string | undefined) {
    if (id && getMode() === 'update') {
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');
      const baseUrl = orgid + '/vehicle/detail/';
      const url = Config.getApiExtranetUrl(baseUrl + id);
      setIsLoading(true);
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if ((res.data.content ?? null) !== null) {
            const baseUrl2 = orgid + '/vehicle-info/get-by-registration-plate/';
            const url2 = Config.getApiExtranetUrl(baseUrl2 + res.data.content.registrationPlate);
            setIsLoading(true);
            axios
              .get(url2, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((res2) => {
                if ((res2.data.content ?? null) !== null) {
                  // on enregistre GetVehicle
                  setGetVehicle(res.data.content);
                  // on enregistre GetVehicleInfo
                  setGetVehicleInfo(res2.data.content.items);
                  setIsLoading(false);
                }
              })
              .catch((error) => {
                setIsLoading(false);
                if (error.response) {
                  if (
                    error.response.data.code == 'ERR4010001' ||
                    error.response.data.code == 'ERR4031001' ||
                    error.response.data.code == 'ERR4010000'
                  ) {
                    history('/');
                  }
                }
                if ((id ?? null) !== null) {
                  addToast(
                    (error?.response?.data?.code
                      ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
                      : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
                    ToastTypes.error,
                  );
                }
              });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          if ((id ?? null) !== null) {
            addToast(
              (error?.response?.data?.code
                ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
                : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
              ToastTypes.error,
            );
          }
        });
    } else setIsLoading(false);
  }

  useEffect(() => {
    getCurrentVehicle(id);
  }, [id, getMode()]);

  // #endregion specific

  const content = (
    <>
      {!isLoading && (
        <>
          {getMode() === 'add' && (
            <GeneralVehicle
              mode={getMode()}
              vehicleData={getVehicle}
              vehicleInfoData={getVehicleInfo}
              onCancel={onCanceled}
              onConfirm={onConfirmed}
            />
          )}

          {getMode() !== 'add' && (
            <>
              <SubMenuVehicle tab={currentTab} updateTab={updateCurrentTab} />
              {currentTab === 'general' && (
                <GeneralVehicle
                  id={id}
                  mode={getMode()}
                  vehicleData={getVehicle}
                  vehicleInfoData={getVehicleInfo}
                  onCancel={onCanceled}
                  onConfirm={onConfirmed}
                />
              )}
              {currentTab === 'contact' && id && (
                <ListContacts
                  contactEntity={ContactEntity.VEHICLE}
                  entityId={id}
                  canAdd={true}
                  canEdit={true}
                  canRemove={true}
                  canSelect={false}
                />
              )}
              {currentTab === 'trip' && id && (
                <ListTrips
                  canAddNew={false}
                  forcedFilters={[
                    {
                      filterType: 'registrationPlate',
                      filterData: getVehicle?.registrationPlate,
                    },
                  ]}
                />
              )}
              {currentTab === 'order' && id && (
                <ListOrders
                  canAddNew={false}
                  forcedFilters={[
                    {
                      filterType: 'registrationPlate',
                      filterData: getVehicle?.registrationPlate,
                    },
                  ]}
                />
              )}
              {currentTab === 'documents' && getVehicle && (
                <ListAdditionalDocs entityType='vehicle' entityId={getVehicle.id ?? ''} canAddNew={true} />
              )}
            </>
          )}
        </>
      )}
    </>
  );

  function onConfirmed(data: any) {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(data);
    } else {
      history('/vehicles');
    }
  }
  function onCanceled() {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(undefined);
    } else {
      history('/vehicles');
    }
  }

  return (
    <>
      {props.isPopup ? (
        <PopupContainer
          hideBottomBar={true}
          isLarge={true}
          title={
            getMode() === 'add'
              ? t('common.newVehicle')
              : t('common.vehicle') +
                ' ' +
                (!getVehicle
                  ? ''
                  : getVehicle.registrationPlate + (getVehicle.trailerPlate ? '-' + getVehicle.trailerPlate : ''))
          }
        >
          {content}
        </PopupContainer>
      ) : (
        <div className='main-container'>
          <Menu selectedPage='vehicles'></Menu> {/* TODO : sortir et mettre dans classe 'parent'*/}
          <div className='right detail-container'>
            <Head
              title={
                getMode() === 'add'
                  ? t('common.newVehicle')
                  : t('common.vehicle') +
                    ' ' +
                    (!getVehicle
                      ? ''
                      : getVehicle.registrationPlate + (getVehicle.trailerPlate ? '-' + getVehicle.trailerPlate : ''))
              }
              back={true}
              OnNotificationClick={function (): void {
                throw new Error('Function not implemented.');
              }}
              OnAutorisationClick={function (): void {
                throw new Error('Function not implemented.');
              }}
              Back={function (): void {
                history('/vehicles');
              }}
            />
            {content}
          </div>
        </div>
      )}
    </>
  );
});

DetailVehicle.displayName = 'DetailVehicle';
export default DetailVehicle;

import { GetCarrier } from './Carrier';
import BuildingSite from './BuildingSite';
import { VatRate } from './VatRate';
import { GetAdditionalDoc } from './AdditionalDoc';
import Customer from './Customer';
import Contact from './Contact';
import { ProductUnit } from './Product';
import { ProductType } from './ProductType';

export enum QuotationStatus {
  CREATED = 'Created',
  UPDATED = 'Updated',
  SENT = 'Sent',
  GENERATED = 'Generated',
  IN_ERROR = 'InError',
  REFUSED = 'Refused',
  VALIDATED = 'Validated',
  EXPIRED = 'Expired',
}

export class QuotationProgressStatus {
  static IN_PROGRESS = [
    QuotationStatus.CREATED,
    QuotationStatus.UPDATED,
    QuotationStatus.SENT,
    QuotationStatus.GENERATED,
  ];
  static OVER = [QuotationStatus.IN_ERROR, QuotationStatus.REFUSED, QuotationStatus.VALIDATED, QuotationStatus.EXPIRED];
}

export interface QuotationTransportPriceSave {
  vatRateId: string;
  price: number;
  buildingSiteId: string;
}

export interface QuotationProductSave {
  productId: string;
  vatRateId: string;
  quotedQuantity: number;
  genericPrice: number;
  repValue?: number;
  tgapValue?: number;
  mainProduct: boolean;
  externalReference?: string;
  comment?: string;
  quotedPrice?: number;
  discount?: number;
  transportMode?: string;
  transportPrices?: Array<QuotationTransportPriceSave>;
}

export interface QuotationSave {
  status: QuotationStatus;
  quotationType: 'Quotation' | 'Contract';
  products: QuotationProductSave[];
  customerId: string;
  dateStart: Date;
  dateEnd?: Date;
  buildingSiteId?: string;
  externalReference?: string;
  quotationComment?: string;
  quotationSubject?: string;
  customerContactId?: string;
  buildingSiteContactId?: string;
  carriersId?: string[];
  dataCustom?: any;
  expiredAt?: Date;
}

export interface GetQuotationParameters {
  search?: string;
  status?: string[];
  buildingSiteId?: string;
  customerId?: string;
  externalReference?: string;
  quotationNumber?: string;
}

export interface GetQuotation {
  id: string;
  quotationType?: 'Quotation' | 'Contract';
  externalReference: string;
  buildingSiteId: string;
  buildingSite: BuildingSite;
  customerId: string;
  customer: Customer;
  carriers: GetCarrier[];
  status: QuotationStatus;
  isActive: boolean;
  lastModificationAt: Date;
  lastModificationBy?: string;
  createdAt: Date;
  createdBy?: string;
  dateStart?: Date;
  dateEnd?: Date;
  expiredAt?: Date;
  quotationNumber: string;
  products: [GetQuotationProductProduct];
  quotationComment?: string;
  quotationSubject?: string;
  buildingSiteContact?: Contact;
  customerContact?: Contact;
  additionalDocs?: [GetAdditionalDoc];
  dataCustom?: string;
}

export interface GetQuotationProductProduct {
  id: string;
  isActive: boolean;
  createdAt: Date;
  lastModificationAt: Date;
  createdBy: string;
  lastModificationBy: string;
  label: string;
  dataCustom?: string;
  unit?: ProductUnit;
  weightByUnit?: number;
  taricCode?: string;
  externalReference?: string;
  multiProductPriority: boolean;
  lastImportAt?: Date;
  productTypeId: string;
  productType: ProductType;
  siteId?: string;
  QuotationProduct: GetQuotationProduct;
}

interface GetQuotationProduct {
  quotedQuantity?: number;
  genericPrice?: number;
  quotedPrice?: number;
  discount?: number;
  quotationId: string;
  productId: string;
  externalReference?: string;
  mainProduct: boolean;
  comment?: string;
  vatRateId: string;
  vatRate: VatRate;
  transportMode?: string;
  quotationProductTransportPrices: GetQuotationProductTransportPrice[];
}

interface GetQuotationProductTransportPrice {
  price?: number;
  buildingSiteId?: string;
  buildingSite?: BuildingSite;
  vatRateId: string;
  vatRate: VatRate;
  quotationProductId?: string;
}

export interface GetQuotationHistory {
  id: string;
  createdAt: Date;
  createdBy?: string;
  comment?: string;
  quotationId: string;
  status: QuotationStatus;
}

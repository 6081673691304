import React, { useEffect } from 'react';
import eventBus from '../utils/EventBus';
import pusherJs, { Channel } from 'pusher-js';

const EventListener = React.memo(() => {
  useEffect(() => {
    eventBus.on('updateWorkspace', (data: any) => {
      subscribe(data.workspaceId);
    });

    const orgid = localStorage.getItem('orgid');
    subscribe(orgid);
  }, []);

  const subscribe = (uuid: string | null | undefined) => {
    if ((uuid ?? null) !== null) {
      const pusher = new pusherJs(process.env.REACT_APP_PUSHER_CHANNEL_KEY ?? '', {
        cluster: 'eu',
      });

      pusher?.allChannels().forEach((channel: Channel) => {
        pusher.unsubscribe(channel.name);
      });

      const channel1 = pusher?.subscribe(uuid as string);

      channel1?.bind('new_alert', function (data: any) {
        eventBus.dispatch('new_alert', data);
      });

      channel1?.bind('trip_has_been_updated_extranet', function (data: any) {
        eventBus.dispatch('update_trip', data);
      });

      channel1?.bind('trip_has_been_added_extranet', function (data: any) {
        eventBus.dispatch('new_trip', data);
      });

      channel1?.bind('trip_has_been_updated', function (data: any) {
        eventBus.dispatch('update_trip_with_uuid', data);
      });
      channel1?.bind('terminal_has_been_send_equipment_data_extranet', function (data: any) {
        eventBus.dispatch('terminal_has_been_updated', data);
      });
      channel1?.bind('terminal_has_been_send_all_equipments_data', function (data: any) {
        eventBus.dispatch('terminal_has_been_updated_callback', data);
      });
    }
  };
  return <></>;
});

EventListener.displayName = 'EventListener';
export default EventListener;

import { SelectData, SelectDataOption, SelectProps } from '../../stories/dune/molecules/SelectData';
import axios from 'axios';
import { useState } from 'react';
import Config from '../../Config';
import { useTranslation } from 'react-i18next';
import { ProductType } from '../../models/ProductType';
import stringUtils from '../../utils/stringUtils';

export type SelectProductTypeOption = SelectDataOption;

export const SelectProductType = ({
  setValue,
  register,
  registerName = 'productType',
  error,
  selectedOptionChanged,
  isPopup,
  onClosePopup,
  searchParent,
  dependsOnSearchParent = false,
  initialOption = undefined,
  forceSelectedOption = undefined,
  ...props
}: SelectProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const [options, setOptions] = useState<SelectProductTypeOption[]>([]);
  const [filterText, setFilterText] = useState('');

  const fetchData = (filterText: string) => {
    setIsLoading(true);

    setFetchedOptions(JSON.parse(localStorage.getItem('productTypes') ?? '[]'));

    setIsLoading(false);
  };

  const setFetchedOptions = (fetchedOptions: ProductType[]) => {
    const options: any[] = [];

    if (fetchedOptions != null) {
      fetchedOptions.forEach((s) => {
        const option = {
          value: s.id,
          label: s.label + (s.externalReference ? ' - ' + s.externalReference : ''),
        };

        options.push(option);
      });
      setOptions(options);
    }
  };

  return (
    <SelectData
      title={t('common.productType')}
      placeholder={t('common.productTypeChoose')}
      titleSize={props.titleSize ?? 'large'}
      hoverDescription={props.hoverDescription}
      setValue={setValue}
      register={register}
      registerName={registerName}
      error={error}
      isSelectable={props.isSelectable}
      selectedOptionChanged={selectedOptionChanged}
      clearOnSelect={props.clearOnSelect}
      isLoading={isLoading}
      options={options}
      searchParent={searchParent}
      dependsOnSearchParent={dependsOnSearchParent}
      initialOption={initialOption}
      forceSelectedOption={forceSelectedOption}
      filterText={''}
      fetch={fetchData}
      fetchAtLoad={true}
      onClickNoResult={undefined}
      isPopup={isPopup}
      onClosePopup={onClosePopup}
      inRow={props.inRow}
      isClearable={props.isClearable ?? true}
      noResultText={props.noResultTextOverride}
    ></SelectData>
  );
};

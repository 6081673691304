import DataTable from 'react-data-table-component';
import './productItems.scss';
import TableStyles from '../../styles/tableStyles';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Icon } from '../../stories/dune/atoms/Icon';
import { SymbolSwitch } from '../../stories/dune/molecules/SymbolSwitch';
import { useEffect, useState } from 'react';
import { Input } from '../../stories/dune/atoms/Input';
import {
  productItemsActions,
  ProductItemsState,
  ProductItemRow,
  loadTransportServices,
} from '../../store/productItems-slice';

import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { SelectProduct } from './SelectProduct';
import { SelectTransportMode, SelectTransportModeOption } from './SelectTransportMode';
import PopupContainer from '../../stories/dune/organisms/PopupContainer';
import { TransportMode } from '../../models/Product';
import { SelectVatRate, SelectVatRateOption } from './SelectVatRates';
import { TooltipIcon } from '../../stories/dune/molecules/TooltipIcon';
import { Button } from '../../stories/dune/atoms/Button';
import { GenerateInvoicesParameters, InvoiceStatus, InvoiceType } from '../../models/Invoice';
import axios from 'axios';
import Config from '../../Config';
import useToast from '../../hooks/use-toast';
import { ToastTypes } from '../../models/ToastTypes';
import moment from 'moment';
import { VatRate } from '../../models/VatRate';
import { throttle } from 'lodash';
import { GlobalParameter } from '../../models/GlobalParameter';

interface ProductItemsProps {
  label: string;
  canAdd: boolean;
  canRemove: boolean;
  isLoading: boolean;
  showProductLink?: boolean;
  showMainProduct?: boolean;
  showExternalRef?: boolean;
  showWeightByUnit?: boolean;
  showOrderedQuantity?: boolean;
  canEditOrderedQuantity?: boolean;
  showDeliveredQuantity?: boolean;
  canEditDeliveredQuantity?: boolean;
  showTransportMode?: boolean;
  canEditTransportMode?: boolean;
  showUnitPrice?: boolean;
  showProductPrice?: boolean;
  canEditUnitPrice?: boolean;
  showDiscountValue?: boolean;
  canEditDiscountValue?: boolean;
  showRowExVatPrice?: boolean;
  showRowInclVatPrice?: boolean;
  showVatRate?: boolean;
  canEditVatRate?: boolean;
  showTotal?: boolean;
  showTotalDetails?: boolean;
  hideIncludedTransportMode?: boolean;
  showProductComment?: boolean;
  hideRep?: boolean;
  showInvoicing?: boolean;
}

export const ProductItems = (props: ProductItemsProps) => {
  const dispatch = useDispatch<AppDispatch>();

  const { t } = useTranslation();
  const { addToast } = useToast();
  const history = useNavigate();

  const currentCurrency = 'EUR';

  const [invoiceManagement, setInvoiceManagement] = useState<boolean>(false);
  useEffect(() => {
    const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setInvoiceManagement(
      (globalParametersSite.find((x) => x.label === 'invoiceManagement')?.value ?? 'false') === 'true',
    );
  }, []);

  const { products } = useSelector((state: { productItems: ProductItemsState }) => {
    return {
      products: state.productItems.products,
    };
  });

  const [isOpenConfirmDeleteProduct, setIsOpenConfirmDeleteProduct] = useState(false);
  const [currentProductToDelete, setCurrentProductToDelete] = useState<ProductItemRow>();

  const [discountMode, setDiscountMode] = useState('');

  function currencyFormat(amount: number | undefined) {
    if (amount === undefined) return undefined;
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: currentCurrency }).format(amount);
  }

  function roundNumber(num: number, decimalPlaces: number): number {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(num * factor) / factor;
  }

  function addLineBreak(str: string) {
    return str.split('\n').map((subStr) => {
      return (
        <>
          {subStr}
          <br />
        </>
      );
    });
  }

  function seeProductDetail(row: ProductItemRow) {
    const url = 'products';
    return `/${url}/${row.productId}/popup`; // todo : popup
  }

  function seeInvoiceDetail(row: ProductItemRow) {
    const url = 'invoices';
    return `/${url}/${row.invoiceId}/update`;
  }

  useEffect(() => {
    return () => {
      dispatch(productItemsActions.reset());
    };
  }, []);

  const columns: any[] = [
    {
      name: '',
      sortable: false,
      right: true,
      cell: (row: any) => (
        <Link className='table-link' to={seeProductDetail(row)}>
          <Icon icon={'eye'} />
        </Link>
      ),
      button: true,
      omit: !(props.showProductLink ?? false),
    },
    {
      name: t('common.label'),
      selector: (row: ProductItemRow) => `${row.label} - ${row.desc}`,
      cell: (row: ProductItemRow) => (
        <div className='column-align-left'>
          <div>
            <strong className='caption2medium'>
              {row.label + (props.showExternalRef ? ' - ' + row.externalReference : '')}
            </strong>
          </div>
          <div>
            <i className='caption2medium'>{row.desc}</i>
          </div>
          {props.showUnitPrice && (row.tgapValue ?? row.repValue ?? 0) > 0 && (
            <div>
              <i className='caption2medium'>{t('common.includingBasePrice')}</i>
            </div>
          )}
          {props.showUnitPrice && (row.transportValue ?? 0) > 0 && (
            <div>
              <i className='caption2medium'>{t('common.includingTransport')}</i>
            </div>
          )}
          {!props.hideRep && props.showUnitPrice && (row.repValue ?? 0) > 0 && (
            <div>
              <i className='caption2medium'>{t('common.includingRepTax')}</i>
            </div>
          )}
          {props.showUnitPrice && (row.tgapValue ?? 0) > 0 && (
            <div>
              <i className='caption2medium'>{t('common.includingTgap')}</i>
            </div>
          )}
        </div>
      ),
      sortable: false,
      right: false,
      id: 'label',
      grow: 2,
    },
    {
      name: t('common.comment'),
      selector: (row: ProductItemRow) => `${row.comment}`,
      cell: (row: ProductItemRow) => (
        <div className='column-align-left'>
          <div>
            <i className='caption2medium'>{row.comment ? addLineBreak(row.comment) : undefined}</i>
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'comment',
      omit: !((props.showProductComment && products.find((x) => x.comment ?? '' !== '')) ?? false),
      grow: 2,
    },
    {
      name: t('common.unitPrice'),
      selector: (row: ProductItemRow) => row.itemUnitPrice,
      cell: (row: ProductItemRow) => (
        <>
          {!row.isEditingPrice && (
            <div
              className={'column-align-right' + props.canEditUnitPrice ? ' clickable' : ''}
              title={props.canEditUnitPrice ? t('common.clickToEdit') : undefined}
              onClick={() => {
                if (props.canEditUnitPrice) {
                  dispatch(
                    productItemsActions.setIsEditingPrice({
                      product: row,
                      newValue: true,
                    }),
                  );
                }
              }}
            >
              <div>
                <strong className='caption2medium'>
                  {productUnitPrice(row)
                    ? currencyFormat(productUnitPrice(row)) + ' / ' + t('unitShort.' + row.unit)
                    : '-'}
                </strong>
              </div>
              {props.showUnitPrice && (row.tgapValue ?? row.repValue ?? 0) > 0 && (
                <div className='column-align-left'>
                  <i className='caption2medium'>{currencyFormat(row.itemUnitPrice)}</i>
                </div>
              )}
              {(row.transportValue ?? 0) > 0 && (
                <div className='column-align-left'>
                  <i className='caption2medium'>{currencyFormat(row.transportValue)}</i>
                </div>
              )}
              {!props.hideRep && (row.repValue ?? 0) > 0 && (
                <div className='column-align-left'>
                  <i className='caption2medium'>{currencyFormat(row.repValue)}</i>
                </div>
              )}
              {(row.tgapValue ?? 0) > 0 && (
                <div className='column-align-left'>
                  <i className='caption2medium'>{currencyFormat(row.tgapValue)}</i>
                </div>
              )}
            </div>
          )}
          {row.isEditingPrice && (
            <Input
              onChange={function (newValue: string | number): void {
                if (props.canEditUnitPrice) {
                  dispatch(
                    productItemsActions.editProductUnitPrice({
                      product: row,
                      newValue: (newValue as number) ?? 0,
                    }),
                  );
                }
              }}
              error={''}
              type='number'
              number={{ min: 0, max: 99999999, step: 1 }}
              value={row.itemUnitPrice ?? ''}
              inRow={true}
              autoFocus={true}
              onBlur={() =>
                dispatch(
                  productItemsActions.setIsEditingPrice({
                    product: row,
                    newValue: false,
                  }),
                )
              }
            />
          )}
        </>
      ),
      sortable: false,
      right: false,
      id: 'genericUnitPrice',
      omit: !(props.showUnitPrice ?? false),
      grow: 1,
    },
    {
      name: t('common.vatRate'),
      selector: (row: ProductItemRow) => row.vatRateId,
      cell: (row: ProductItemRow) => (
        <>
          {
            <SelectVatRate
              setValue={undefined}
              register={undefined}
              registerName='vatRate'
              titleOverride=''
              error={''}
              selectedOptionChanged={(selectedOption: SelectVatRateOption) => {
                if (props.canEditVatRate) {
                  dispatch(
                    productItemsActions.editProductVatRate({
                      product: row,
                      newValue: { id: selectedOption.value, vatRate: selectedOption.data.vatRate },
                    }),
                  );
                }
              }}
              isPopup={false}
              onClosePopup={undefined}
              searchParent={''}
              dependsOnSearchParent={false}
              forceSelectedOption={
                row.vatRate !== undefined
                  ? {
                      value: row.vatRateId,
                      label: roundNumber(row.vatRate * 100, 4) + '%',
                      data: { vatRate: row.vatRate },
                    }
                  : undefined
              }
              isSelectable={props.canEditTransportMode ?? true}
              inRow={true}
              isClearable={false}
            />
          }
        </>
      ),
      sortable: false,
      right: false,
      button: false,
      id: 'vatRate',
      omit: !(props.showVatRate ?? false),
      grow: 1,
    },
    {
      name: props.showDeliveredQuantity ? t('common.orderedQuantity') : t('common.quantity'),
      selector: (row: ProductItemRow) => row.orderedQuantity,
      cell: (row: ProductItemRow) => (
        <div className='column'>
          <div>
            <Input
              onChange={function (newValue: string | number): void {
                if (props.canEditOrderedQuantity) {
                  dispatch(
                    productItemsActions.editProductOrderedQuantity({
                      product: row,
                      newValue: (newValue as number) ?? 1,
                    }),
                  );
                }
              }}
              error={''}
              type='number'
              number={{ min: 0, max: 99999999, step: 1 }}
              value={row.orderedQuantity ?? ''}
              suffix={!row.quantityIsSum ? t('unitShort.' + row.unit) : ' '}
              disabled={row.quantityIsSum || !props.canEditOrderedQuantity}
              inRow={true}
            />
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'orderedQuantity',
      omit: !(props.showOrderedQuantity ?? false),
      grow: 1,
    },
    {
      name: props.showOrderedQuantity ? t('common.deliveredQuantity') : t('common.quantity'),
      selector: (row: ProductItemRow) => row.deliveredQuantity,
      cell: (row: ProductItemRow) => (
        <div className='column'>
          <div>
            <Input
              onChange={function (newValue: string | number): void {
                if (props.canEditDeliveredQuantity) {
                  dispatch(
                    productItemsActions.editProductDeliveredQuantity({
                      product: row,
                      newValue: (newValue as number) ?? 1,
                    }),
                  );
                }
              }}
              error={''}
              type='number'
              number={{ min: 0, max: 99999999, step: 1 }}
              value={row.deliveredQuantity ?? '0'}
              suffix={!row.isService ? t('unitShort.' + row.unit) : ' '}
              disabled={!props.canEditDeliveredQuantity}
              inRow={true}
            />
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'deliveredQuantity',
      omit: !(props.showDeliveredQuantity ?? false),
      grow: 1,
    },
    {
      name: (
        <>
          <span title={t('common.transportModesDesc')}>{t('common.transport')}</span>
          <TooltipIcon
            icon={'question'}
            message={
              props.hideIncludedTransportMode
                ? t('common.transportModesDescNoIncluded')
                : t('common.transportModesDesc')
            }
          />
        </>
      ),
      selector: (row: ProductItemRow) => row.enableTransportPrice,
      cell: (row: ProductItemRow) => (
        <>
          {!row.isService && (
            <SelectTransportMode
              setValue={undefined}
              register={undefined}
              registerName='transportMode'
              titleOverride=''
              error={''}
              selectedOptionChanged={(selectedOption: SelectTransportModeOption) => {
                if (props.canEditTransportMode) {
                  dispatch(
                    productItemsActions.editProductTransportMode({
                      product: row,
                      newValue: selectedOption.value,
                    }),
                  );

                  if (
                    selectedOption.value === TransportMode.DELIVERED &&
                    products?.findIndex((x) => x.isTransport) === -1
                  ) {
                    dispatch(loadTransportServices({ id: row.vatRateId, vatRate: row.vatRate } as VatRate));
                  }
                }
              }}
              isPopup={false}
              onClosePopup={undefined}
              searchParent={''}
              dependsOnSearchParent={false}
              forceSelectedOption={{
                value: row.transportMode ?? TransportMode.EXWORKS,
                label: t('transportMode.' + (row.transportMode ?? TransportMode.EXWORKS)),
              }}
              isSelectable={props.canEditTransportMode ?? true}
              inRow={true}
              isClearable={false}
              hideIncludedTransportMode={props.hideIncludedTransportMode}
            />
          )}
        </>
      ),
      sortable: false,
      right: false,
      button: false,
      id: 'transportMode',
      omit: !(props.showTransportMode ?? false),
      grow: 1,
    },
    {
      name: (
        <div className='table-header'>
          <div className='table-header-column--box'>
            <div className='table-header-column--text'>{t('common.discount')}</div>
            <SymbolSwitch
              onChange={onChangeDiscountMode}
              checked={discountMode === '%'}
              leftSymbol={'€'}
              rightSymbol={'%'}
            />
          </div>
        </div>
      ),
      selector: (row: ProductItemRow) => row.discountPercent,
      cell: (row: ProductItemRow) => (
        <>
          {!row.isService && (
            <div className='column'>
              <div className='firstRow'>
                <Input
                  onChange={function (newValue: string | number): void {
                    if (props.canEditDiscountValue) {
                      const val =
                        discountMode === '%'
                          ? (newValue as number) / 100.0
                          : (newValue as number) / (productUnitPrice(row) ?? 1);
                      dispatch(
                        productItemsActions.editProductDiscountValue({
                          product: row,
                          newValue: roundNumber(val, 4),
                        }),
                      );
                    }
                  }}
                  error={''}
                  value={roundNumber(discountValue(row), 2)}
                  suffix={discountMode === '%' ? '%' : '€'}
                  disabled={!props.canEditDiscountValue}
                  inRow={true}
                  type='number'
                  number={{
                    min: 0.0,
                    max: discountMode === '%' ? 100.0 : productUnitPrice(row),
                    step: discountMode === '%' ? 0.1 : roundNumber(0.01 * productUnitPrice(row), 2),
                  }}
                />
              </div>
            </div>
          )}
        </>
      ),
      sortable: false,
      right: false,
      id: 'discount',
      omit: true, // TODO : revoir fonctionnement discount !(props.showDiscountValue ?? false),
      grow: 1.8,
    },
    {
      name: t('common.totalExVat'), // temp : TODO : put back when transportPrice is back t('common.productPrice'),
      selector: (row: ProductItemRow) => totalProductPrice(row),
      cell: (row: ProductItemRow) => (
        <div className='column'>
          <strong className='caption2medium'>{currencyFormat(totalProductPrice(row)) ?? '-'}</strong>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'productPrice',
      omit: !((props.showProductPrice && (products.find((x) => (x.itemUnitPrice ?? 0) !== 0) ?? false)) ?? true),
      grow: 1,
    },
    {
      name: t('common.transportPrice'),
      selector: (row: ProductItemRow) => totalTransportPrice(row),
      cell: (row: ProductItemRow) => (
        <>
          {!row.isService && (
            <div className='column'>
              <strong className='caption2medium'>{currencyFormat(totalTransportPrice(row)) ?? '-'}</strong>
            </div>
          )}
        </>
      ),
      sortable: false,
      right: false,
      id: 'transportPrice',
      omit: true, // disabled, TODO : review properly when doing invoices ? // allowMultipleSites ?? false,
      grow: 1,
    },
    {
      name: t('common.totalExVat'),
      selector: (row: ProductItemRow) => exVatPrice(row),
      cell: (row: ProductItemRow) => (
        <div className='column'>
          <strong className='caption2medium'>{currencyFormat(exVatPrice(row)) ?? '-'}</strong>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'exVatPrice',
      omit: true, // disabled, TODO : when transportPrice is back !(props.showRowExVatPrice ?? false),
      grow: 1,
    },
    {
      name: props.showProductPrice || props.showRowExVatPrice ? t('common.totalInclVat') : t('common.inclVat'),
      selector: (row: ProductItemRow) => inclVatPrice(row),
      cell: (row: ProductItemRow) => (
        <div className='column'>
          <strong className='caption2medium'>{currencyFormat(inclVatPrice(row)) ?? '-'}</strong>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'inclVatPrice',
      omit: !(props.showRowInclVatPrice ?? false),
      grow: 1,
    },
    {
      name: t('common.invoice'),
      selector: (row: ProductItemRow) => row.invoiceId,
      cell: (row: ProductItemRow) => (
        <>
          {row.invoice && (
            <Link className='table-link' to={seeInvoiceDetail(row)}>
              <a>
                {t('invoiceType.' + row.invoice.type) +
                  ' ' +
                  row.invoice.invoiceNumber +
                  (row.invoice.externalReference ? '-' + row.invoice.externalReference : '') +
                  (row.invoice.type === InvoiceType.PROFORMA
                    ? ''
                    : ' - ' +
                      t(
                        'status.' +
                          (row.invoice.status === InvoiceStatus.UPCOMING &&
                          row.invoice.invoiceDueDate !== undefined &&
                          moment(row.invoice.invoiceDueDate) < moment(new Date())
                            ? 'overdue'
                            : row.invoice.status),
                      ))}
              </a>
            </Link>
          )}
        </>
      ),
      sortable: false,
      button: true,
      right: true,
      id: 'invoice',
      omit: !((props.showInvoicing && (products.find((x) => (x.invoiceId ?? '') !== '') ?? false)) ?? false),
      grow: 1,
    },
    {
      name: '',
      sortable: false,
      cell: (row: any) => (
        <>
          {(!row.isService || row.isTransport) && (row.invoiceId ?? '') === '' && (
            <div
              className='column-last'
              onClick={(e: any) => {
                onClickDeleteProduct(row);
              }}
            >
              <Icon icon={'close'} />
            </div>
          )}
        </>
      ),
      button: true,
      right: true,
      id: 'remove',
      omit: !(props.canRemove ?? false),
      minWidth: '3%',
      grow: 1,
    },
  ];

  function onChangeDiscountMode() {
    setDiscountMode((prevState: string) => {
      return prevState === '%' ? '€' : '%';
    });
  }

  const onClickDeleteProduct = (e: any) => {
    setIsOpenConfirmDeleteProduct(true);
    setCurrentProductToDelete(e);
  };

  const onDeleteProductOk = (e: ProductItemRow) => {
    if (props.canRemove) dispatch(productItemsActions.removeProduct(e));
    setIsOpenConfirmDeleteProduct(false);
  };

  function discountValue(row: ProductItemRow) {
    const discPerc = row.discountPercent ?? 0;
    return discPerc * (discountMode === '%' ? 100.0 : productUnitPrice(row));
  }

  function productQuantity(row: ProductItemRow) {
    let lRet = 0;
    if (props.showTotal) {
      if (props.showDeliveredQuantity) lRet = row.deliveredQuantity ?? 0;
      else if (props.showOrderedQuantity) lRet = row.orderedQuantity ?? 0;
    } else lRet = 1;
    return lRet;
  }

  function productTaxableUnitPrice(row: ProductItemRow) {
    return row.itemUnitPrice
      ? row.itemUnitPrice + (props.hideRep ? 0 : row.repValue ?? 0) + (row.transportValue ?? 0)
      : 0;
  }

  function productUnitPrice(row: ProductItemRow, onlyTaxable = false) {
    return row.itemUnitPrice ? productTaxableUnitPrice(row) + (onlyTaxable ? 0 : row.tgapValue ?? 0) : 0;
  }

  function totalProductPrice(row: ProductItemRow, onlyTaxable = false) {
    const pricePerc = 1 - (row.discountPercent ?? 0);
    return pricePerc * (productUnitPrice(row, onlyTaxable) * productQuantity(row));
  }

  function transportPrice(row: ProductItemRow) {
    return 0;
  }

  function totalTransportPrice(row: ProductItemRow) {
    return transportPrice(row) * productQuantity(row);
  }

  function exVatPrice(row: ProductItemRow) {
    // Le prix du transport est "FRANCO DE PORT", c'est à dire que le prix de transport est inclus dans le prix du produit https://comptabilite.pagesjaunes.fr/astuce/voir/598467/tva-sur-les-frais-de-port
    return Math.round((totalProductPrice(row) + totalTransportPrice(row)) * 100) / 100; // round to 2 decimals
  }

  function vatPrice(row: ProductItemRow) {
    // get price only for taxable parts (no TGAP)
    return Math.round((totalProductPrice(row, true) + totalTransportPrice(row)) * (row.vatRate ?? 0) * 100) / 100; // round to 2 decimals
  }

  function inclVatPrice(row: ProductItemRow) {
    return exVatPrice(row) + vatPrice(row);
  }

  function getTotalPrice(data: ProductItemRow[]) {
    return data.reduce((total, item) => total + inclVatPrice(item), 0);
  }

  function getTotalVat(data: ProductItemRow[]) {
    return data.reduce((total, item) => total + vatPrice(item), 0);
  }

  function getTotalRepTax(data: ProductItemRow[]) {
    const total = data.reduce((total, item) => total + (item.repValue ?? 0) * (productQuantity(item) ?? 0), 0);

    const totalVat = data.reduce(
      (total, item) => total + (item.repValue ?? 0) * (productQuantity(item) ?? 0) * (item.vatRate ?? 0),
      0,
    );
    return total + totalVat;
  }

  function getTotalTgap(data: ProductItemRow[]) {
    const total = data.reduce((total, item) => total + (item.tgapValue ?? 0) * (productQuantity(item) ?? 0), 0);

    const totalVat = 0; // no VAT for TGAP

    return total + totalVat;
  }

  function getTotalTransportValue(data: ProductItemRow[]) {
    const total = data.reduce(
      (total, item) =>
        total + (item.transportValue ?? item.isTransport ? productUnitPrice(item) : 0) * (productQuantity(item) ?? 0),
      0,
    );

    const totalVat = data.reduce(
      (total, item) =>
        total +
        (item.transportValue ?? item.isTransport ? productUnitPrice(item, true) : 0) *
          (productQuantity(item) ?? 0) *
          (item.vatRate ?? 0),
      0,
    );

    return total + totalVat;
  }

  function handleGenerateInvoice() {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url = orgid + '/invoice/generate-from-trip-products';

    const dataToSend: GenerateInvoicesParameters = {
      invoiceType: InvoiceType.PROFORMA,
      tripProducts: products?.filter((x) => (x.invoiceId ?? '') === '')?.map((x) => x.id ?? '') ?? [],
    };

    axios
      .post(
        Config.getApiExtranetUrl(url),
        { ...dataToSend },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async (res) => {
        addToast(t('common.proformaGenerated'), ToastTypes.success);
        history('/invoices/' + res.data.content.invoices[0] + '/update');
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  }

  const throttledhandleGenerateInvoice = throttle(handleGenerateInvoice, 1000);

  return (
    <>
      {props.isLoading ?? false ? (
        <div>{t('common.loading')}</div>
      ) : (
        <>
          <DataTable
            className='table'
            customStyles={TableStyles}
            columns={columns}
            data={products}
            noDataComponent={<div className='noResults'>{t('common.noProduct')}</div>}
            sortServer
          />
          {props.canAdd && (
            <SelectProduct
              registerName='products'
              error={''}
              isSelectable={true}
              isPopup={false}
              titleSize='none'
              selectedOptionChanged={(selectedOption: any) => {
                if (selectedOption) {
                  dispatch(productItemsActions.addProduct(selectedOption.data));
                }
              }}
              clearOnSelect={true}
            />
          )}
          {props.showTotal && invoiceManagement && (products.find((x) => x.itemUnitPrice != null) ?? false) && (
            <div className='table-row'>
              {props.showInvoicing && (products.find((x) => (x.invoiceId ?? '') === '') ?? false) && (
                <div className='table-row-content table-row-content-left'>
                  <Button
                    onClick={() => throttledhandleGenerateInvoice()}
                    label={t('common.invoiceAction')}
                    size='large'
                  />
                </div>
              )}
              <div className='table-row-content table-row-content-right'>
                <div className='table-row-column--text-box'>
                  <div className='table-row-column--text-main'>{t('common.totalVat')}</div>
                  <div className='table-row-column--text-main'>{t('common.total')}</div>

                  {getTotalTransportValue(products) > 0 && (
                    <div>
                      <i className='caption2medium'>{t('common.includingTransport')}</i>
                    </div>
                  )}
                  {!props.hideRep && getTotalRepTax(products) > 0 && (
                    <div>
                      <i className='caption2medium'>{t('common.includingRepTax')}</i>
                    </div>
                  )}
                  {getTotalTgap(products) > 0 && (
                    <div>
                      <i className='caption2medium'>{t('common.includingTgap')}</i>
                    </div>
                  )}
                </div>
                <div className='table-row-column--text-box table-row-column--text-box--right'>
                  <div className='table-row-column--text-main'>{currencyFormat(getTotalVat(products))}</div>
                  <div className='table-row-column--text-main'>{currencyFormat(getTotalPrice(products))}</div>

                  {getTotalTransportValue(products) > 0 && (
                    <div>
                      <i className='caption2medium'>{currencyFormat(getTotalTransportValue(products))}</i>
                    </div>
                  )}
                  {!props.hideRep && getTotalRepTax(products) > 0 && (
                    <div>
                      <i className='caption2medium'>{currencyFormat(getTotalRepTax(products))}</i>
                    </div>
                  )}
                  {getTotalTgap(products) > 0 && (
                    <div>
                      <i className='caption2medium'>{currencyFormat(getTotalTgap(products))}</i>
                    </div>
                  )}
                </div>
                <div className='table-row-column'></div>
              </div>
            </div>
          )}
        </>
        // TOTAL HT 4 000,00 €
        // REMISE GLOBALE HT -400,00 €
        // TOTAL NET HT 3 600,00 €
        // TOTAL TVA 720,00 €
        // TOTAL 4 320,00 €
      )}

      {isOpenConfirmDeleteProduct && (
        <PopupContainer
          message={`${t('common.confirmDelete')} '${currentProductToDelete?.label}' ?`}
          onCancel={function (): void {
            setIsOpenConfirmDeleteProduct(false);
          }}
          onConfirm={function (): void {
            setIsOpenConfirmDeleteProduct(false);
            if (currentProductToDelete) onDeleteProductOk(currentProductToDelete);
          }}
          confirmLabel={t('common.yes')}
          confirmIcon='delete'
          cancelLabel={t('common.no')}
        />
      )}
    </>
  );
};

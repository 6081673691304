import Product, { GetProduct, ProductTaxes, TransportProductType } from '../models/Product';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import Config from '../Config';
import { CustomerTypeProductPrice } from '../models/CustomerType';
import { Invoice } from '../models/Invoice';
import Trip from '../models/Trip';
import { VatRate } from '../models/VatRate';
export interface ProductItemRow {
  id?: string;
  productId: string;
  label: string;
  desc: string;
  externalReference?: string;
  comment?: string;
  unit: string;
  weightByUnit?: number;
  orderedQuantity?: number;
  deliveredQuantity?: number;
  baseUnitPrice?: number;
  itemUnitPrice?: number;
  discountPercent?: number;
  vatRate?: number;
  vatRateId?: string;
  repValue?: number;
  tgapValue?: number;
  transportValue?: number; // only used in invoice row
  transportMode?: string;
  mainProduct?: boolean;
  enableTransportPrice?: boolean;
  showTransportDetails?: boolean;
  isService?: boolean;
  isTransport?: boolean;
  quantityIsSum?: boolean;
  isEditingPrice?: boolean;
  invoiceId?: string;
  invoice?: Invoice;
  tripId?: string;
  trip?: Trip;
}
function findProductIndex(
  arrayToSearch: ProductItemRow[],
  productToFind: string,
  externalReferenceToFind: string | undefined,
) {
  const index =
    arrayToSearch.findIndex(
      (x) =>
        x.productId === productToFind &&
        (externalReferenceToFind === undefined || x.externalReference === externalReferenceToFind),
    ) ?? -1;

  return index;
}

export interface ProductItemsState {
  products: ProductItemRow[];
  customerTypeProductPrices: CustomerTypeProductPrice[];
}
const sliceInitialState: ProductItemsState = {
  products: [],
  customerTypeProductPrices: [],
};

const addNewProduct = (
  products: ProductItemRow[],
  customerTypeProductPrices: CustomerTypeProductPrice[],
  product: GetProduct,
) => {
  if (product) {
    const productPrice =
      customerTypeProductPrices.find((x) => x.product?.id === product?.id)?.price ?? product.genericPrice ?? 0;

    const newProduct: ProductItemRow = {
      productId: product.id,
      label: product.label,
      desc: product.externalReference ?? '',
      isService: false,
      isTransport: TransportProductType === product?.productTypeId || product.productType === 'Service Transport',
      quantityIsSum: false,
      externalReference: undefined,
      unit: product.unit ?? 'U',
      weightByUnit: product.weightByUnit ?? 0,
      orderedQuantity: 0,
      deliveredQuantity: undefined,
      baseUnitPrice: product.genericPrice ?? 0,
      itemUnitPrice: productPrice,
      discountPercent: 0,
      vatRate: product.vatRate?.vatRate,
      vatRateId: product.vatRate?.id,
      repValue: product.repValue ?? 0,
      tgapValue: product.isSubjectToTgap ? ProductTaxes.TGAP : 0,
      mainProduct: false,
      enableTransportPrice: undefined,
    };

    if (newProduct.isTransport) {
      newProduct.isService = true;
      newProduct.orderedQuantity = 1;
    }

    if (newProduct.quantityIsSum) {
      newProduct.orderedQuantity = products.reduce((sum, x) => {
        if (!x.quantityIsSum && !x.isService) return sum + (x.orderedQuantity ?? 0);
        else return sum + 0;
      }, 0);
    }

    const index = findProductIndex(products, newProduct.productId, newProduct.externalReference);

    if (index === -1) {
      products.push(newProduct);
      products = sortProducts(products);
    }
  }

  return products;
};

const sortProducts = (products: ProductItemRow[]) => {
  products.sort((a, b) => {
    // Primary sort
    const quantityIsSumDiff = (a.quantityIsSum ? 1 : -1) - (b.quantityIsSum ? 1 : -1);
    const isServiceDiff = (a.isService ? 1 : -1) - (b.isService ? 1 : -1);
    const primarySort = quantityIsSumDiff + isServiceDiff;

    if (primarySort !== 0) {
      return primarySort;
    }

    // Secondary sort
    const secondarySort = (b.mainProduct ?? false ? 1 : -1) - (a.mainProduct ?? false ? 1 : -1);
    if (secondarySort !== 0) {
      return secondarySort;
    }
    // tertiary sort
    const tertiarySort = (a.externalReference ?? '').localeCompare(b.externalReference ?? '');
    if (tertiarySort !== 0) {
      return tertiarySort;
    }
    // quartenary sort
    const quarternarySort = a.label.localeCompare(b.label);

    return quarternarySort;
  });
  return products;
};

const refreshProductPrices = (products: ProductItemRow[], customerTypeProductPrices: CustomerTypeProductPrice[]) => {
  return products.map((x) => {
    const productPrice =
      customerTypeProductPrices.find((y) => y.product?.id === x?.productId)?.price ?? x.baseUnitPrice ?? 0;
    return { ...x, itemUnitPrice: productPrice };
  });
};

const productItemsSlice = createSlice({
  name: 'productItems',
  initialState: sliceInitialState,
  reducers: {
    reset: () => {
      return sliceInitialState;
    },
    setCustomerTypeProductPrices(
      state,
      action: PayloadAction<{ prices: CustomerTypeProductPrice[]; bRefresh: boolean }>,
    ) {
      state.customerTypeProductPrices = action.payload.prices;
      if (action.payload.bRefresh)
        state.products = refreshProductPrices(state.products, state.customerTypeProductPrices);
    },
    setProducts(state, action: PayloadAction<ProductItemRow[]>) {
      state.products = sortProducts(action.payload);
    },
    addProduct(state, action: PayloadAction<GetProduct | undefined>) {
      if (action.payload) {
        state.products = addNewProduct(state.products, state.customerTypeProductPrices, action.payload);
      }
    },
    removeProduct(state, action: PayloadAction<ProductItemRow>) {
      state.products = state.products.filter(
        (x) =>
          x.productId !== action.payload.productId ||
          (action.payload.externalReference !== undefined && x.externalReference !== action.payload.externalReference),
      );

      // #region quantityIsSum calcs
      const orderedQuantitySum = state.products.reduce((sum, x) => {
        if (!x.quantityIsSum && !x.isService) return sum + (x.orderedQuantity ?? 0);
        else return sum + 0;
      }, 0);
      state.products.map((x) => {
        if (x.quantityIsSum) x.orderedQuantity = orderedQuantitySum;
        return x;
      });
      state.products.sort((a, b) => {
        // Primary sort
        const quantityIsSumDiff = (a.quantityIsSum ? 1 : -1) - (b.quantityIsSum ? 1 : -1);
        const isServiceDiff = (a.isService ? 1 : -1) - (b.isService ? 1 : -1);
        const primarySort = quantityIsSumDiff + isServiceDiff;

        if (primarySort !== 0) {
          return primarySort;
        }

        // Secondary sort
        return a.label.localeCompare(b.label);
      });
      // #endregion quantityIsSum calcs
    },
    editProductOrderedQuantity(state, action: PayloadAction<{ product: ProductItemRow; newValue: number }>) {
      const index = findProductIndex(
        state.products,
        action.payload.product.productId,
        action.payload.product.externalReference,
      );

      if (index > -1) {
        state.products[index].orderedQuantity = action.payload.newValue;

        // #region quantityIsSum calcs
        const orderedQuantitySum = state.products.reduce((sum, x) => {
          if (!x.quantityIsSum && !x.isService) return sum + (x.orderedQuantity ?? 0);
          else return sum + 0;
        }, 0);
        state.products.map((x) => {
          if (x.quantityIsSum) x.orderedQuantity = orderedQuantitySum;
          return x;
        });
        // #endregion quantityIsSum calcs
      }
    },
    editProductDeliveredQuantity(state, action: PayloadAction<{ product: ProductItemRow; newValue: number }>) {
      const index = findProductIndex(
        state.products,
        action.payload.product.productId,
        action.payload.product.externalReference,
      );

      if (index > -1) {
        state.products[index].deliveredQuantity = action.payload.newValue;

        // #region quantityIsSum calcs
        const deliveredQuantitySum = state.products.reduce((sum, x) => {
          if (!x.quantityIsSum && !x.isService) return sum + (x.deliveredQuantity ?? 0);
          else return sum + 0;
        }, 0);
        state.products.map((x) => {
          if (x.quantityIsSum) x.deliveredQuantity = deliveredQuantitySum;
          return x;
        });
        // #endregion quantityIsSum calcs
      }
    },
    editProductDiscountValue(state, action: PayloadAction<{ product: ProductItemRow; newValue: number }>) {
      const index = findProductIndex(
        state.products,
        action.payload.product.productId,
        action.payload.product.externalReference,
      );

      if (index > -1) {
        state.products[index].discountPercent = action.payload.newValue;
      }
    },
    editProductTransportMode(state, action: PayloadAction<{ product: ProductItemRow; newValue: string }>) {
      const index = findProductIndex(
        state.products,
        action.payload.product.productId,
        action.payload.product.externalReference,
      );

      if (index > -1) {
        state.products[index].transportMode = action.payload.newValue;
      }
    },
    editProductComment(state, action: PayloadAction<{ product: ProductItemRow; newValue: string }>) {
      const index = findProductIndex(
        state.products,
        action.payload.product.productId,
        action.payload.product.externalReference,
      );

      if (index > -1) {
        state.products[index].comment = action.payload.newValue;
      }
    },
    editProductVatRate(
      state,
      action: PayloadAction<{ product: ProductItemRow; newValue: { id: string; vatRate: number } }>,
    ) {
      const index = findProductIndex(
        state.products,
        action.payload.product.productId,
        action.payload.product.externalReference,
      );

      if (index > -1) {
        state.products[index].vatRateId = action.payload.newValue.id;
        state.products[index].vatRate = action.payload.newValue.vatRate;
      }
    },
    setIsEditingPrice(state, action: PayloadAction<{ product: ProductItemRow; newValue: boolean }>) {
      const index = findProductIndex(
        state.products,
        action.payload.product.productId,
        action.payload.product.externalReference,
      );

      if (index > -1) {
        state.products[index].isEditingPrice = action.payload.newValue;
      }
    },
    editProductUnitPrice(state, action: PayloadAction<{ product: ProductItemRow; newValue: number }>) {
      const index = findProductIndex(
        state.products,
        action.payload.product.productId,
        action.payload.product.externalReference,
      );

      if (index > -1) {
        state.products[index].itemUnitPrice = action.payload.newValue;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadProductData.fulfilled, (state, action) => {
      state.products = action.payload.products;
    });
    builder.addCase(loadTransportServices.fulfilled, (state, action) => {
      let products = state.products;
      action.payload.transportServices?.map(
        (x) => (products = addNewProduct(products, state.customerTypeProductPrices, x)),
      );
      state.products = products;
    });
  },
});

export const loadProductData = createAsyncThunk('productItems/loadProductData', async (products: ProductItemRow[]) => {
  let retProducts = products.slice();
  await Promise.all(
    products.map(async (x) => {
      const productInfo = await fetchProductInfo(x.productId);
      if (productInfo) {
        const index = findProductIndex(retProducts, x.productId, x.externalReference);

        retProducts = [
          ...retProducts.slice(0, index),
          {
            ...retProducts[index],
            label: productInfo.label ?? '',
            desc: productInfo.externalReference ?? '',
            weightByUnit: productInfo.weightByUnit ?? 0,
          },
          ...retProducts.slice(index + 1),
        ];
      }
    }),
  );

  return { products: retProducts };
});

export const loadTransportServices = createAsyncThunk(
  'productItems/loadTransportServices',
  async (parentVatRate: VatRate) => {
    let retProducts: GetProduct[] | undefined = undefined;
    retProducts = await fetchTransportServices();

    retProducts = retProducts?.map((x) => {
      x.vatRate = parentVatRate;
      return x;
    });

    return { transportServices: retProducts };
  },
);

async function fetchProductInfo(productId: string): Promise<Product | undefined> {
  const token = localStorage.getItem('token');
  const orgid = localStorage.getItem('orgid');

  if (!token || !orgid) {
    return undefined;
  }

  const baseUrl = '/product/detail/' + productId;
  const url = orgid + `${baseUrl}`;

  try {
    const res = await axios.get(Config.getApiExtranetUrl(url), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return res.data.content;
  } catch (error) {
    return undefined;
  }
}
async function fetchTransportServices(): Promise<GetProduct[] | undefined> {
  const token = localStorage.getItem('token');
  const orgid = localStorage.getItem('orgid');

  if (!token || !orgid) {
    return undefined;
  }

  const baseUrl = '/product/get?page=1&limit=1000';
  const url = orgid + `${baseUrl}`;

  try {
    const res = await axios.post(
      Config.getApiExtranetUrl(url),
      { productTypeId: TransportProductType },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return res.data.content.items;
  } catch (error) {
    return undefined;
  }
}

export const productItemsActions = productItemsSlice.actions;

export default productItemsSlice.reducer;

import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import TableStyles from '../../../styles/tableStyles';
import axios from 'axios';
import Config from '../../../Config';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TabFilter from '../TabFilter';
import FilterHeader, { AdvancedFilter } from '../FilterHeader';
import useToast from '../../../hooks/use-toast';
import { ToastTypes } from '../../../models/ToastTypes';
import Trip, { GetTripParameters } from '../../../models/Trip';
import { TripStatus } from '@dune-manager/backend-core/dist/models/trip';
import moment from 'moment';
import { IconType } from '../../../stories/dune/atoms/Icon';
import { GlobalParameter } from '../../../models/GlobalParameter';

interface ListTripsProps {
  canAddNew: boolean;
  defaultStatus?: string;
  forcedFilters?: AdvancedFilter[];

  onAddNew?: () => void;
  addNewIcon?: IconType;
  addNewText?: string;
  addNewDesc?: string;
}

const ListTrips = React.memo((props: ListTripsProps) => {
  // #region intializing constants

  // #region generic
  const { t } = useTranslation();
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(true);

  const history = useNavigate();

  const [filtersAdvanced, setFiltersAdvanced] = useState<AdvancedFilter[]>(props.forcedFilters ?? []);
  const [filtersTab, setFiltersTab] = useState<string[]>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState(20);
  const [sortColumn, setSortColumn] = useState('tripDate');
  const [sortDirection, setSortDirection] = useState('desc');
  const [totalRows, setTotalRows] = useState(0);
  const [totalResource, setTotalResource] = useState(0);

  const [siteTimeZone, setSiteTimeZone] = useState<string>('Europe/Paris');
  useEffect(() => {
    const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setSiteTimeZone(globalParametersSite.find((x) => x.label === 'siteTimeZone')?.value ?? 'Europe/Paris');
  }, []);

  // #endregion generic

  // #region specific

  const [filteredItems, setFilteredItems] = useState<Trip[]>([]);

  const fetchItems = (
    page: number,
    newPerPage: number,
    newSortColumn: string = sortColumn,
    newSortDirection: string = sortDirection,
    TabFilter: string[] | undefined = filtersTab,
    AdvancedFilters: AdvancedFilter[] = filtersAdvanced,
  ) => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');

    const url =
      orgid +
      '/trip/get?page=' +
      page +
      '&limit=' +
      newPerPage +
      '&sortBy=' +
      newSortColumn +
      '&orderBy=' +
      newSortDirection;

    setCurrentPage(page);
    setIsLoading(true);
    setFilteredItems([]);

    if (newSortColumn != sortColumn) {
      setSortColumn(newSortColumn);
    }

    if (newSortDirection != sortDirection) {
      setSortDirection(newSortDirection);
    }

    axios
      .post(
        Config.getApiExtranetUrl(url),
        { ...getFiltersToSend(TabFilter, AdvancedFilters) },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async (res) => {
        setTotalRows(res.data.meta.total);
        setTotalResource(res.data.meta.total);
        setFilteredItems(res.data.content.items);

        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  function getFiltersToSend(tabFilters: string[] | undefined, advancedFilters: AdvancedFilter[]) {
    const toSend: GetTripParameters = {};

    if (tabFilters && tabFilters?.length > 0) toSend.status = tabFilters as TripStatus[];

    if (advancedFilters.length > 0) {
      advancedFilters.map((x) => {
        if (x.filterType === 'number') toSend.tripNumber = x.filterData;
        if (x.filterType === 'externalReference') toSend.tripExternalReference = x.filterData;
        if (x.filterType === 'tripType') toSend.tripType = [x.filterData?.value];
        if (x.filterType === 'order') toSend.orderId = x.filterData?.value;
        if (x.filterType === 'customer') toSend.customerId = x.filterData?.value;
        if (x.filterType === 'carrier') toSend.carrierId = x.filterData?.value;
        if (x.filterType === 'customerRef') toSend.customerOrderNumber = x.filterData;
        if (x.filterType === 'buildingSite') toSend.buildingSiteId = x.filterData?.value;
        if (x.filterType === 'dateStart')
          toSend.startDate = moment(new Date(x.filterData), 'YYYY-MM-DD', true).format('YYYY-MM-DD');
        if (x.filterType === 'dateEnd')
          toSend.endDate = moment(new Date(x.filterData), 'YYYY-MM-DD', true).format('YYYY-MM-DD');
        if (x.filterType === 'processingZone') toSend.processingZone = x.filterData?.value;
        if (x.filterType === 'currentZone') toSend.currentZone = x.filterData?.value;
        if (x.filterType === 'registrationPlate') toSend.registrationPlate = x.filterData;
      });
    }

    return toSend;
  }

  const tabFilterOptions = [
    { tabLabel: 'Tous', tabLabelTranslate: t('common.all') },
    { tabLabel: 'En attente', tabLabelTranslate: t('common.waiting') },
    { tabLabel: 'En cours', tabLabelTranslate: t('common.inProgress') },
    { tabLabel: 'Parti', tabLabelTranslate: t('common.exited') },
    { tabLabel: 'Refusé', tabLabelTranslate: t('common.refused') },
  ];

  const seeDetails = (row: Trip) => {
    // TODO INTEGRATION : gestion popup si dans sous-fenetre ?
    const url = 'Trips';
    return `/${url}/${row.id}/update`;
  };

  const columns: any[] = [
    {
      name: t('common.details'),
      selector: (row: Trip) => `${row.externalReference ?? row.tripNumber} - ${row.tripType?.label}`,
      cell: (row: Trip) => (
        <Link to={seeDetails(row)}>
          <div className='firstColumn' data-tag='allowRowEvents'>
            <div data-tag='allowRowEvents'>
              <strong className='caption2medium' data-tag='allowRowEvents'>
                {row.externalReference ?? row.tripNumber} - {row.tripType?.label}
              </strong>
            </div>
            <div data-tag='allowRowEvents'>
              <i className='caption2medium' data-tag='allowRowEvents'>
                {moment(row.tripDate).isValid()
                  ? moment(row.tripDate ?? null)
                      .tz(siteTimeZone)
                      .format('DD/MM/YYYY, HH:mm')
                  : moment(row.createdAt).tz(siteTimeZone).format('DD/MM/YYYY, HH:mm')}
              </i>
            </div>
          </div>
        </Link>
      ),
      sortable: false,
      right: false,
      id: 'details',
      grow: 2,
    },
    {
      name: t('common.vehicle'),
      selector: (row: Trip) => row.vehicle?.registrationPlate ?? row.registrationPlateRead,
      cell: (row: Trip) => (
        <div className='firstColumn' data-tag='allowRowEvents'>
          <div className='firstRow' data-tag='allowRowEvents'>
            <strong className='caption2medium' data-tag='allowRowEvents'>
              {row.vehicle?.registrationPlate ?? row.registrationPlateRead}
            </strong>
          </div>
          <div className='firstRow' data-tag='allowRowEvents'>
            <i className='caption2medium' data-tag='allowRowEvents'>
              {row.carrier?.label ?? ''}
            </i>
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'vehicle',
      grow: 1,
    },
    {
      name: t('common.order'),
      selector: (row: Trip) => row.tripNumber,
      cell: (row: Trip) => (
        <div className='firstColumn' data-tag='allowRowEvents'>
          <div data-tag='allowRowEvents'>
            <strong className='caption2medium' data-tag='allowRowEvents'>
              {row.order?.externalReference}
            </strong>
          </div>
          <div data-tag='allowRowEvents'>
            <i className='caption2medium' data-tag='allowRowEvents'>
              {row.order?.customerOrderNumber}
            </i>
          </div>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'commande',
      grow: 1,
    },
    {
      name: t('common.customer'),
      selector: (row: Trip) => row.customer?.label,
      cell: (row: Trip) => (
        <div className='firstColumn' data-tag='allowRowEvents'>
          <strong className='caption2medium' data-tag='allowRowEvents'>
            {row.customer?.label ?? '-'}
          </strong>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'customer',
      grow: 1,
    },
    {
      name: t('common.buildingSite'),
      selector: (row: Trip) => row.buildingSite?.label,
      cell: (row: Trip) => (
        <div className='firstColumn' data-tag='allowRowEvents'>
          <strong className='caption2medium' data-tag='allowRowEvents'>
            {row.buildingSite?.label ?? '-'}
          </strong>
        </div>
      ),
      sortable: false,
      right: false,
      id: 'buildingSite',
      grow: 1,
    },
  ];

  // #endregion specific

  // #endregion intializing constants

  // #region event handling

  const handlePageChange = (pageNumber: number) => {
    fetchItems(pageNumber, perPage);
  };

  const handlePerRowsChange = (newPerPage: any) => {
    setPerPage(newPerPage);
    fetchItems(1, newPerPage);
  };

  const onSort = (data: any, newSortOrder: any) => {
    if (data.id == sortColumn) {
      if (sortDirection == 'asc') {
        newSortOrder = 'desc';
      } else {
        newSortOrder = 'asc';
      }
    } else {
      newSortOrder = 'asc';
    }
    fetchItems(1, perPage, data.id, newSortOrder);
  };

  function handleSetFiltersAdvanced(newFilters: AdvancedFilter[]) {
    setFiltersAdvanced(newFilters);
    fetchItems(currentPage, perPage, sortColumn, sortDirection, filtersTab, newFilters);
  }

  function handleSetFiltersStatus(newFilters: string[]) {
    setFiltersTab(newFilters);
    fetchItems(currentPage, perPage, sortColumn, sortDirection, newFilters, filtersAdvanced);
  }

  // #endregion event handling

  let onAddNew = props.onAddNew;
  if (!onAddNew) {
    onAddNew = () => {
      history('./add');
    };
  }

  return (
    <>
      <FilterHeader
        title={''}
        allowQuickSearch={true}
        allowAdvancedSearch={true}
        advancedSearchOptions={[
          'number',
          'externalReference',
          'tripType',
          'order',
          'customer',
          'customerRef',
          'buildingSite',
          'carrier',
          'processingZone',
          'registrationPlate',
          'currentZone',
          'dateStart',
          'dateEnd',
        ]}
        showAddNew={props.canAddNew}
        addNewText={props.addNewText ?? t('common.newTrip')}
        addNewDesc={props.addNewDesc}
        addNew={onAddNew}
        addNewIcon={props.addNewIcon}
        forcedFilters={props.forcedFilters}
        quickSearchFilterTypes={['number']}
        advancedSearchFilterChanged={handleSetFiltersAdvanced}
      />
      <TabFilter tabArray={tabFilterOptions} parent={'Trip'} filterSet={handleSetFiltersStatus} defaultTab={'Tous'} />
      {isLoading ? (
        <div>{t('common.loading')}</div>
      ) : (
        <DataTable
          noHeader={true}
          customStyles={TableStyles}
          columns={columns}
          onRowClicked={(row) => history(seeDetails(row))}
          highlightOnHover
          pointerOnHover
          data={filteredItems}
          noDataComponent={<div className='noResults'>{t('common.noTrip')}</div>}
          defaultSortAsc={sortDirection == 'asc'}
          defaultSortFieldId={sortColumn}
          onSort={(data, newSortDirection) => onSort(data, newSortDirection)}
          sortServer
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationDefaultPage={currentPage}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          conditionalRowStyles={[{ when: (row: any) => row.hasBeenRefused, style: { backgroundColor: '#F6DFDF' } }]}
          paginationPerPage={perPage}
          paginationRowsPerPageOptions={[5, 10, 20, 50, 100, 200]}
          paginationComponentOptions={{
            rowsPerPageText: t('common.perPage'),
            rangeSeparatorText: t('common.onPage'),
            noRowsPerPage: false,
            selectAllRowsItem: false,
            selectAllRowsItemText: 'All',
          }}
        />
      )}
    </>
  );
});

ListTrips.displayName = 'ListTrips';
export default ListTrips;

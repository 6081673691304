import './Contracts.scss';
import React from 'react';
import { Head } from '../../../stories/dune/molecules/Head';
import { useTranslation } from 'react-i18next';

import Menu from '../../Menu';
import { useNavigate } from 'react-router-dom';
import ListContracts from '../../lists/Contracts/ListContracts';

const Quotations = React.memo(() => {
  const { t } = useTranslation();

  const history = useNavigate();

  return (
    <div className='main-container'>
      <Menu selectedPage='contracts'></Menu> {/* TODO : sortir et mettre dans classe 'parent'*/}
      <div className='right detail-container'>
        <Head
          title={t('common.contracts')}
          back={false}
          OnNotificationClick={function (): void {
            throw new Error('Function not implemented.');
          }}
          OnAutorisationClick={function (): void {
            throw new Error('Function not implemented.');
          }}
          Back={function (): void {
            history('/contracts');
          }}
        />
        <ListContracts />
      </div>
    </div>
  );
});

Quotations.displayName = 'Quotations';
export default Quotations;

import { useEffect, useState } from 'react';
import './Login.scss';
import '../index.scss';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Config from '../Config';
import { Buffer } from 'buffer';
import { useTranslation } from 'react-i18next';
import Lang from './Lang';
import { ReactComponent as Logo } from '../assets/svg/dune-logo.svg';
import ToastComponent from './ToastComponent';
import Toast from '../models/Toast';
import { ToastTypes } from '../models/ToastTypes';

const schema = yup.object().shape({
  email: yup.string().email('Adresse email non valide').required('Le mail est obligatoire'),
  password: yup
    .string()
    .required('Le mot de passe est obligatoire')
    .equals([yup.ref('confirmPassword')], 'Les mots de passe ne correspondent pas'),
  confirmPassword: yup
    .string()
    .required('Le mot de passe est obligatoire')
    .equals([yup.ref('password')], 'Les mots de passe ne correspondent pas'),
});

function ResetPassword() {
  const { t } = useTranslation();
  const [toasts, setToast] = useState<Toast[]>([]);

  const [errorMessage, setErrorMessage] = useState('');
  const [currentToken, setCurrentToken] = useState<string | null>('');

  const history = useNavigate();

  useEffect(() => {
    localStorage.clear();
    const token = new URLSearchParams(window.location.search).get('token');
    setCurrentToken(token);
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
  });

  const addToast = (message: string, type: ToastTypes) => {
    setToast([...toasts, new Toast(message, '', type)]);
  };

  const onSubmit = (data: any) => {
    const dataToSend = {
      username: data.email,
      password: Buffer.from(data.password).toString('base64'),
      resetToken: currentToken,
    };

    axios
      .post(Config.getUrl('auth/reset-password'), dataToSend)
      .then(() => {
        addToast(t('login.resetPassword.results.success'), ToastTypes.success);
        setTimeout(() => history('/'), 2000);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            setErrorMessage(t('login.IncorrectCredentials'));
          }
          if (error.response.status == 400) {
            setErrorMessage(t('login.resetPassword.results.expired'));
          }
        } else if (error.request) {
          setErrorMessage(t('login.ErrorMessage'));
        } else {
          setErrorMessage(t('login.ErrorMessage'));
        }
      });
  };

  const onKeyPressEvent = async (event: any) => {
    if (event.key === 'Enter') {
      await handleSubmit(onSubmit)();
    }
  };

  return (
    <div className='login'>
      <ToastComponent toastList={toasts} position={'top-right'} autoDelete={true} dismissTime={4000} key={1} />

      <div className='leftImage'></div>
      <div className='right'>
        <div style={{ textAlign: 'center' }}>
          <Logo></Logo>
        </div>

        <h1 className='title-center'>{t('login.resetPassword.title')}</h1>
        <div className='content'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <h5>{t('login.email')}</h5>

            <input type='text' {...register('email', {})} name='email' placeholder='Dune.manager@gmail.com' />
            <p className='validator-error'>{errors.email?.message}</p>

            <h5>{t('login.password')}</h5>

            <input
              type='password'
              {...register('password', {})}
              name='password'
              placeholder='***********'
              onKeyPress={onKeyPressEvent}
            />

            <p className='validator-error'>{errors.password?.message}</p>

            <h5>{t('login.resetPassword.confirmPassword')}</h5>

            <input
              type='password'
              {...register('confirmPassword', {})}
              name='confirmPassword'
              placeholder='***********'
            />

            <p className='validator-error'>{errors.confirmPassword?.message}</p>

            <p className='validator-error'>{errorMessage}</p>

            <div className='addBtn' onClick={handleSubmit(onSubmit)}>
              <div className='base2'>{t('login.resetPassword.reset')}</div>
            </div>
          </form>
        </div>
      </div>

      <div className='login langSelector'>
        <Lang></Lang>
      </div>
    </div>
  );
}

export default ResetPassword;

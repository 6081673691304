import { SelectData, SelectDataOption, SelectProps } from '../../stories/dune/molecules/SelectData';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InvoiceType } from '../../models/Invoice';

export type SelectInvoiceTypeOption = SelectDataOption

export const SelectInvoiceType = ({
  setValue,
  register,
  registerName = 'invoiceType',
  error,
  selectedOptionChanged,
  isPopup,
  onClosePopup,
  searchParent,
  dependsOnSearchParent = false,
  initialOption = undefined,
  forceSelectedOption = undefined,
  ...props
}: SelectProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const [options, setOptions] = useState<SelectInvoiceTypeOption[]>([]);

  const [defaultOption, setDefaultOption] = useState<SelectInvoiceTypeOption | undefined>(undefined);

  const invoiceTypeList = {
    proforma: t('invoiceType.' + InvoiceType.PROFORMA),
    invoice: t('invoiceType.' + InvoiceType.INVOICE),
    'credit-note': t('invoiceType.' + InvoiceType.CREDIT_NOTE),
  };

  const loadOptions = () => {
    const optionsInvoiceType: SelectInvoiceTypeOption[] = [];

    if (invoiceTypeList != null) {
      for (const [key, value] of Object.entries(invoiceTypeList)) {
        const option = {
          value: key,
          label: value,
          data: { value: key, label: value },
        };

        optionsInvoiceType.push(option);
      }
      setOptions(optionsInvoiceType);
      setDefaultOption(undefined);
    }
  };

  useEffect(() => {
    loadOptions();
  }, []);

  return (
    <SelectData
      title={props.titleOverride ?? t('common.invoiceType')}
      placeholder={t('common.invoiceTypeChoose')}
      titleSize={props.titleSize ?? 'large'}
      hoverDescription={props.hoverDescription}
      setValue={setValue}
      register={register}
      registerName={registerName}
      error={error}
      isSelectable={props.isSelectable}
      selectedOptionChanged={selectedOptionChanged}
      clearOnSelect={props.clearOnSelect}
      isLoading={isLoading}
      options={options}
      searchParent={searchParent}
      dependsOnSearchParent={dependsOnSearchParent}
      initialOption={initialOption}
      forceSelectedOption={forceSelectedOption ?? defaultOption}
      filterText={''}
      fetchAtLoad={false}
      onClickNoResult={undefined}
      isPopup={isPopup}
      onClosePopup={onClosePopup}
      inRow={props.inRow}
      isClearable={props.isClearable ?? true}
      noResultText={props.noResultTextOverride}
    ></SelectData>
  );
};

import React from 'react';
import ListTrips from '../../lists/Trips/ListTrips';
import { GetOrder } from '../../../models/Order';

interface TripOrderProps {
  id?: string;
  orderData: GetOrder | undefined;
}

const TripOrder = React.memo((props: TripOrderProps) => {
  return (
    <>
      {props.orderData && (
        <ListTrips
          canAddNew={false}
          forcedFilters={[
            {
              filterType: 'order',
              filterData: {
                value: props.orderData.id,
                label: props.orderData.customerOrderNumber + ' - ' + props.orderData.externalReference,
                data: props.orderData,
              },
            },
          ]}
        />
      )}
    </>
  );
});

TripOrder.displayName = 'TripOrder';
export default TripOrder;

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Config from '../../../Config';
import axios from 'axios';
import useToast from '../../../hooks/use-toast';
import { ToastTypes } from '../../../models/ToastTypes';
import { Head } from '../../../stories/dune/molecules/Head';
import Menu from '../../Menu';
import GeneralBuildingSite from './GeneralBuildingSite';
import SubMenuBuildingSite from './SubMenuBuildingSite';
import { GetBuildingSite } from '../../../models/BuildingSite';
import PopupContainer from '../../../stories/dune/organisms/PopupContainer';
import ListTrips from '../../lists/Trips/ListTrips';
import ListOrders from '../../lists/Orders/ListOrders';
import ListContacts from '../../lists/Contacts/ListContacts';
import { ContactEntity } from '@dune-manager/backend-core/dist/models/contact';
import ListAdditionalDocs from '../../lists/AdditionalDocs/ListAdditionalDocs';
import BuildingSiteContract from './BuildingSiteContract';

interface DetailBuildingSiteProps {
  id?: string;

  isPopup?: boolean;
  onClosePopup?: (createdId: string | undefined) => void;
  mode?: string;

  dataParent?: string;
}

const DetailBuildingSite = React.memo((props: DetailBuildingSiteProps) => {
  // #region generic

  const { t } = useTranslation();
  const { addToast } = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState('general');

  const history = useNavigate();

  type Params = {
    id: string;
    mode: string;
    autosign?: string;
    tab?: string;
  };

  const { id, mode, tab } = useParams<Params>();

  function updateCurrentTab(mode: string) {
    setCurrentTab(mode);
  }

  function getMode() {
    return props.mode ?? mode ?? '';
  }

  // #endregion generic

  // #region specific
  const [getBuildingSite, setGetBuildingSite] = useState<GetBuildingSite>();

  function getCurrentBuildingSite(id: string | undefined) {
    if (id && getMode() === 'update') {
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');
      const baseUrl = orgid + '/building-site/detail/';
      const url = Config.getApiExtranetUrl(baseUrl + id);
      setIsLoading(true);
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if ((res.data.content ?? null) !== null) {
            // on enregistre GetBuildingSite
            setGetBuildingSite(res.data.content);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          if ((id ?? null) !== null) {
            addToast(
              (error?.response?.data?.code
                ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
                : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
              ToastTypes.error,
            );
          }
        });
    } else setIsLoading(false);
  }

  useEffect(() => {
    getCurrentBuildingSite(id);
  }, [id, getMode()]);

  useEffect(() => {
    if (tab) setCurrentTab(tab);
  }, [tab]);

  // #endregion specific

  const content = (
    <>
      {!isLoading && (
        <>
          {getMode() === 'add' && (
            <GeneralBuildingSite
              mode={getMode()}
              buildingSiteData={getBuildingSite}
              onCancel={onCanceled}
              onConfirm={onConfirmed}
              dataParent={props.dataParent}
            />
          )}

          {getMode() !== 'add' && (
            <>
              <SubMenuBuildingSite tab={currentTab} updateTab={updateCurrentTab} />
              {currentTab === 'general' && (
                <GeneralBuildingSite
                  id={id}
                  mode={getMode()}
                  buildingSiteData={getBuildingSite}
                  onCancel={onCanceled}
                  onConfirm={onConfirmed}
                />
              )}
              {currentTab === 'contract' && id && (
                <BuildingSiteContract buildingSite={getBuildingSite} onCancel={onCanceled} />
              )}
              {currentTab === 'order' && id && (
                <ListOrders
                  canAddNew={true}
                  forcedFilters={[
                    {
                      filterType: 'buildingSite',
                      filterData: {
                        value: getBuildingSite?.id,
                        label: getBuildingSite?.label + ' - ' + getBuildingSite?.externalReference,
                        data: getBuildingSite,
                      },
                    },
                  ]}
                />
              )}
              {currentTab === 'trip' && id && (
                <ListTrips
                  canAddNew={false}
                  forcedFilters={[
                    {
                      filterType: 'buildingSite',
                      filterData: {
                        value: getBuildingSite?.id,
                        label: getBuildingSite?.label + ' - ' + getBuildingSite?.externalReference,
                        data: getBuildingSite,
                      },
                    },
                  ]}
                />
              )}
              {currentTab === 'documents' && getBuildingSite && (
                <ListAdditionalDocs entityType='building_site' entityId={getBuildingSite.id ?? ''} canAddNew={true} />
              )}
              {currentTab === 'contact' && id && (
                <ListContacts
                  contactEntity={ContactEntity.BUILDING_SITE}
                  entityId={id}
                  canAdd={true}
                  canEdit={true}
                  canRemove={true}
                  canSelect={false}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );

  function onConfirmed(data: any) {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(data);
    } else {
      history('/buildingSites');
    }
  }
  function onCanceled() {
    if (props.isPopup) {
      if (props.onClosePopup === undefined) throw new Error('Function not implemented.');
      else props.onClosePopup(undefined);
    } else {
      history('/buildingSites');
    }
  }

  return (
    <>
      {props.isPopup ? (
        <PopupContainer
          hideBottomBar={true}
          isLarge={true}
          title={
            getMode() === 'add'
              ? t('common.newJobSite')
              : t('common.buildingSite') +
                ' ' +
                (!getBuildingSite
                  ? ''
                  : getBuildingSite.label +
                    (getBuildingSite.externalReference ? '-' + getBuildingSite.externalReference : ''))
          }
        >
          {content}
        </PopupContainer>
      ) : (
        <div className='main-container'>
          <Menu selectedPage='buildingSites'></Menu> {/* TODO : sortir et mettre dans classe 'parent'*/}
          <div className='right detail-container'>
            <Head
              title={
                getMode() === 'add'
                  ? t('common.newJobSite')
                  : t('common.jobSite') +
                    ' ' +
                    (!getBuildingSite
                      ? ''
                      : getBuildingSite.label +
                        (getBuildingSite.externalReference ? '-' + getBuildingSite.externalReference : ''))
              }
              back={true}
              OnNotificationClick={function (): void {
                throw new Error('Function not implemented.');
              }}
              OnAutorisationClick={function (): void {
                throw new Error('Function not implemented.');
              }}
              Back={function (): void {
                history('/buildingSites');
              }}
            />
            {content}
          </div>
        </div>
      )}
    </>
  );
});

DetailBuildingSite.displayName = 'DetailBuildingSite';
export default DetailBuildingSite;

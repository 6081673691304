import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import GeneralContract from '../Contracts/GeneralContract';
import BuildingSite from '../../../models/BuildingSite';
import { GetQuotation as GetContract } from '../../../models/Quotation';
import Config from '../../../Config';
import axios from 'axios';
import useToast from '../../../hooks/use-toast';
import { ToastTypes } from '../../../models/ToastTypes';

interface BuildingSiteContractProps {
  buildingSite: BuildingSite | undefined;

  onCancel: () => void;
}

const BuildingSiteContract = React.memo((props: BuildingSiteContractProps) => {
  const { t } = useTranslation();
  const { addToast } = useToast();

  const history = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const [getContract, setGetContract] = useState<GetContract>();

  function getCurrentContract() {
    if (props.buildingSite) {
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');
      const baseUrl = orgid + '/quotation/get';
      const url = Config.getApiExtranetUrl(baseUrl);

      const dataToSend = { buildingSiteId: props.buildingSite.id, quotationType: 'Contract', dateStart: new Date() };

      setIsLoading(true);
      axios
        .post(url, dataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.content.items.length > 0) {
            // on enregistre GetContract
            getCurrentContractDetails(res.data.content.items[0].id);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        });
    }
  }

  function getCurrentContractDetails(id: string | undefined) {
    if (id) {
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');
      const baseUrl = orgid + '/quotation/detail/';
      const url = Config.getApiExtranetUrl(baseUrl + id);
      setIsLoading(true);
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if ((res.data.content ?? null) !== null) {
            // on enregistre GetContract
            setGetContract(res.data.content);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          if ((id ?? null) !== null) {
            addToast(
              (error?.response?.data?.code
                ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
                : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
              ToastTypes.error,
            );
          }
        });
    }
  }
  useEffect(() => {
    getCurrentContract();
  }, [props.buildingSite]);

  return (
    <GeneralContract
      id={getContract?.id ?? undefined}
      mode={getContract ? 'update' : 'add'}
      isEditable={true}
      contractMode={'buildingSite'}
      contractData={getContract}
      contractCustomer={props.buildingSite?.customer}
      contractBuildingSite={props.buildingSite}
      onCancel={props.onCancel}
      onConfirm={() => {
        getCurrentContract();
      }}
      onReload={() => {
        getCurrentContract();
      }}
    />
  );
});

BuildingSiteContract.displayName = 'BuildingSiteContract';
export default BuildingSiteContract;

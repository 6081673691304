import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './DetailProduct.scss';
import * as yup from 'yup';
import Config from '../../../Config';
import { ToastTypes } from '../../../models/ToastTypes';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CardDetail } from '../../../stories/dune/atoms/CardDetail';
import { FixBar } from '../../../stories/dune/molecules/FixBar';
import { Button } from '../../../stories/dune/atoms/Button';
import { GetProduct, ProductSave, TransportProductType } from '../../../models/Product';
import useToast from '../../../hooks/use-toast';
import BuildDataCustom, { DataCustomItem } from '../../forms/BuildDataCustom';
import { SelectProductType, SelectProductTypeOption } from '../../forms/SelectProductType';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { Input } from '../../../stories/dune/atoms/Input';
import Switch from 'react-switch';
import { SelectProductUnit } from '../../forms/SelectProductUnit';
import stringUtils from '../../../utils/stringUtils';
import { ErrorLine } from '../../../stories/dune/atoms/ErrorLine';
import { SelectVatRate } from '../../forms/SelectVatRates';
import { Title } from '../../../stories/dune/atoms/Title';
import {
  CustomerType,
  CustomerTypeProductPriceSave,
  GetCustomerTypeProductPrice,
  GetCustomerTypeProductPriceParameters,
} from '../../../models/CustomerType';
import { ProductPrices } from '../../forms/ProductPrices';
import { forEach } from 'lodash';

interface GeneralProductProps {
  id?: string;
  productData: GetProduct | undefined;

  onCancel: () => void;
  onConfirm: (data: any) => void;

  mode: string;
}

interface IFormInputs {
  label: string;
  unit: {
    label: string;
    value: string;
  };
  weightByUnit: number;
  actualStockQuantity: number;
  externalReference: string;
  multiProductPriority: boolean;
  productType: {
    label: string;
    value: string;
  };
  taricCode: string;
  genericPrice: number;
  vatRate: {
    label: string;
    value: string;
  };
  repCode?: string;
  repUnit?: {
    label: string;
    value: string;
  };
  repValue?: number;
  isSubjectToTgap?: boolean;
}

const GeneralProduct = React.memo((props: GeneralProductProps) => {
  const { t } = useTranslation();
  const history = useNavigate();
  const { addToast } = useToast();

  const [isAddingLoading, setIsAddingLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [saveProduct, setSaveProduct] = useState<ProductSave>();

  const [isActive, setIsActive] = useState<boolean>(true);

  const [externalReference, setExternalReference] = useState<string>();
  const [label, setLabel] = useState<string>();

  const [productTypeOption, setProductTypeOption] = useState<SelectProductTypeOption | null>(
    props.productData?.productType
      ? {
          value: props.productData.productTypeId,
          label: props.productData.productType,
        }
      : null,
  );

  const [weightByUnit, setWeightByUnit] = useState<number>();
  const [multiProductPriority, setMultiProductPriority] = useState<boolean>(false);
  const [selectedOptionProductType, setSelectedOptionProductType] = useState<any>();
  const [selectedOptionUnit, setSelectedOptionUnit] = useState<any>();
  const [selectedOptionVatRate, setSelectedOptionVatRate] = useState<any>();
  const [actualStockQuantity, setActualStockQuantity] = useState<number>();
  const [taricCode, setTaricCode] = useState<string>();
  const [genericPrice, setGenericPrice] = useState<number>();

  const [repCode, setRepCode] = useState<string>();
  const [selectedOptionRepUnit, setSelectedOptionRepUnit] = useState<any>();
  const [repValue, setRepValue] = useState<number>();
  const [isSubjectToTgap, setIsSubjectToTgap] = useState<boolean>();

  const [definePricesByCustomerType, setDefinePricesByCustomerType] = useState<boolean>(false);
  const [isTransport, setIsTransport] = useState<boolean>(false);

  const [productDataCustom, setProductDataCutom] = useState<DataCustomItem>({});

  const [customerTypeProductPrices, setCustomerTypeProductPrices] = useState<GetCustomerTypeProductPrice[]>([]);
  const [customerTypeProductPricesToSave, setCustomerTypeProductPricesToSave] = useState<string[]>([]);

  const schema = yup.object().shape({
    productType: yup.object().shape({
      value: yup.string().required(t('common.requiredProductType')),
      label: yup.string().required(t('common.requiredProductType')),
    }),
    label: yup.string().required(t('common.requiredLabel')).min(0),
    unit: yup.object().shape({
      value: yup.string().required(t('common.requiredProductUnit')),
      label: yup.string().required(t('common.requiredProductUnit')),
    }),
  });

  const {
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (props.productData) {
      // on initialise SaveProduct pour ne pas perdre les données non-gérées lors de la sauvegarde
      setSaveProduct({
        ...props.productData,
      });

      setIsActive(props.productData?.isActive ?? true);

      setValue('label', props.productData?.label ?? '');
      setLabel(props.productData?.label);
      setValue('externalReference', props.productData?.externalReference ?? '');
      setExternalReference(props.productData?.externalReference);
      setValue('taricCode', props.productData?.taricCode ?? '');
      setTaricCode(props.productData?.taricCode);
      setValue('multiProductPriority', props.productData?.multiProductPriority ?? false);
      setMultiProductPriority(props.productData?.multiProductPriority ?? false);
      setValue('weightByUnit', props.productData?.weightByUnit ?? 0);
      setWeightByUnit(props.productData?.weightByUnit);
      setValue('actualStockQuantity', props.productData?.actualStockQuantity ?? 0);
      setActualStockQuantity(props.productData?.actualStockQuantity);
      setValue('genericPrice', props.productData?.genericPrice ?? 0);
      setGenericPrice(props.productData?.genericPrice);

      if (props.productData.productType) {
        setValue('productType', { value: props.productData?.productTypeId, label: props.productData?.productType });
        setSelectedOptionProductType({
          value: props.productData?.productTypeId,
          label: props.productData?.productType,
        });
        setIsTransport(TransportProductType === props.productData?.productTypeId);
      }

      if (props.productData.unit) {
        setValue('unit', {
          value: props.productData?.unit,
          label: props.productData?.unit ? t('unit.' + props.productData?.unit) : '',
        });
        setSelectedOptionUnit({
          value: props.productData?.unit,
          label: props.productData?.unit ? t('unit.' + props.productData?.unit) : '',
        });
      }

      if (props.productData.vatRate) {
        setValue('vatRate', {
          value: props.productData?.vatRate.id,
          label: props.productData?.vatRate.label,
        });
        setSelectedOptionVatRate({
          value: props.productData?.vatRate.id,
          label: props.productData?.vatRate.label + ' (' + props.productData?.vatRate.vatRate * 100 + '%)',
        });
      }

      setValue('isSubjectToTgap', props.productData?.isSubjectToTgap ?? false);
      setIsSubjectToTgap(props.productData?.isSubjectToTgap);
      setValue('repCode', props.productData?.repCode ?? undefined);
      setRepCode(props.productData?.repCode);
      setValue('repValue', props.productData?.repValue ?? 0);
      setRepValue(props.productData?.repValue);
      if (props.productData.repUnit) {
        setValue('repUnit', {
          value: props.productData?.repUnit,
          label: props.productData?.repUnit ? t('unit.' + props.productData?.repUnit) : '',
        });
        setSelectedOptionRepUnit({
          value: props.productData?.repUnit,
          label: props.productData?.repUnit ? t('unit.' + props.productData?.repUnit) : '',
        });
      }

      setProductDataCutom(props.productData?.dataCustom as any);

      getProductPrices(props.productData.id);
    }
  }, [props.productData]);

  function onSubmit() {
    setIsAddingLoading(true);

    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    let baseUrl = orgid + '/product/add';
    if (props.mode == 'update') baseUrl = orgid + '/product/edit/' + props.id;

    if (saveProduct) {
      const dataToSend: ProductSave = saveProduct;

      dataToSend.label = label;
      dataToSend.externalReference = externalReference;
      dataToSend.weightByUnit = weightByUnit;
      dataToSend.multiProductPriority = multiProductPriority;
      dataToSend.actualStockQuantity = actualStockQuantity;
      dataToSend.taricCode = taricCode;
      dataToSend.genericPrice = genericPrice;
      dataToSend.repCode = repCode;
      dataToSend.repValue = repValue;
      dataToSend.isSubjectToTgap = isSubjectToTgap;
      dataToSend.isActive = isActive;

      axios
        .post(Config.getApiExtranetUrl(baseUrl), stringUtils.formatFieldsForPost(dataToSend), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          forEach(customerTypeProductPricesToSave, (customerTypeId) => {
            const priceData = customerTypeProductPrices.find((x) => x.customerType.id === customerTypeId);
            let baseUrl = orgid + '/customer-type-product-price/add';
            if (priceData?.id) baseUrl = orgid + '/customer-type-product-price/edit/' + priceData?.id;

            const dataToSend2: CustomerTypeProductPriceSave = {
              price: priceData?.price ?? 0,
              customerTypeId: customerTypeId,
              productId: props.id ?? res.data.content.uuid ?? '',
              vatRatId: '6566abec-95a2-4eae-b014-35add27108c7', // vat rate always 0% because not managed
              isActive: priceData?.isActive ?? true,
            };
            axios.post(Config.getApiExtranetUrl(baseUrl), stringUtils.formatFieldsForPost(dataToSend2), {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
          });

          setIsAddingLoading(false);
          addToast(t('common.product' + (props.mode === 'update' ? 'Updated' : 'Created')), ToastTypes.success);
          props.onConfirm(res.data.content.uuid);
        })
        .catch((error) => {
          setIsAddingLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        });
    } else {
      setIsAddingLoading(false);
    }
  }

  function getProductPrices(id: string) {
    if (id && props.mode === 'update') {
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');
      const baseUrl = orgid + '/customer-type-product-price/get';
      const url = Config.getApiExtranetUrl(baseUrl);
      setIsLoading(true);

      const dataToSend: GetCustomerTypeProductPriceParameters = { productId: id };
      axios
        .post(url, dataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if ((res.data.content ?? null) !== null) {
            // on enregistre
            setCustomerTypeProductPrices(res.data.content.items);
            if (
              res.data.content.items.length > 0 &&
              res.data.content.items.find((x: GetCustomerTypeProductPrice) => x.isActive)
            ) {
              setDefinePricesByCustomerType(true);
            }
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          if ((id ?? null) !== null) {
            addToast(
              (error?.response?.data?.code
                ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
                : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
              ToastTypes.error,
            );
          }
        });
    } else setIsLoading(false);
  }

  function handleProductPriceChanged(customerType: CustomerType, newPrice: number) {
    if (customerTypeProductPrices) {
      // edit existing price
      const newPrices = customerTypeProductPrices.map((x) => {
        if (x.customerType.id === customerType.id) {
          return { ...x, price: newPrice };
        }
        return x;
      });

      // add new price if not exists
      if (!newPrices.find((x) => x.customerType.id === customerType.id)) {
        newPrices.push({
          id: '',
          customerType: customerType,
          product: {
            id: props.id ?? '',
            label: saveProduct?.label ?? '',
            unit: saveProduct?.unit ?? '',
          },
          price: newPrice,
          vatRate: { label: '', vatRate: 0 },
          isActive: true,
        });
      }

      if (customerTypeProductPricesToSave.indexOf(customerType.id) === -1)
        setCustomerTypeProductPricesToSave([...customerTypeProductPricesToSave, customerType.id]);

      setCustomerTypeProductPrices(newPrices);
    }
  }

  function handleDefinePricesByCustomerType(checked: boolean) {
    setDefinePricesByCustomerType(checked);

    // disable all prices if unchecked, enable all prices if checked
    if (customerTypeProductPrices && customerTypeProductPrices.length > 0) {
      let pricesToSave = customerTypeProductPricesToSave;
      const disabledPrices = customerTypeProductPrices.map((x) => {
        if (x.isActive !== checked) {
          if (
            customerTypeProductPricesToSave.indexOf(x.customerType.id) === -1 &&
            pricesToSave.indexOf(x.customerType.id) === -1
          ) {
            pricesToSave = [...pricesToSave, x.customerType.id];
          }
          return { ...x, isActive: checked };
        }
        return x;
      });
      setCustomerTypeProductPrices(disabledPrices);
      setCustomerTypeProductPricesToSave(pricesToSave);
    }
  }

  function roundNumber(num: number, decimalPlaces: number): number {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(num * factor) / factor;
  }

  return !props.productData && props.mode === 'update' ? (
    <div>Loading...</div>
  ) : (
    <form id='form' onSubmit={handleSubmit(onSubmit)}>
      <CardDetail>
        <div className='form-section'>
          <FormRow>
            <Title label={t('common.generalInformations')} type='title1' />

            {props.mode === 'update' && (
              <FormRow align='right'>
                <h3 className='base2' style={{ margin: '0px 10px' }}>
                  {t('common.active')}
                </h3>
                <Switch
                  className='base2'
                  type='text'
                  onChange={function () {
                    setIsActive((prevValue) => !prevValue);
                  }}
                  checked={isActive ?? false}
                  onColor={'#2a85ff'}
                />
              </FormRow>
            )}
          </FormRow>
          <FormRow>
            <Input
              {...register('label')}
              error={errors.label?.message ?? ''}
              label={t('common.label')}
              type='text'
              value={label ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setValue('label', e.toString());
                setLabel(e.toString());
              }}
            />

            <SelectProductType
              titleSize='normal'
              register={register}
              registerName='productType'
              setValue={setValue}
              error={errors.productType?.value?.message ?? errors.productType?.label?.message ?? ''}
              isSelectable={true}
              selectedOptionChanged={(e: SelectProductTypeOption) => {
                if (e && e.value) {
                  setValue('productType', {
                    value: e?.value,
                    label: e?.label,
                  });

                  setSaveProduct((prevValue) => {
                    return { ...prevValue, productTypeId: e?.value };
                  });

                  setIsTransport(TransportProductType === e?.value);
                } else {
                  setValue('productType', {
                    value: '',
                    label: '',
                  });

                  setSaveProduct((prevValue) => {
                    return { ...prevValue, productTypeId: undefined };
                  });

                  setIsTransport(false);
                }
              }}
              forceSelectedOption={productTypeOption}
            />
          </FormRow>
          <FormRow>
            <Input
              {...register('externalReference')}
              error={errors.externalReference?.message ?? ''}
              label={t('common.yourReference')}
              type='text'
              value={externalReference ?? ''}
              placeholder=''
              disabled={false}
              onChange={function (e): void {
                setValue('externalReference', e.toString());
                setExternalReference(e.toString());
              }}
            />
            <SelectProductUnit
              titleSize='normal'
              titleOverride={t('common.unit')}
              register={register}
              registerName='unit'
              setValue={setValue}
              error={errors.unit?.value?.message ?? errors.unit?.label?.message ?? ''}
              isSelectable={true}
              selectedOptionChanged={(e: any) => {
                if (e && e.value) {
                  setValue('unit', {
                    value: e?.value,
                    label: e?.label,
                  });

                  setSaveProduct((prevValue) => {
                    return { ...prevValue, unit: e?.value };
                  });

                  setSelectedOptionUnit(e);
                } else {
                  setValue('unit', {
                    value: '',
                    label: '',
                  });

                  setSaveProduct((prevValue) => {
                    return { ...prevValue, unit: undefined };
                  });
                }
              }}
              forceSelectedOption={selectedOptionUnit}
            />
          </FormRow>
          {!isTransport && (
            <FormRow>
              <Input
                {...register('actualStockQuantity')}
                error={errors.actualStockQuantity?.message ?? ''}
                label={t('common.actualStockQuantity')}
                type='number'
                value={actualStockQuantity ?? ''}
                placeholder=''
                disabled={false}
                onChange={function (newValue: string | number): void {
                  setValue('actualStockQuantity', newValue as number);
                  setActualStockQuantity(newValue as number);
                }}
              />
              {selectedOptionUnit?.value !== 'kg' && selectedOptionUnit?.value !== 'ton' && (
                <Input
                  {...register('weightByUnit')}
                  error={errors.weightByUnit?.message ?? ''}
                  label={t('common.weightPerUnitDesc')}
                  hoverDescription={t('common.weightPerUnitDesc')}
                  type='number'
                  value={weightByUnit ?? ''}
                  placeholder=''
                  disabled={false}
                  onChange={function (newValue: string | number): void {
                    setValue('weightByUnit', newValue as number);
                    setWeightByUnit(newValue as number);
                  }}
                />
              )}
            </FormRow>
          )}
        </div>

        <div className='form-section'>
          <FormRow>
            <Title label={t('common.prices')} type='title1' />
          </FormRow>

          <FormRow>
            <Input
              {...register('genericPrice')}
              error={errors.genericPrice?.message ?? ''}
              label={t('common.genericPrice') + ' / ' + selectedOptionUnit?.label ?? ''}
              hoverDescription={t('common.genericPriceDesc')}
              type='number'
              value={roundNumber(genericPrice ?? 0, 2)}
              placeholder=''
              disabled={false}
              onChange={function (newValue: string | number): void {
                setValue('genericPrice', roundNumber((newValue as number) ?? 0, 2));
                setGenericPrice(roundNumber((newValue as number) ?? 0, 2));
              }}
              suffix={'€'}
              number={{ min: 0, max: 100000, step: 0.1 }}
            />
            <SelectVatRate
              titleSize='normal'
              titleOverride={t('common.defaultVatRate')}
              hoverDescription={t('common.defaultVatRateDesc')}
              register={register}
              registerName='vatRate'
              setValue={setValue}
              error={errors.vatRate?.value?.message ?? errors.vatRate?.label?.message ?? ''}
              isSelectable={true}
              selectedOptionChanged={(e: any) => {
                if (e && e.value) {
                  setValue('vatRate', {
                    value: e?.value,
                    label: e?.label,
                  });

                  setSaveProduct((prevValue) => {
                    return { ...prevValue, vatRateId: e?.value };
                  });
                } else {
                  setValue('vatRate', {
                    value: '',
                    label: '',
                  });

                  setSaveProduct((prevValue) => {
                    return { ...prevValue, vatRateId: undefined };
                  });
                }
              }}
              forceSelectedOption={selectedOptionVatRate}
              dependsOnSearchParent={false}
            />
          </FormRow>
          <FormRow>
            {/* // TODO DESIGN : champ 'checkbox' générique pour formulaire ? */}
            <div className='panelInput' title={t('common.definePricesByCustomerTypeDesc')}>
              <Switch
                className='base2'
                type='text'
                checked={definePricesByCustomerType ?? false}
                onChange={(checked: boolean) => handleDefinePricesByCustomerType(checked)}
                onColor={'#2a85ff'}
              />
              <h3 className='base2' style={{ margin: '0px 10px' }}>
                {t('common.definePricesByCustomerType')}
              </h3>
            </div>
          </FormRow>
          {definePricesByCustomerType && (
            <FormRow>
              <ProductPrices
                product={{
                  id: props.id ?? '',
                  label: label ?? '',
                  unit: selectedOptionUnit?.label ?? '',
                }}
                priceData={customerTypeProductPrices}
                onChangePrice={handleProductPriceChanged}
              />
            </FormRow>
          )}
        </div>

        {!isTransport && (
          <>
            <div className='form-section'>
              <FormRow>
                <Title label={t('common.taxes')} type='title1' />
              </FormRow>
              <FormRow alignVert='center'>
                <Input
                  {...register('repCode')}
                  error={errors.repCode?.message ?? ''}
                  label={t('common.repCode')}
                  hoverDescription={t('common.repCodeDesc')}
                  type='text'
                  value={repCode ?? ''}
                  placeholder=''
                  disabled={false}
                  onChange={function (e): void {
                    setValue('repCode', e.toString());
                    setRepCode(e.toString());
                  }}
                />
                <SelectProductUnit
                  titleSize='normal'
                  titleOverride={t('common.repUnit')}
                  hoverDescription={t('common.repUnitDesc')}
                  register={register}
                  registerName='repUnit'
                  setValue={setValue}
                  error={errors.repUnit?.value?.message ?? errors.repUnit?.label?.message ?? ''}
                  isSelectable={true}
                  selectedOptionChanged={(e: any) => {
                    if (e && e.value) {
                      setValue('repUnit', {
                        value: e?.value,
                        label: e?.label,
                      });

                      setSaveProduct((prevValue) => {
                        return { ...prevValue, repUnit: e?.value };
                      });

                      setSelectedOptionRepUnit(e);
                    } else {
                      setValue('repUnit', {
                        value: '',
                        label: '',
                      });

                      setSaveProduct((prevValue) => {
                        return { ...prevValue, repUnit: undefined };
                      });
                    }
                  }}
                  forceSelectedOption={selectedOptionRepUnit}
                />
                <Input
                  {...register('repValue')}
                  error={errors.repValue?.message ?? ''}
                  label={t('common.repValue') + ' / ' + selectedOptionUnit?.label ?? ''}
                  hoverDescription={t('common.repValueDesc')}
                  type='number'
                  value={roundNumber(repValue ?? 0, 2)}
                  placeholder=''
                  disabled={false}
                  onChange={function (newValue: string | number): void {
                    setValue('repValue', roundNumber((newValue as number) ?? 0, 2));
                    setRepValue(roundNumber((newValue as number) ?? 0, 2));
                  }}
                  suffix={'€'}
                  number={{ min: 0, max: 100, step: 0.1 }}
                />
              </FormRow>
              <FormRow align='left'>
                <div
                  title={t('common.repProductListLinkDesc') + ' Écominéro'}
                  style={{
                    margin: '10px 20px',
                  }}
                >
                  <a
                    href={'https://www.ecominero.fr/download/2811/?tmstv=1706727945'}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {t('common.repProductListLink') + ' Écominéro'}
                  </a>
                </div>

                <div
                  title={t('common.repProductListLinkDesc') + ' Valobat'}
                  style={{
                    margin: '10px 20px',
                  }}
                >
                  <a
                    href={'https://www.valobat.fr/wp-content/uploads/2024/03/Valobat_Codification_PMCB_20240313-1.xlsx'}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    {t('common.repProductListLink') + ' Valobat'}
                  </a>
                </div>
              </FormRow>
              <FormRow>
                {/* // TODO DESIGN : champ 'checkbox' générique pour formulaire ? */}
                <div className='panelInput' title={t('common.isSubjectToTgapDesc')}>
                  <Switch
                    className='base2'
                    type='text'
                    checked={isSubjectToTgap ?? false}
                    onChange={(checked: boolean) => setIsSubjectToTgap(checked)}
                    onColor={'#2a85ff'}
                  />
                  <h3 className='base2' style={{ margin: '0px 10px' }}>
                    {t('common.isSubjectToTgap')}
                  </h3>
                </div>
              </FormRow>
            </div>

            <div className='form-section'>
              <FormRow>
                <Title label={t('common.other')} type='title1' />
              </FormRow>
              <FormRow>
                <Input
                  {...register('taricCode')}
                  error={errors.taricCode?.message ?? ''}
                  label={t('common.taricCode')}
                  hoverDescription={t('common.taricCodeDesc')}
                  toolTipMode={'question'}
                  type='text'
                  value={taricCode ?? ''}
                  placeholder=''
                  disabled={false}
                  onChange={function (newValue: string | number): void {
                    setValue('taricCode', newValue as string);
                    setTaricCode(newValue as string);
                  }}
                />
              </FormRow>
              <FormRow>
                {/* // TODO DESIGN : champ 'checkbox' générique pour formulaire ? */}
                <div className='panelInput' title={t('common.multiProductPriorityDesc')}>
                  <h3 className='base2' style={{ margin: '0px 10px' }}>
                    {t('common.multiProductPriority')}
                  </h3>
                  <Switch
                    className='base2'
                    type='text'
                    checked={multiProductPriority ?? false}
                    onChange={(checked: boolean) => {
                      setValue('multiProductPriority', checked);
                      setMultiProductPriority(checked);
                    }}
                    onColor={'#2a85ff'}
                  />
                </div>
              </FormRow>
              <ErrorLine label={errors.multiProductPriority?.message ?? ''} align='left' type='label' />
            </div>
          </>
        )}

        <div className='form-section'>
          <BuildDataCustom
            dataCustomType={'Product'}
            dataCustom={productDataCustom}
            dataCustomUpdated={(data: DataCustomItem) => {
              setSaveProduct((prevValue) => {
                return { ...prevValue, dataCustom: data };
              });
            }}
          />
        </div>
        <FixBar>
          <Button label={t('common.return')} style='white' onClick={props.onCancel} />
          <Button iconLeft='save' label={t('common.save')} style='primary' onClick={handleSubmit(onSubmit)} />
        </FixBar>
      </CardDetail>
    </form>
  );
});

GeneralProduct.displayName = 'GeneralProduct';
export default GeneralProduct;

import { SelectData, SelectDataOption, SelectProps } from '../../stories/dune/molecules/SelectData';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import countryList from '../Countries';

export type SelectCountryOption = SelectDataOption

export const SelectCountry = ({
  setValue,
  register,
  registerName = 'country',
  error,
  selectedOptionChanged,
  isPopup,
  onClosePopup,
  searchParent,
  dependsOnSearchParent = false,
  initialOption = undefined,
  forceSelectedOption = undefined,
  ...props
}: SelectProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const [options, setOptions] = useState<SelectCountryOption[]>([]);

  const loadOptions = () => {
    const optionsCountry: SelectCountryOption[] = [];

    if (countryList != null) {
      for (const [key, value] of Object.entries(countryList)) {
        const option = {
          value: key,
          label: value,
          data: { value: key, label: value },
        };

        optionsCountry.push(option);
      }
      setOptions(optionsCountry);
    }
  };

  useEffect(() => {
    loadOptions();
  }, []);

  return (
    <SelectData
      title={props.titleOverride ?? t('common.country')}
      placeholder={t('common.countryChoose')}
      titleSize={props.titleSize ?? 'large'}
      hoverDescription={props.hoverDescription}
      setValue={setValue}
      register={register}
      registerName={registerName}
      error={error}
      isSelectable={props.isSelectable}
      selectedOptionChanged={selectedOptionChanged}
      clearOnSelect={props.clearOnSelect}
      isLoading={isLoading}
      options={options}
      searchParent={searchParent}
      dependsOnSearchParent={dependsOnSearchParent}
      initialOption={initialOption}
      forceSelectedOption={forceSelectedOption}
      filterText={''}
      fetchAtLoad={false}
      onClickNoResult={undefined}
      isPopup={isPopup}
      onClosePopup={onClosePopup}
      inRow={props.inRow}
      isClearable={props.isClearable ?? true}
      noResultText={props.noResultTextOverride}
    ></SelectData>
  );
};

export default class Config {
  static getApiExtranetUrl(path = '') {
    return process.env.REACT_APP_API_URL + 'extranet/' + path;
  }

  static getApiUrl() {
    return process.env.REACT_APP_API_URL;
  }

  static getUrl(path: string) {
    if (path.includes('?')) {
      return process.env.REACT_APP_API_URL + 'core/' + path;
    } else {
      return process.env.REACT_APP_API_URL + 'core' + '/' + path;
    }
  }
}

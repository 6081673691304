import React, { useEffect, useState } from 'react';
import '../styles/table.scss';
import '../styles/popup.scss';
import './AddVehicle.scss';
import axios from 'axios';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Config from '../Config';
import { Vehicle, Carrier } from '@dune-manager/backend-core/dist/models';
import Select, { createFilter } from 'react-select';
import { customStylesSelectReactPopup } from '../styles/custom-styles-select-react';
import { ReactComponent as SvgOpen } from '../assets/svg/chevron-down.svg';
import { ReactComponent as SvgCollapse } from '../assets/svg/chevron-up.svg';
import { ToastTypes } from '../models/ToastTypes';
import Toast from '../models/Toast';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { VehicleType } from '../models/Vehicle';
import Menu from './Menu';
import ToastComponent from './ToastComponent';
import { ReactComponent as ArrowLeft } from '../assets/svg/arrow_left.svg';
import Header from './Header';

interface AddVehicleProps {
  isUpdate?: boolean;
  from?: string;
  onsuccess?(item?: ItemAddVehicle): void;
  currentVehicle?: Vehicle;
  closePopup?(ChildData?: boolean): void;
}

export type ItemAddVehicle = {
  isCreated: boolean;
  vehicle?: {
    id?: string;
    registrationPlate?: string;
    trailerPlate?: string;
  };
};

const AddVehicle = React.memo((props: AddVehicleProps) => {
  const { t } = useTranslation();
  const history = useNavigate();
  const [isAddingLoading, setIsAddingLoading] = useState(false);
  const [registrationPlate, setregistrationPlate] = useState('');
  const [axiosErrorMessage, setAxiosErrorMessage] = useState<string | undefined>(undefined);
  const [trailerPlate, settrailerPlate] = useState<string>();
  const [dryWeight, setdryWeight] = useState<number>();
  const [maxWeight, setmaxWeight] = useState<number>();
  const [ptacMax, setptacMax] = useState<number>();
  const [inductionBreakAdditionalWeight, setinductionBreakAdditionalWeight] = useState<number>();
  const [hasInductionBreak, sethasInductionBreak] = useState<boolean>();
  const [alternativeMotorSystemAdditionalWeight, setalternativeMotorSystemAdditionalWeight] = useState<number>();
  const [sixthAxleAditionalWeight, setsixthAxleAditionalWeight] = useState<number>();
  const [hasAlternativeMotorSystem, sethasAlternativeMotorSystem] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoadingCarriers, setIsLoadingCarriers] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const [optionsvi, setOptionsvi] = useState<any[]>([]);
  const [filterText, setFilterText] = useState('');
  const [selectedOption, setselectedOption] = useState<any>();
  const [selectedOptionVehicleType, setselectedOptionVehicleType] = useState<any>();
  const [selectedOptionAxle, setSelectedOptionAxle] = useState<any>();
  const [optionsAxleNumber, setOptionsAxleNumber] = useState<any[]>([]);
  const [queryCarrier, setQueryCarrier] = useState('');
  const [isReadOnly, setisReadOnly] = useState(false);
  const [isCloseWeight, setIsCloseWeight] = useState(true);
  const [calculPTACMax, setcalculPTACMax] = useState<number>();
  const [toasts, setToast] = useState<Toast[]>([]);

  useEffect(() => {
    updatecalculatePTAC();
  }, [
    ptacMax,
    selectedOptionVehicleType,
    sixthAxleAditionalWeight,
    inductionBreakAdditionalWeight,
    alternativeMotorSystemAdditionalWeight,
  ]);

  useEffect(() => {
    getVehicleType;
    const timeOutId = setTimeout(() => (queryCarrier.length > 2 ? fetchCarriers(queryCarrier) : null), 500);
    return () => clearTimeout(timeOutId);
  }, [queryCarrier]);

  useEffect(() => {
    getVehicleType();
    setAxleNumberOptions([2, 3, 4, 5, 6]);
    setisReadOnly(true);

    if (props.isUpdate && props.currentVehicle != null) {
      const currentVehicle = props.currentVehicle;
      if (currentVehicle.registrationPlate != undefined) {
        setregistrationPlate(currentVehicle.registrationPlate);
      }
    }
  }, [props.isUpdate, props.currentVehicle]);

  const addToast = (message: string, type: ToastTypes) => {
    setToast([...toasts, new Toast(message, '', type)]);
  };

  const getVehicleType = () => {
    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    const url = orgid + '/vehicle-type/get/';
    axios
      .post(
        Config.getApiExtranetUrl(url),
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(async (res) => {
        setVehicleInfoOptions(res.data.content.items);
      })
      .catch((error) => {
        setVehicleInfoOptions([]);
        if (error.response) {
          setErrorMessage('Une erreur est survenue');
        }
      });
  };

  interface IFormInputs {
    registrationPlate: string;
    inductionBreakAdditionalWeight: number;
    trailerPlate: string;
    maxOfPTAC: number;
    type: {
      value: string;
      label: string;
      axles: number;
      maxWeight: number;
      defaultDryWeight: number;
    };
    axleNumber: number;
    addVehiclePtacMax: number;
    hasInductionBreak: boolean;
    tareWeight: number;
    dryWeight: number | undefined;
    selectedOption: {
      value: string;
      label: string;
    };
    hasAlternativeMotorSystem: boolean;
    alternativeMotorSystemAdditionalWeight: number;
    sixthAxleAditionalWeight: number;
  }

  const schema = yup.object().shape({
    registrationPlate: yup
      .string()
      .required(t('common.requiredRegistrationPlate'))
      .max(10, t('common.lengthRegistrationPlate'))
      .matches(/^[a-zA-Z0-9]+$/, { message: t('common.onlyAlphanumeric') }),
    inductionBreakAdditionalWeight: yup
      .number()
      .nullable()
      .notRequired()
      .min(0, t('common.valueBetween') + '0 ' + t('common.and') + ' 0,5')
      .max(0.5, t('common.valueBetween') + '0 ' + t('common.and') + ' 0,5'),
    alternativeMotorSystemAdditionalWeight: yup
      .number()
      .nullable()
      .notRequired()
      .min(0, t('common.valueBetween') + '0 ' + t('common.and') + ' 1')
      .max(1, t('common.valueBetween') + '0 ' + t('common.and') + ' 1'),
    sixthAxleAditionalWeight: yup
      .number()
      .nullable()
      .notRequired()
      .min(0, t('common.valueBetween') + '0 ' + t('common.and') + ' 1')
      .max(1, t('common.valueBetween') + '0 ' + t('common.and') + ' 1')
      .transform((curr, orig) => (orig === '' ? null : curr)),
    trailerPlate: yup
      .string()
      .notRequired()
      .matches(/^[a-zA-Z0-9]+$/, { message: t('common.onlyAlphanumeric'), excludeEmptyString: true }),
    type: yup.object().shape({
      value: yup.string().required(t('common.requiredTypeVehicle')),
      label: yup.string().required(t('common.requiredTypeVehicle')),
    }),
    addVehiclePtacMax: yup
      .number()
      .min(0, t('common.positiveValuePTAC'))
      .max(yup.ref('maxOfPTAC'), t('common.highValuePTAC'))
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)),
    tareWeight: yup
      .number()
      .notRequired()
      .min(0, t('common.positiveValueTare'))
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)),
    dryWeight: yup
      .number()
      .notRequired()
      .min(0, t('common.positiveValueDryWeight'))
      .nullable()
      .max(yup.ref('addVehiclePtacMax'), t('common.dryWeightLowerThanPTAC'))
      .transform((curr, orig) => (orig === '' ? null : curr)),
  });

  const {
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const SearchCompleteVehicleInfo = (registrationPlate: string) => {
    setValue('registrationPlate', registrationPlate);
    setregistrationPlate(registrationPlate);
    setErrorMessage('');

    if (registrationPlate.trim().toUpperCase() !== '') {
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');
      const url = orgid + '/vehicle-info/get-by-registration-plate/' + registrationPlate.trim().toUpperCase();
      axios
        .get(Config.getApiExtranetUrl(url), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async (res) => {
          if (res.data.content.items !== null) {
            setptacMax(res.data.content.items.ptacMax);
            setValue('addVehiclePtacMax', res.data.content.items.ptacMax);
            setinductionBreakAdditionalWeight(res.data.content.items.inductionBreakAdditionalWeight);
            setsixthAxleAditionalWeight(res.data.content.items.sixthAxleAditionalWeight ?? undefined);
            sethasInductionBreak(res.data.content.items.hasInductionBreak);

            setalternativeMotorSystemAdditionalWeight(
              res.data.content.items.alternativeMotorSystemAdditionalWeight ?? undefined,
            );
            sethasAlternativeMotorSystem(res.data.content.items.hasAlternativeMotorSystem);

            if ((res.data.content.items.vehicleType?.id ?? null) !== null) {
              updatetype({
                value: res.data.content.items.vehicleType?.id,
                label: res.data.content.items.vehicleType?.label,
                axles: res.data.content.items.vehicleType?.axles,
                maxWeight: res.data.content.items.vehicleType?.maxWeight,
              });
            }

            updateAxleNumber(res.data.content.items.axleNumber);
            updatedryWeight(res.data.content.items.dryWeight);
            setValue('dryWeight', res.data.content.items.dryWeight);
          } else {
            setptacMax(undefined);
            setdryWeight(undefined);
            setinductionBreakAdditionalWeight(undefined);
            sethasInductionBreak(false);
            setalternativeMotorSystemAdditionalWeight(undefined);
            sethasAlternativeMotorSystem(false);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.code == 'ERR4010001') {
              history('/');
            }
          }
          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        });
    }
  };

  const updatetrailerPlate = (trailerPlate: string) => {
    settrailerPlate(trailerPlate);
    setErrorMessage('');
  };

  const updatetype = (typeKey: any) => {
    setSelectedOptionAxle(null);
    setselectedOptionVehicleType({
      value: typeKey.value,
      label: typeKey.label,
      axles: typeKey.axles,
      maxWeight: typeKey.maxWeight,
      defaultDryWeight: typeKey.defaultDryWeight,
    });
    setValue('type', {
      value: typeKey.value,
      label: typeKey.label,
      axles: typeKey.axles,
      maxWeight: typeKey.maxWeight,
      defaultDryWeight: typeKey.defaultDryWeight,
    });

    if (typeKey.axles === null || typeKey.axles === undefined) {
      setAxleNumberOptions([2, 3, 4, 5, 6]);
    } else {
      setAxleNumberOptions(typeKey.axles.split(';'));
    }

    if (typeKey.axles === 6) setisReadOnly(false);
    else {
      setsixthAxleAditionalWeight(undefined);
      setisReadOnly(true);
    }

    if (typeKey.defaultDryWeight !== null && typeKey.defaultDryWeight !== undefined) {
      setdryWeight(typeKey.defaultDryWeight);
      setValue('dryWeight', typeKey.defaultDryWeight);
    } else {
      setdryWeight(undefined);
      setValue('dryWeight', undefined);
    }

    setmaxWeight(typeKey.maxWeight);
    setErrorMessage('');
  };

  const updatecalculatePTAC = () => {
    setcalculPTACMax(
      (maxWeight ?? 0) +
        (inductionBreakAdditionalWeight ?? 0) +
        (alternativeMotorSystemAdditionalWeight ?? 0) +
        (sixthAxleAditionalWeight ?? 0),
    );
    setValue(
      'maxOfPTAC',
      (maxWeight ?? 0) +
        (inductionBreakAdditionalWeight ?? 0) +
        (alternativeMotorSystemAdditionalWeight ?? 0) +
        (sixthAxleAditionalWeight ?? 0) +
        0.0001,
    );
  };

  const updatedryWeight = (dryWeight: number) => {
    setValue('dryWeight', dryWeight);
    setdryWeight(dryWeight);
    setErrorMessage('');
  };

  const updateptacMax = (ptacMax: number) => {
    setValue('addVehiclePtacMax', ptacMax);
    setptacMax(ptacMax);
    setErrorMessage('');
  };

  const updateAxleNumber = (axleNumber: any) => {
    setSelectedOptionAxle({
      value: axleNumber,
      label: axleNumber,
    });

    if (axleNumber === 6) setisReadOnly(false);
    else {
      setsixthAxleAditionalWeight(undefined);
      setisReadOnly(true);
    }
    setErrorMessage('');
  };

  const updatesixthAxleAditionalWeight = (sixthAxleAditionalWeight: number) => {
    setsixthAxleAditionalWeight(sixthAxleAditionalWeight ?? undefined);
    setErrorMessage('');
  };

  const updateinductionBreakAdditionalWeight = (inductionBreakAdditionalWeight: number) => {
    setinductionBreakAdditionalWeight(inductionBreakAdditionalWeight);
    setErrorMessage('');
  };

  const updatehasAlternativeMotorSystem = (hasAlternativeMotorSystem: boolean) => {
    sethasAlternativeMotorSystem(hasAlternativeMotorSystem);
    setErrorMessage('');
  };

  const updatealternativeMotorSystemAdditionalWeight = (alternativeMotorSystemAdditionalWeight: number) => {
    setalternativeMotorSystemAdditionalWeight(alternativeMotorSystemAdditionalWeight ?? undefined);
    setErrorMessage('');
  };

  const updatehasInductionBreak = (hasInductionBreak: boolean) => {
    sethasInductionBreak(hasInductionBreak);
    setErrorMessage('');
  };

  const onSubmit = () => {
    setAxiosErrorMessage(undefined);
    onSubmitCreate();
    return;
  };

  const onSubmitCreate = async () => {
    setIsAddingLoading(true);

    const token = localStorage.getItem('token');

    const orgid = localStorage.getItem('orgid');

    const dataToSend: CreateVehicle = {
      registrationPlate: registrationPlate,
      trailerPlate: trailerPlate,
      lastTareDate: new Date(),
      carrierId: selectedOption?.value,
    };

    const url = orgid + '/vehicle/add';
    await axios
      .post(Config.getApiExtranetUrl(url), dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(async () => {
        const dataToSendvehicleInfo: CreateVehicleInfo = {
          registrationPlate: registrationPlate,
          hasInductionBreak: hasInductionBreak,
          ptacMax: ptacMax === 0 ? undefined : ptacMax,
          axleNumber: (selectedOptionAxle ?? null) === null ? undefined : selectedOptionAxle.value,
          dryWeight: dryWeight === 0 ? undefined : dryWeight,
          inductionBreakAdditionalWeight:
            inductionBreakAdditionalWeight === 0 || (hasInductionBreak ?? false) === false
              ? undefined
              : inductionBreakAdditionalWeight,
          vehicleTypeId: (selectedOptionVehicleType ?? null) === null ? undefined : selectedOptionVehicleType.value,
          hasAlternativeMotorSystem: hasAlternativeMotorSystem,
          alternativeMotorSystemAdditionalWeight:
            alternativeMotorSystemAdditionalWeight === 0 || (hasAlternativeMotorSystem ?? false) === false
              ? undefined
              : alternativeMotorSystemAdditionalWeight,
          sixthAxleAditionalWeight:
            sixthAxleAditionalWeight === 0 || (selectedOptionAxle?.value ?? 0) === 6
              ? undefined
              : sixthAxleAditionalWeight,
        };

        const urlAddVehicleInfo = orgid + '/vehicle-info/add';
        await axios
          .post(Config.getApiExtranetUrl(urlAddVehicleInfo), dataToSendvehicleInfo, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then()
          .catch((error) => {
            if (error.response) {
              if (error.response.data.code == 'ERR4010001') {
                history('/');
              }
            }
          });
      })
      .catch((error) => {
        setIsAddingLoading(false);

        if (error.response) {
          if (
            error.response.data.code == 'ERR4010001' ||
            error.response.data.code == 'ERR4031001' ||
            error.response.data.code == 'ERR4010000'
          ) {
            history('/');
          }
          if (error.response.data.code == 'ERR4030012') {
            setAxiosErrorMessage(error.response.data.detail);
          }
        }
        addToast(
          (error?.response?.data?.code
            ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
            : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
          ToastTypes.error,
        );
      });
  };

  const fetchCarriers = (filterText: string) => {
    setFilterText(filterText);
    if (filterText.length > 0) {
      setIsLoadingCarriers(true);
      const token = localStorage.getItem('token');
      const orgid = localStorage.getItem('orgid');

      const baseUrl = '/carrier/get';
      const url = orgid + `${baseUrl}?page=${1}&limit=${200}&search=${filterText}`;

      axios
        .get(Config.getApiExtranetUrl(url), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsLoadingCarriers(false);
          setCarriersOptions(res.data.content.items);
        })
        .catch(() => {
          setIsLoadingCarriers(false);
          setCarriersOptions([]);
        });
    } else {
      setCarriersOptions([]);
    }
  };

  const setCarriersOptions = (carriers: Carrier[]) => {
    const options: any[] = [];

    if (carriers != null) {
      carriers.forEach((s) => {
        const option = {
          value: s.id,
          label: s.label,
        };

        options.push(option);
      });

      setOptions(options);
    }
  };

  const setVehicleInfoOptions = (vehicleTypeList: VehicleType[]) => {
    const optionsvi: any[] = [];

    for (const vehicleType of vehicleTypeList) {
      optionsvi.push({
        value: vehicleType.id,
        label: vehicleType.label,
        axles: vehicleType.axles,
        maxWeight: vehicleType.maxWeight,
        defaultDryWeight: vehicleType.defaultDryWeight,
      });
    }

    setOptionsvi(optionsvi);
  };

  const setAxleNumberOptions = (cont: any[]) => {
    const optionsAxleNumber: any = [];
    cont.map((value) => {
      let intValue: string | number = value;
      if (typeof value !== 'number') {
        intValue = parseInt(value);
      }
      optionsAxleNumber.push({
        value: intValue,
        label: intValue,
      });
    });

    setOptionsAxleNumber(optionsAxleNumber);
    setSelectedOptionAxle(optionsAxleNumber[0]);
  };

  const updateCarrier = (newCarrier: string) => {
    setselectedOption(newCarrier);
    setErrorMessage('');
  };

  const filterConfig = {
    stringify: (option: any) => `${option.label}`,
  };

  const toggleReport = () => {
    setIsCloseWeight(!isCloseWeight);
  };

  const goHome = () => {
    {
      history('/vehicles');
    }
  };

  return (
    <div className='vehicles'>
      <Menu selectedPage='vehicles'></Menu>
      <ToastComponent toastList={toasts} position={'top-right'} autoDelete={true} dismissTime={4000} key={1} />

      <div className={'right table-container'}>
        <div className='detail-header'>
          <div className='buttonContainer'>
            <div className='returnBtn' onClick={() => goHome()}>
              <ArrowLeft className='svg'></ArrowLeft>
            </div>
            <Header selectedPage={t('common.addVehicle')}></Header>
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='popup__row'>
            <div className='popup-col right'>
              <div className='input__title'>{t('common.vehicleType')}</div>
              <Select
                {...register('type', {})}
                name='typeVehicle'
                className='popup__select'
                isMulti={false}
                styles={customStylesSelectReactPopup('#F4F4F4')}
                options={optionsvi}
                placeholder={t('common.typeChoose')}
                onChange={(input) => {
                  input != null && input.value != null ? updatetype(input) : null;
                }}
                value={selectedOptionVehicleType}
              />

              <p className='validator-error'>{errors.type?.label?.message ?? errors.type?.value?.message}</p>
            </div>

            <div className='popup-col'>
              <div className='input__title'>{t('common.axleNumber')}</div>
              <Select
                {...register('axleNumber', {})}
                name='axleNumber'
                className='popup__select'
                isMulti={false}
                styles={customStylesSelectReactPopup('#F4F4F4')}
                options={optionsAxleNumber}
                placeholder={t('common.axleNumberChoose')}
                onChange={(input) => {
                  input != null && input.value != null ? updateAxleNumber(input.value) : null;
                }}
                value={selectedOptionAxle}
              />
              <p className='validator-error'>{errors.axleNumber?.message}</p>
            </div>
          </div>
          <div className='popup__row'>
            <div className='popup-col right'>
              <div className='input__title'>{t('common.registrationPlate')} *</div>
              <div>
                <input
                  type='text'
                  {...register('registrationPlate', {})}
                  placeholder=''
                  onBlur={(e) => SearchCompleteVehicleInfo(e?.target.value)}
                />
              </div>
              <div>
                <p className='validator-error'>{errors.registrationPlate?.message}</p>
              </div>
            </div>

            <div className='popup-col'>
              <div className='input__title'>{t('common.trailerPlate')}</div>
              <div>
                <input
                  type='text'
                  {...register('trailerPlate', {})}
                  placeholder=''
                  onChange={(e) => updatetrailerPlate(e?.target.value)}
                />
              </div>
              <div>
                <p className='validator-error'>{errors.trailerPlate?.message}</p>
              </div>
            </div>
          </div>
          <div>
            <div className='addVehicle-svgBox primary2 right'>
              {!isCloseWeight ? (
                <SvgCollapse className='svg' onClick={toggleReport}></SvgCollapse>
              ) : (
                <SvgOpen className='svg' onClick={toggleReport}></SvgOpen>
              )}
            </div>
            <div className={isCloseWeight ? 'addVehicle-collapse hidden' : 'addVehicle-collapse'}>
              <div className='popup__row'>
                <div className='popup-col right'>
                  <div className='input__title'>{t('common.theoreticalMaxWeight')}</div>
                  <div className='panelInput'>
                    <div className='input-prefix'>
                      <h3 className='base2'>T</h3>
                    </div>
                    <input type='number' placeholder='0' value={maxWeight} step='0.01' />
                  </div>
                </div>

                <div className={(selectedOptionAxle?.value ?? 0) !== 6 ? 'popup-col disabled' : 'popup-col'}>
                  <div className='input__title'>
                    {t('common.sixthAxleAdditionalWeight')}
                    <i>{' ( max. +1T )'}</i>
                  </div>
                  <div className='panelInput'>
                    <div className='input-prefix'>
                      <h3 className='base2'>T</h3>
                    </div>
                    <input
                      type='number'
                      {...register('sixthAxleAditionalWeight', {})}
                      placeholder={sixthAxleAditionalWeight?.toString() ?? '0'}
                      min='0'
                      max='1'
                      step='0.01'
                      onChange={(e) => updatesixthAxleAditionalWeight(parseFloat(e?.target.value))}
                      value={sixthAxleAditionalWeight ?? undefined}
                      readOnly={isReadOnly}
                    />
                  </div>
                  <p className='validator-error'>{errors.sixthAxleAditionalWeight?.message}</p>
                </div>
              </div>

              <div className='popup__row'>
                <div className='popup-col right'>
                  <div className='input__title'>
                    {t('common.alternativeMotor')}
                    <i>{' ( max. +1T )'}</i>
                  </div>
                  <div className='popup-col same-row'>
                    <input
                      type='checkbox'
                      {...register('hasAlternativeMotorSystem', {})}
                      checked={hasAlternativeMotorSystem === true}
                      onChange={(e) => updatehasAlternativeMotorSystem(e?.target.checked)}
                    />
                    <div className={hasAlternativeMotorSystem ? 'panelInput' : 'panelInput hidden'}>
                      <div className='input-prefix'>
                        <h3 className='base2'>T</h3>
                      </div>
                      <input
                        type='number'
                        {...register('alternativeMotorSystemAdditionalWeight', {})}
                        placeholder={alternativeMotorSystemAdditionalWeight?.toString() ?? '0'}
                        min='0'
                        max='1'
                        step='0.01'
                        defaultValue={0}
                        onChange={(e) => updatealternativeMotorSystemAdditionalWeight(parseFloat(e?.target.value))}
                        value={alternativeMotorSystemAdditionalWeight}
                      />
                    </div>
                  </div>

                  <p className='validator-error'>{errors.alternativeMotorSystemAdditionalWeight?.message}</p>
                </div>

                <div className='popup-col'>
                  <div className='input__title'>
                    {t('common.inductionBreak')}
                    <i>{' ( max. +0.5T )'}</i>
                  </div>
                  <div className='popup-col same-row'>
                    <input
                      type='checkbox'
                      {...register('hasInductionBreak', {})}
                      checked={hasInductionBreak === true}
                      onChange={(e) => updatehasInductionBreak(e?.target.checked)}
                    />
                    <div className={hasInductionBreak ? 'panelInput' : 'panelInput hidden'}>
                      <div className='input-prefix'>
                        <h3 className='base2'>T</h3>
                      </div>
                      <input
                        type='number'
                        className='input-suffix'
                        {...register('inductionBreakAdditionalWeight', {})}
                        placeholder={inductionBreakAdditionalWeight?.toString() ?? '0'}
                        min='0'
                        max='0.5'
                        step='0.01'
                        defaultValue={0}
                        onChange={(e) => updateinductionBreakAdditionalWeight(parseFloat(e?.target.value))}
                        value={inductionBreakAdditionalWeight}
                      />
                    </div>
                  </div>

                  <p className='validator-error'>{errors.inductionBreakAdditionalWeight?.message}</p>
                </div>
              </div>

              <div className='popup__row'>
                <div className='popup-col right'>
                  <div className='input__title'>
                    {t('common.ptac')}{' '}
                    <i> {calculPTACMax ? ' ( max. ' + Number(calculPTACMax.toFixed(2)) + ' T )' : ''}</i>
                  </div>
                  <div className='panelInput'>
                    <div className='input-prefix'>
                      <h3 className='base2'>T</h3>
                    </div>
                    <input
                      className='base2'
                      type='number'
                      {...register('addVehiclePtacMax', {})}
                      value={parseFloat(ptacMax as any)}
                      placeholder='0'
                      min='0'
                      step='0.01'
                      max={calculPTACMax ?? 0 + 0.0000001}
                      onChange={(e) => updateptacMax(parseFloat(e?.target.value))}
                    />
                  </div>
                  <div>
                    <p className='validator-error'>{errors.addVehiclePtacMax?.message}</p>
                  </div>
                </div>

                <div className='popup-col'>
                  <div className='input__title'>{t('common.dryWeight')}</div>
                  <div className='panelInput'>
                    <div className='input-prefix'>
                      <h3 className='base2'>T</h3>
                    </div>
                    <input
                      type='number'
                      {...register('dryWeight', {})}
                      placeholder='0'
                      step='0.01'
                      min='0'
                      max={ptacMax}
                      onChange={(e) => updatedryWeight(parseFloat(e?.target.value))}
                      value={dryWeight}
                    />
                  </div>
                  <p className='validator-error'>{errors.dryWeight?.message}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='popup-col'>
            <div className='input__title'>{t('common.carrier')}</div>
            <Select
              className='popup__select'
              isMulti={false}
              isLoading={isLoadingCarriers}
              options={options}
              styles={customStylesSelectReactPopup('#F4F4F4')}
              loadingMessage={() => t('common.loading')}
              noOptionsMessage={() => (filterText.length < 2 ? t('common.searchWith3Caracters') : t('common.noResult'))}
              placeholder={t('common.carrierChoose')}
              onInputChange={(e) => setQueryCarrier(e)}
              isSearchable={true}
              onChange={(input) => {
                input != null && input.value != null ? updateCarrier(input) : null;
              }}
              filterOption={createFilter(filterConfig)}
              isClearable={true}
            />
            <p className='validator-error'>
              {errors.selectedOption?.label?.message ?? errors.selectedOption?.value?.message}
            </p>
          </div>
          <div>{errorMessage}</div>
        </form>

        <div className='popup-row-right'>
          <div className='addBtn' onClick={handleSubmit(onSubmit)}>
            <div className='base2'>{t('common.add')}</div>
          </div>
          <div className='cancelBtn'>
            <div className='base2'>{t('common.cancel')}</div>
          </div>
        </div>
        <div>
          <p className='validator-error'>{axiosErrorMessage}</p>
        </div>
      </div>
    </div>
  );
});

class CreateVehicle {
  registrationPlate: string | undefined;
  trailerPlate: string | undefined;
  lastTareDate: Date | undefined;
  carrierId: string | undefined;
}

class CreateVehicleInfo {
  registrationPlate: string | undefined;
  hasInductionBreak: boolean | undefined;
  ptacMax: number | undefined;
  axleNumber: number | undefined;
  dryWeight: number | undefined;
  inductionBreakAdditionalWeight: number | undefined;
  vehicleTypeId: string | undefined;
  hasAlternativeMotorSystem: boolean | undefined;
  alternativeMotorSystemAdditionalWeight: number | undefined;
  sixthAxleAditionalWeight: number | undefined;
}

AddVehicle.displayName = 'AddVehicle';
export default AddVehicle;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CardDetail } from '../../../stories/dune/atoms/CardDetail';
import { FormRow } from '../../../stories/dune/atoms/FormRow';
import { Input } from '../../../stories/dune/atoms/Input';
import { AuthorizedVehicleSave, GetAuthorizedVehicle } from '../../../models/AuthorizedVehicle';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { FixBar } from '../../../stories/dune/molecules/FixBar';
import { Button } from '../../../stories/dune/atoms/Button';
import useToast from '../../../hooks/use-toast';
import axios from 'axios';
import { ToastTypes } from '../../../models/ToastTypes';
import Config from '../../../Config';
import Switch from 'react-switch';
import stringUtils from '../../../utils/stringUtils';
import moment from 'moment';
import { SelectVehicle, SelectVehicleOption } from '../../forms/SelectVehicle';
import { SelectTripType, SelectTripTypeOption } from '../../forms/SelectTripType';
import { GlobalParameter } from '../../../models/GlobalParameter';

interface GeneralAuthorizedVehicleProps {
  id?: string;
  authorizedVehicleData: GetAuthorizedVehicle | undefined;

  onCancel: () => void;
  onConfirm: (data: any) => void;

  mode: string;
}

interface IFormInputs {
  vehicle: {
    value: string;
    label: string;
  };
  tripType: {
    value: string;
    label: string;
  };
  description: string;
  beginDate: Date;
  expirationDate: Date | undefined;
}

const GeneralAuthorizedVehicle = React.memo((props: GeneralAuthorizedVehicleProps) => {
  const { t } = useTranslation();
  const history = useNavigate();
  const { addToast } = useToast();

  const [isAddingLoading, setIsAddingLoading] = useState(false);

  const [siteTimeZone, setSiteTimeZone] = useState<string>('Europe/Paris');
  useEffect(() => {
    const globalParametersSite: GlobalParameter[] = JSON.parse(localStorage.getItem('globalParameters') ?? '[]');
    setSiteTimeZone(globalParametersSite.find((x) => x.label === 'siteTimeZone')?.value ?? 'Europe/Paris');
  }, []);

  const [saveAuthorizedVehicle, setSaveAuthorizedVehicle] = useState<AuthorizedVehicleSave>();

  const [isActive, setIsActive] = useState<boolean>(true);

  const [vehicleOption, setVehicleOption] = useState<SelectVehicleOption>();
  const [beginDate, setBeginDate] = useState<Date>(new Date());
  const [tripTypeOption, setTripTypeOption] = useState<SelectTripTypeOption>();
  const [description, setDescription] = useState<string>();
  const [expirationDate, setExpirationDate] = useState<Date | undefined>(undefined);

  const schema = yup.object().shape({
    vehicle: yup.object().shape({
      value: yup.string().nullable().required(t('common.requiredVehicle')),
      label: yup.string().nullable().required(t('common.requiredVehicle')),
    }),
    tripType: yup.object().shape({
      value: yup.string().nullable().required(t('common.requiredTripType')),
      label: yup.string().nullable().required(t('common.requiredTripType')),
    }),
    beginDate: yup
      .date()
      .default(new Date())
      .required(t('common.requiredStartDate'))
      .min(moment().startOf('day').toDate(), t('common.startDateBeforeToday')),
    expirationDate: yup
      .date()
      .nullable()
      .notRequired()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .min(yup.ref('beginDate'), t('common.endDateBeforeStartDate')),
  });

  const {
    setValue,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  useEffect(() => {
    if (props.authorizedVehicleData) {
      // on initialise SaveAuthorizedVehicle pour ne pas perdre les données non-gérées lors de la sauvegarde
      setSaveAuthorizedVehicle({
        ...props.authorizedVehicleData,
      });

      setIsActive(props.authorizedVehicleData?.isActive ?? true);

      if (props.authorizedVehicleData?.vehicle) {
        setVehicleOption({
          value: props.authorizedVehicleData?.vehicle?.id,
          label: props.authorizedVehicleData?.vehicle?.registrationPlate,
          data: props.authorizedVehicleData?.vehicle,
        });
      }

      setBeginDate(props.authorizedVehicleData?.beginDate ?? new Date());
      setValue('beginDate', props.authorizedVehicleData?.beginDate ?? new Date());

      if (props.authorizedVehicleData?.tripType) {
        setTripTypeOption({
          value: props.authorizedVehicleData?.tripType?.id ?? '',
          label: props.authorizedVehicleData?.tripType?.label ?? '',
          data: props.authorizedVehicleData?.tripType,
        });
        setValue('tripType', {
          value: props.authorizedVehicleData?.tripType?.id ?? '',
          label: props.authorizedVehicleData?.tripType?.label ?? '',
        });
      }

      setDescription(props.authorizedVehicleData?.description ?? '');
      setValue('description', props.authorizedVehicleData?.description ?? '');

      setExpirationDate(props.authorizedVehicleData?.expirationDate);
    }
  }, [props.authorizedVehicleData]);

  function onSubmit() {
    setIsAddingLoading(true);

    const token = localStorage.getItem('token');
    const orgid = localStorage.getItem('orgid');
    let baseUrl = orgid + '/authorized-vehicle/add';
    if (props.mode == 'update') baseUrl = orgid + '/authorized-vehicle/edit/' + props.id;

    if (saveAuthorizedVehicle) {
      const dataToSend: AuthorizedVehicleSave = saveAuthorizedVehicle;

      dataToSend.vehicleId = saveAuthorizedVehicle.vehicleId ?? vehicleOption?.value ?? '';
      dataToSend.tripTypeId = saveAuthorizedVehicle.tripTypeId ?? tripTypeOption?.value ?? '';
      dataToSend.description = description ?? '';
      dataToSend.beginDate = beginDate;
      dataToSend.expirationDate = expirationDate;

      axios
        .post(Config.getApiExtranetUrl(baseUrl), stringUtils.formatFieldsForPost(dataToSend), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setIsAddingLoading(false);
          addToast(
            t('common.authorizedVehicle' + (props.mode === 'update' ? 'Updated' : 'Created')),
            ToastTypes.success,
          );
          props.onConfirm(res.data.content.uuid);
        })
        .catch((error) => {
          setIsAddingLoading(false);
          if (error.response) {
            if (
              error.response.data.code == 'ERR4010001' ||
              error.response.data.code == 'ERR4031001' ||
              error.response.data.code == 'ERR4010000'
            ) {
              history('/');
            }
          }
          addToast(
            (error?.response?.data?.code
              ? error?.response?.data?.code + ': ' + t('errors.' + error.response.data.code)
              : undefined) ?? t('common.genericErrorMessage') + error?.response?.status,
            ToastTypes.error,
          );
        });
    } else {
      setIsAddingLoading(false);
    }
  }

  return !props.authorizedVehicleData && props.mode === 'update' ? (
    <div>Loading...</div>
  ) : (
    <form id='form' onSubmit={handleSubmit(onSubmit)}>
      <CardDetail>
        <div className='form-section'>
          {props.mode === 'update' && (
            <FormRow align='right' alignVert='center'>
              <h3 className='base2'>{t('common.active')}</h3>
              <Switch
                disabled={true}
                onChange={function () {
                  setIsActive((prevValue) => !prevValue);
                }}
                checked={isActive ?? false}
                onColor={'#2a85ff'}
              />
            </FormRow>
          )}
          <FormRow>
            <SelectVehicle
              titleSize='normal'
              register={register}
              registerName='vehicle'
              setValue={setValue}
              error={errors.vehicle?.value?.message ?? errors.vehicle?.label?.message ?? ''}
              isSelectable={props.mode === 'add'}
              selectedOptionChanged={(e: SelectVehicleOption) => {
                if (e && e.value) {
                  setValue('vehicle', {
                    value: e?.value,
                    label: e?.label,
                  });

                  setSaveAuthorizedVehicle((prevValue) => {
                    return { ...prevValue, vehicleId: e?.value };
                  });
                } else {
                  setValue('vehicle', {
                    value: '',
                    label: '',
                  });

                  setSaveAuthorizedVehicle((prevValue) => {
                    return { ...prevValue, vehicleId: undefined };
                  });
                }
              }}
              forceSelectedOption={vehicleOption}
            />
            <SelectTripType
              titleSize='normal'
              register={register}
              registerName='tripType'
              setValue={setValue}
              error={errors.tripType?.value?.message ?? errors.tripType?.label?.message ?? ''}
              isSelectable={true}
              selectedOptionChanged={(e: SelectTripTypeOption) => {
                if (e && e.value) {
                  setValue('tripType', {
                    value: e?.value,
                    label: e?.label,
                  });

                  setSaveAuthorizedVehicle((prevValue) => {
                    return { ...prevValue, tripTypeId: e?.value };
                  });
                } else {
                  setValue('tripType', {
                    value: '',
                    label: '',
                  });

                  setSaveAuthorizedVehicle((prevValue) => {
                    return { ...prevValue, tripTypeId: undefined };
                  });
                }
              }}
              forceSelectedOption={tripTypeOption}
              searchParent={['authorization']}
            />
          </FormRow>
        </div>

        <div className='form-section'>
          <FormRow>
            <Input
              error={errors.description?.message ?? ''}
              label={t('common.description')}
              type='text'
              {...register('description')}
              value={description ?? ''}
              onChange={(newValue: string | number) => {
                setDescription(newValue as string);
                setValue('description', newValue as string);
              }}
            />
          </FormRow>
        </div>
        <div className='form-section'>
          <FormRow>
            <Input
              error={errors.beginDate?.message ?? ''}
              label={t('common.startDate')}
              type='datetime-local'
              {...register('beginDate')}
              value={moment(beginDate).tz(siteTimeZone).format('YYYY-MM-DDTHH:mm')}
              placeholder='DD/MM/YY HH:mm'
              onChange={(newValue: string | number) => {
                setBeginDate(new Date(newValue as string));
                setValue('beginDate', new Date(newValue as string));
              }}
            />
            <Input
              error={errors.expirationDate?.message ?? ''}
              label={t('common.endDate')}
              type='datetime-local'
              {...register('expirationDate', {})}
              value={expirationDate ? moment(expirationDate).tz(siteTimeZone).format('YYYY-MM-DDTHH:mm') : ''}
              placeholder='DD/MM/YY HH:mm'
              onChange={(newValue: string | number) => {
                if (newValue && newValue !== '') {
                  setExpirationDate(new Date(newValue as string));
                  setValue('expirationDate', new Date(newValue as string));
                } else {
                  setExpirationDate(undefined);
                  setValue('expirationDate', undefined);
                }
              }}
            />
          </FormRow>
        </div>
        <FixBar>
          <Button label={t('common.return')} style='white' onClick={props.onCancel} />
          <Button iconLeft='save' label={t('common.save')} style='primary' onClick={handleSubmit(onSubmit)} />
        </FixBar>
      </CardDetail>
    </form>
  );
});

GeneralAuthorizedVehicle.displayName = 'GeneralAuthorizedVehicle';
export default GeneralAuthorizedVehicle;
